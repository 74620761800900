/* eslint-disable */

import {
  Box,
  Button, Card, CardHeader, Grid,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TableContainer
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import StatusButtonPopper from './StatusButtonPopper';
import AddAppointmentDialog from './AddAppointmentDialog';
import AttendedButtonPopper from './AttendedButtonPopper';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonBlue: {
    color: 'white',
    backgroundColor: 'SteelBlue',
    padding: '2px 7px 2px 7px',
    '&:hover': {
      backgroundColor: 'SkyBlue'
    },
    width: 'fit-content'
  },
  selected: {
    backgroundColor: '#D3D3D3'
  }
}));

// appointments: List of appointments
// handleStatusChange: function to change the status of the selected meeting
// handleDelete: function to delete the selected meeting
// handleClick: function to handle selecting a meeting

const AppointmentTable = ({ appointments, handleDelete, handleClick, handleApproveAppointment, handleAttendAppointment, handleAddAppointment }) => {
  const classes = useStyles();

  // variable to set max number of rows on the table, initialized to 5
  const [limit, setLimit] = useState(5);
  // variable to set the current table page, initialized to 0
  const [page, setPage] = useState(0);

  // Determines if the add appointment pop-up is open or not
  const [openAddAppointment, setOpenAddAppointment] = useState(false);

  // function to open the add appointment pop-up
  const handleOpenAddAppointment = () => {
    setOpenAddAppointment(true);
  };

  // function to close the add appointment pop-up
  const handleCloseAddAppointment = () => {
    setOpenAddAppointment(false);
  };

  // changes the limit
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // changes the page
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // turns a date object into something more presentable
  function handleTime(date) {
    let newDate = new Date(date);
    return `${newDate.toDateString()} at ${newDate.getHours()}:${String(newDate.getMinutes()).padStart(2, '0')}`;
  }

  // changes the css class of a table row
  function changeClass(selected) {
    if (selected) {
      return classes.selected
    } else {
      return null
    }
  }

  return (
      <Card>

          <CardHeader
            titleTypographyProps={{ variant: 'h5', component: 'span' }}
            style={{ backgroundColor: '#F4F6F8' }}
            title={'Schedule'}
            action={
              <Box
                display='flex'
                justifyContent='flex-end'
                className={classes.addButton}
              >
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={handleOpenAddAppointment}
                >
                  Add appointment
                </Button>
                <AddAppointmentDialog addAppointment={handleAddAppointment} openDialog={openAddAppointment}
                                      handleCloseDialog={handleCloseAddAppointment} />
              </Box>
            }
          />
          <TableContainer>
            <Table align={'center'} >

              <TableHead>
                <TableRow>
                  <TableCell align={'center'}>
                    Title
                  </TableCell>
                  <TableCell align={'center'}>
                    Type
                  </TableCell>
                  <TableCell align={'center'}>
                    Required
                  </TableCell>
                  <TableCell align={'center'}>
                    Status
                  </TableCell>
                  <TableCell>
                    Comment
                  </TableCell>
                  <TableCell align={'center'}>
                    Time
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {appointments.slice(page * limit, page * limit + limit).map((appointment, i) => {
                  return (
                    <TableRow
                      hover
                      key={appointment.id}
                      onClick={(event) => {
                        handleClick(event, appointment.id);
                      }}
                      className={changeClass(appointment.selected)}
                    >
                      <TableCell align={'center'} width='20%'>
                        {appointment.title}
                      </TableCell>
                      <TableCell align={'center'} width='10%'>
                        {appointment.type === 'in-person' ? ('In-Person') : appointment.type === 'zoom' ? (
                          <Link
                            href={appointment.zoomURL}
                            target={'_blank'}
                            underline={'none'}
                          >
                            <Button
                              onClick={(event) => {
                                event.stopPropagation()
                              }}
                              className={classes.buttonBlue}
                            >
                              Join
                            </Button>
                          </Link>) : ('Phone')}
                      </TableCell>
                      <TableCell align={'center'} width='8%'>
                        {appointment.required ? 'Yes' : ('No')}
                      </TableCell>
                      <TableCell align={'center'} width='15%'>
                        <Grid container justify={'center'} spacing={1}>
                          <Grid item>
                            <StatusButtonPopper
                              appointmentState={appointment}
                              approveAppointment={handleApproveAppointment}
                            />
                          </Grid>
                          <Grid item>
                            <AttendedButtonPopper
                              appointmentState={appointment}
                              attendAppointment={handleAttendAppointment}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell width='36%'>
                        {appointment.purpose}
                      </TableCell>
                      <TableCell align={'center'} width='10%'>
                        {handleTime(appointment.start_time)}
                      </TableCell>
                      <TableCell width={'1%'}>
                        <IconButton>
                          <DeleteIcon
                            fontSize={'small'}
                            onClick={(event) => {
                              event.stopPropagation()
                              handleDelete(event, appointment.id);
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>


          <TablePagination
            component='div'
            count={appointments.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />

      </Card>
  );
};

export default AppointmentTable;
