/* eslint-disable */
import {
  CircleMarker,
} from 'react-leaflet';
import theme from 'src/theme';

function VisitedMarker({ visitedPlaces, radiusValue }) {
    const visitedMarkerGroup = []

    const dotColor = { color: theme.palette.dot.green, weight: 10 };

    visitedPlaces.map((location) => {
      visitedMarkerGroup.push(
        <CircleMarker center={location} pathOptions={dotColor} radius={radiusValue} opacity={1} />
      );
    });

    return (
      <div>
        {visitedMarkerGroup}
      </div>
    );
}

export default VisitedMarker;