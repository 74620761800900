/* eslint-disable */
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import React, { useState } from 'react';
import SearchableObjects from './SearchableObjects';
import UserStatus from '../Cards/UserStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  header: {
    marginBottom: '15px'
  },
  uploadButton: {
    paddingTop: '6px'
  },
  popoverRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%'
  },
}));

const Overview = () => {
  const classes = useStyles();

  const [displayedObjects, setDisplayedObjects] = useState(SearchableObjects)

  return (
    <Page
      id={'participant-status-view'}
      className={classes.root}
      title='Participant Status'
    >
      <Card>
        <CardContent>
            <Grid
              container
              spacing={3}
            >

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={12}
              >
                <UserStatus setDisplayedObjects={setDisplayedObjects} />
              </Grid>

              {displayedObjects.map((card) => (
                <Grid
                  item
                  xs={card.componentData.xs}
                  sm={card.componentData.sm}
                  md={card.componentData.md}
                  lg={card.componentData.lg}
                  xl={card.componentData.xl}
                >
                  {card.component}
                </Grid>
              ))}

              {/*<Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={12}
              >
                <Demographics />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <AddressQuery />
              </Grid>


              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <AutomotiveQuery/>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <ApptQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <EducationQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={8}
              >
                <EmploymentQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={12}
              >
                <DrugScreenQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={8}
              >
                <CaseQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <LevelOfSupervision />
              </Grid>




              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <ProgramsQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <CommunitySupervision />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <AdditionalCharges />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={8}
              >
                <TreatmentQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <CourtOrdersQuery />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <CsstAssessment />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <ConductViolationQuery />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <PatAssessment />
              </Grid>


              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
              </Grid>*/}
            </Grid>

        </CardContent>
      </Card>

    </Page>
  );
};

export default Overview;
