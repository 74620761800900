const ConvertRestrictedLocation = (restrictedLocation) => {
    var res = [];
    try {
        if (restrictedLocation != null) {
            restrictedLocation.forEach((point) => {
                var tmpPoint = [];
                tmpPoint.push(point[1], point[0]);
                res.push(tmpPoint);
            });
        }
        return res;
    } catch (error) {
        console.log(error);
    }

}

export default ConvertRestrictedLocation;
