import { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import useImage from '../../../utils/useImage';


const GameVistaScreenshot = ({ fileName, alt }) => {
  const { loading, error, image } = useImage(fileName);

  if (error) return <Typography>{alt}</Typography>;

  useEffect(() => {
    if (error != null) {
      return { alt };
    }
  }, [alt, error]);

  return (
    <>
      {loading ? (
        <Typography>loading</Typography>
      ) : (
        <img
          src={image}
          alt={alt}
          style={{ height: '100%' }}
        />
      )}
    </>
  );
};

export default GameVistaScreenshot;
