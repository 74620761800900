/* eslint-disable */
import {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ParallelCoordinatesChart from '../Charts/ParallelCoordinatesChart';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles, withStyles
} from '@material-ui/core';
import {ParticipantContext} from "../../../context/ParticipantContext";
import {getAveragePoints, getUserScoreAll} from "../api";
import measurement from 'src/context/GlobalVariables';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    //width: '200%'
  },
  parrellelCoordChartContainer: {
    padding: '0px 0px 0px 0px', //0 20 20 0
    height: 250,
  },
  paperTitleContainer: {
    padding: '12px 12px 6px 12px',
  },
  paperTitle: {
   // paddingTop: '5px',
    //paddingBottom: '5px',
  },
}));

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000ff",
    fontSize: 12
  }
})(Typography);

const BlueTextTypography = withStyles({
  root: {
    color: "#003bffff",
    fontSize: 12
  }
})(Typography);

const BlackTextTypography = withStyles({
  root: {
    color: "#000000ff",
    fontSize: 12
  }
})(Typography);

function ScoreRanking ({ className, range, ...rest }) {
  const classes = useStyles();
  const participantContext = useContext(ParticipantContext);
  const [data, setData] = useState([]);
  const [averageScore, setAverageScore] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth);

  useEffect( () => {
    setScreenWidth(window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth)
  }, [window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth]);

  useEffect(() => {

  }, [data, averageScore]);

  useEffect(() => {
    getUserScoreAll()
      .then((data) => {
        if (data)
        {
          setData(data)
        }
      });

    getAveragePoints()
      .then((data) => {
        if (data) {
          setAverageScore(data);
        }
      });

  }, [participantContext.id]);

return (
    <Grid
      container
      spacing={1}>
      <Grid
        container
        justify="space-between"
        spacing={3}
      >
        <Grid container justifyContent="left" spacing={2} className={classes.paperTitleContainer}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              className={classes.paperTitle}
            >
              Score Rankings<br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={12}
        sm={12}
        xl={12}
        xs={12}
        className={classes.parrellelCoordChartContainer}
      >
        <RedTextTypography
          display="inline"
          gutterBottom
          //variant="h6"
          className={classes.paperTitle}
        >
          — Current User's Score
        </RedTextTypography>
        <BlueTextTypography
          display="inline"
          gutterBottom
          //variant="h6"
          className={classes.paperTitle}
        >
          &nbsp; — Other User's Scores
        </BlueTextTypography>
        <BlackTextTypography
          display="inline"
          gutterBottom
          //variant="h6"
          className={classes.paperTitle}
        >
          &nbsp; — Average User Score
        </BlackTextTypography>
        <ParallelCoordinatesChart
          currentId = {participantContext.id}
          data = {data}
          averageScore = {averageScore}
          widthIn={screenWidth >= 1280 ? screenWidth - 300 : (screenWidth >= 600 ? screenWidth * .91 : screenWidth * .87)}
          heightIn={250}
        />
      </Grid>
      <Grid item>

      </Grid>
    </Grid>
  );
}

ScoreRanking.propTypes = {
  className: PropTypes.string
};

export default ScoreRanking;
