/* eslint-disable */
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/nij/ai-sms/location`;
const lcpProxy = 'http://localhost:8010/proxy';

export const getGeoRestrictionProfile = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/georestriction-profile`, {
      params: {
        userId: participant.id
      }
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getPersonalRestrictions = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/specific-restriction`, {
      params: {
        userId: participant.id
      }
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getGeneralExclusions = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/georestriction-profile`, {
      params: {
        userId: participant.id
      }
    });

    return data;

  } catch (error) {
    console.log(error);
  }
};

export const setGeneralExclusions = async (participant, state) => {
  try {
    return await axios.post(`${BASE_URL}/georestriction-profile`, {
      id: state.id,
      userId: participant.id,
      schoolTriggerDistance: state.schoolTriggerDistance / 3.28,
      playgroundTriggerDistance: state.playgroundTriggerDistance / 3.28,
      barTriggerDistance: state.barTriggerDistance / 3.28,
      schoolRestricted: state.schoolRestricted,
      playgroundRestricted: state.playgroundRestricted,
      barRestricted: state.barRestricted
    });


  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addPersonalExclusion = async (participant, state, latitude, longitude, address) => {
  try {
    return await axios.post(`${BASE_URL}/specific-restriction`, {
      category: state.category,
      comments: state.comments,
      coordinates: [[longitude, latitude]],
      locationName: state.location,
      triggerDistance: state.triggerRadius / 3.28,
      userId: participant.id,
      address: address
    });

  } catch (error) {
    console.log(error);
    console.log(error.response);
    return error.response;
  }
};

export const convertAddressToLatLong = async (address) => {
  try {
    return await axios.get(`${BASE_URL}/forward-geocode`, {
      params: {
        address: address
      }
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deletePersonalRestriction = async (id) => {
  try {
    return await axios.delete(`${BASE_URL}/specific-restriction`, {
      params: {
        id: id
      }
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editPersonalRestriction = async (participant, state, latitude, longitude, address) => {
  try {

    return await axios.put(`${BASE_URL}/specific-restriction`, {
      category: state.category,
      comments: state.comments,
      coordinates: [[longitude, latitude]],
      locationName: state.location,
      triggerDistance: state.triggerRadius / 3.28,
      userId: participant.id,
      address: address
    }, {
      params: {
        id: state.id
      }
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getGeneralRestrictions = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/general-restricted-user`, {
      params: {
        id: participant.id
      }
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};
