/* eslint-disable */
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, Legend, ArcElement, LinearScale, CategoryScale, PointElement, LineElement, BarElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Legend, ArcElement, LinearScale, CategoryScale, PointElement, LineElement, BarElement, Tooltip, ChartDataLabels );

function BarChart ({ progress }) {
  ChartJS.defaults.font.family = "Roboto";

  if (!progress)
  {
   progress = {
     healthScore: 0,
     meetingScore: 0,
     restrictionScore: 0,
     copingScore: 0,
     relationshipScore: 0,
     socialScore: 0,
     thinkingScore: 0,
     workScore: 0,
    }
  }
  const data = {
    labels: ['Five Key', 'Meeting', 'Restriction', 'Health'],
    datasets: [
      {
        label: '',
        data: ["", progress.meetingScore, progress.restrictionScore, progress.healthScore],
        backgroundColor: [
          '#FFFFFF00',
          '#D3D3D3',
          '#FFC82B',
          '#E30837',
        ],
      },
      {
        label: 'Work Score',
        data: [progress.workScore],
        backgroundColor: [
          '#2C9400',
        ],
      },
      {
        label: 'Relationship Score',
        data: [progress.relationshipScore],
        backgroundColor: [
          '#00008B88',
        ],
        datalabels: {
          labels: {
            title: progress.relationshipScore !== 0 ?  progress.relationshipScore : null,
          },
        },
      },
      {
        label: 'Coping Score',
        data: [progress.copingScore],
        backgroundColor: [
          '#E4F78F',
        ],
        datalabels: {
          labels: {
            title: progress.copingScore !== 0 ?  progress.copingScore : null
          },
        },
      },
      {
        label: 'Social Score',
        data: [progress.socialScore],
        backgroundColor: [
          '#8ff7d8',
        ],
        datalabels: {
          labels: {
            title: progress.socialScore !== 0 ?  progress.socialScore : null,
          },
        },
      },
      {
        label: 'Thinking Score',
        data: [progress.thinkingScore],
        backgroundColor: [
          '#80002088',
        ],
        datalabels: {
          labels: {
            title: progress.thinkingScore !== 0 ?  progress.thinkingScore : null,
          },
        },
      },
      {
        label: 'Score left to max',
        data: [400 - progress.workScore - progress.relationshipScore - progress.copingScore - progress.socialScore - progress.thinkingScore, 400 - progress.meetingScore, 400 - progress.restrictionScore, 400 - progress.healthScore],
        backgroundColor: [
          '#2C940020',
          '#D3D3D340',
          '#FFC82B40',
          '#E3083740',
        ]
      }
    ]
  }

  return (
    <>
      <div className="barChartContainer" style={{
        position: "relative",
      }}>
        <Bar data={data} options={{
          plugins: {
            legend: {
              display: true
            },
            datalabels: {
              color: 'black',
            }
          },
          responsive: true,
          indexAxis: 'y',
          scales: {
            x: {
              //max:
              beginAtZero: true,
              ticks: {
                display: false
              },
              grid: {
                display: false
              },
              title: {
                display: true,
                text: "Progress Level"
              },
              stacked: true,
            },
            y: {
              grid: {
                display: false
              },
              stacked: true
            }
          }
        }}/>
      </div>
    </>
  );
}

export default BarChart;
