/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  List, ListItem, Divider, ListItemSecondaryAction, IconButton, TextField
} from '@material-ui/core';
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl
} from 'react-leaflet';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { createCase, getCategoryData, updateCase } from '../api';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SnackBarAlert from '../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },

  column: {
    float: 'left',
    width: '100%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnLeft: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  row: {
    display: 'flex'
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  },
  listKey: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  }
}));

const CaseQuery = ({ className, ...rest }) => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  let leftColumnSkeleton = {
    programEntryDate: {
      label: 'Program Entry Date',
      value: 'NO DATA'
    },
    completionDate: {
      label: 'Completion Date',
      value: 'NO DATA'
    },
    maxOutDate: {
      label: 'Max Out Date',
      value: 'NO DATA'
    },
    statusName: {
      label: 'Status Name',
      value: 'NO DATA'
    },
    offenseLevel: {
      label: 'Offense Level',
      value: 'NO DATA'
    },
    offenseDescription: {
      label: 'Offense Description',
      value: 'NO DATA'
    },
    receivedDescription: {
      label: 'Received Description',
      value: 'NO DATA'
    },
    comments: {
      label: 'Comments',
      value: 'NO DATA'
    }
  };

  let rightColumnSkeleton = {
    riskLevel: {
      label: 'Risk Level',
      value: 'NO DATA'
    },
    disposition: {
      label: 'Disposition',
      value: 'NO DATA'
    },
    sentenceDate: {
      label: 'Sentence Date',
      value: 'NO DATA'
    },
    sentenceYears: {
      label: 'Sentence Years',
      value: 'NO DATA'
    },
    sentenceDays: {
      label: 'Sentence Days',
      value: 'NO DATA'
    },
    habitualEnhancement: {
      label: 'Habitual Enhancement',
      value: 'NO DATA'
    },
    lowRiskMonitoring: {
      label: 'Low Risk Monitoring',
      value: 'NO DATA'
    }
  };

  const [rightColumn, setRightColumn] = useState(rightColumnSkeleton);
  const [leftColumn, setLeftColumn] = useState(leftColumnSkeleton);
  const [editable, setEditable] = useState(false);
  const [id, setID] = useState(null);
  const [canceled, setCanceled] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(``);
  const [severity, setSeverity] = useState(``);

  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleRightColumnChange = (event) => {
    setRightColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: rightColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleLeftColumnChange = (event) => {
    setLeftColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: leftColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  useEffect(() => {
    getCategoryData(participant, 'CASE_QUERY')
      .then((data) => {
        if (data && data['caseQuery']) {
          for (const [key, value] of Object.entries(data['caseQuery'])) {
            if (key === 'id') {
              setID(value);
            }
            if (key in rightColumn) {
              setRightColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: rightColumn[key].label,
                  value: value
                }
              }));
            } else if (key in leftColumn) {
              setLeftColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: leftColumn[key].label,
                  value: value
                }
              }));
            }
          }
        } else {
          setLeftColumn(leftColumnSkeleton);
          setRightColumn(rightColumnSkeleton);
        }
      });
  }, [participant.id, canceled]);


  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            className={classes.container}
          >
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
            >
              <Typography
                color='textSecondary'
                variant='body1'
              >
                <List>
                  <ListItem className={classes.sectionTitle}>
                    <b>Case Query</b>
                    <ListItemSecondaryAction>
                      {editable ? (
                        <>
                          <IconButton aria-label='save' onClick={() => {
                            if (id) {
                              updateCase(participant, Object.assign({}, rightColumn, leftColumn), id)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to save changes, try again later.`, 'error');
                                      setCanceled(!canceled);

                                    } else {
                                      handleAlert(`Changes saved!`, 'success');
                                    }
                                  } else {
                                    handleAlert(`Failed to save changes, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            } else {
                              createCase(participant, Object.assign({}, rightColumn, leftColumn), id)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to create new data, try again later.`, 'error');
                                      setCanceled(!canceled);
                                    } else {
                                      handleAlert(`New data saved!`, 'success');
                                      setID(1)
                                    }
                                  } else {
                                    handleAlert(`Failed to create new data, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            }

                            setEditable(false);
                          }}>
                            <SaveIcon />
                          </IconButton>
                          <IconButton aria-label='cancel' onClick={() => {
                            setCanceled(!canceled);
                            setEditable(false);
                          }}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton aria-label='edit' onClick={() => {
                          setEditable(true);
                        }}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div className={classes.column}>
                        {Object.keys(leftColumn).map((key) => (
                          <div className={classes.row}>
                            <div className={classes.columnLeft}>
                              <ListItem className={classes.listKey}><b>{leftColumn[key].label}</b></ListItem>
                            </div>
                            <div className={classes.columnRight}>
                              {editable ? (
                                <TextField
                                  fullWidth
                                  multiline
                                  id={key}
                                  name={key}
                                  value={leftColumn[key].value}
                                  variant={'standard'}
                                  onChange={handleLeftColumnChange}
                                />
                              ) : (
                                <>
                                  <ListItem>{leftColumn[key].value}</ListItem>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div className={classes.column}>
                        {Object.keys(rightColumn).map((key) => (
                          <div className={classes.row}>
                            <div className={classes.columnLeft}>
                              <ListItem className={classes.listKey}><b>{rightColumn[key].label}</b></ListItem>
                            </div>
                            <div className={classes.columnRight}>
                              {editable ? (
                                <TextField
                                  fullWidth
                                  multiline
                                  id={key}
                                  name={key}
                                  value={rightColumn[key].value}
                                  variant={'standard'}
                                  onChange={handleRightColumnChange}
                                />
                              ) : (
                                <>
                                  <ListItem>{rightColumn[key].value}</ListItem>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>


                </List>
              </Typography>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
      <SnackBarAlert
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />
    </>
  );
};

CaseQuery.propTypes = {
  className: PropTypes.string
};

export default CaseQuery;
