import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl,
  Grid, TextField,
  Typography
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { addReward } from '../api';
import { ParticipantContext } from '../../../context/ParticipantContext';


const NewRewardDialog = ({ open, handleCloseDialog, handleAlert }) => {

  const participant = useContext(ParticipantContext);

  const [state, setState] = useState({
    description: '',
    status: 'pending',
    targetPoints: 0,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (state.description === '' || state.targetPoints < 0) {
      return
    }

    addReward(participant, state)
      .then((r) => {
        if (r) {
          if (r.status === 200 || r.status === 201) {
            handleAlert('Added Reward', 'success')
            handleCloseDialog()
          } else {
            handleAlert(`Error: ${r.status} Try again later`, 'error');
            handleCloseDialog()
          }
        } else {
          handleAlert(`No response from server, try again later`, 'error');
          handleCloseDialog()
        }
      })

  };



  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        <Typography>New Reward</Typography>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Grid container direction={'column'} spacing={1}>

          <Grid item>
            <FormControl fullWidth>
              <TextField
                name={'description'}
                label={'Reward Description'}
                variant={'outlined'}
                error={state.description === ''}
                value={state.description}
                onChange={handleChange}
                multiline
                rows={2}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <TextField
                name={'targetPoints'}
                label={'Target Points'}
                variant={'outlined'}
                error={state.targetPoints < 0}
                value={state.targetPoints}
                onChange={handleChange}
                type={'number'}
              />
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button type={'submit'} onClick={handleSave}>Add</Button>
      </DialogActions>

    </Dialog>
  );
};

export default NewRewardDialog;
