import React from 'react';
import AddressQuery from '../Cards/AddressQuery';
import Demographics from '../Cards/Demographics';
import AutomotiveQuery from '../Cards/AutomotiveQuery';
import ApptQuery from '../Cards/ApptQuery';
import EducationQuery from '../Cards/EducationQuery';
import EmploymentQuery from '../Cards/EmploymentQuery';
import DrugScreenQuery from '../Cards/DrugScreensQuery';
import CaseQuery from '../Cards/CaseQuery';
import LevelOfSupervision from '../Cards/LevelOfSupervision';
import ProgramsQuery from '../Cards/ProgramsQuery';
import CommunitySupervision from '../Cards/CommunitySupervision';
import AdditionalCharges from '../Cards/AdditionalCharges';
import TreatmentQuery from '../Cards/TreatmentQuery';
import CsstAssessment from '../Cards/CsstAssessment';
import ConductViolationQuery from '../Cards/ConductViolationQuery';
import PatAssessment from '../Cards/PatAssessment';
import CourtOrdersQuery from '../Cards/CourtOrdersQuery';

const DemographicsObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  },
  component: <Demographics />,
  labels: [
    'demographics',
    'age',
    'race',
    'gender',
    'last appointment date',
    'next appointment date',
    'uds category',
    'court services status',
    'probation status',
    'probation type',
    'cs case manager',
    'community services status',
    'cc case manager',
    'warrant',
    'warrant date',
    'doc eprd',
    'currently in doc',
    'sex offender',
    'violent offender',
    'gang member',
    'veteran',
    'us citizen',
    'hispanic',
    'home hope',
    'dna collected',
    'exclusion zone'
  ]
};

const AddressQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <AddressQuery />,
  labels: [
    'address',
    'neighborhood issues',
    'lease expiration',
    'county name',
    'length residence',
    'address type',
    'ownership type',
    'created date',
    'updated date'
  ]
};

const AutomotiveQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <AutomotiveQuery />,
  labels: [
    'automotive',
    'id type',
    'license status',
    'expiration date',
    'auto make',
    'auto color',
    'comments'
  ]
};

const AppointmentQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <ApptQuery />,
  labels: [
    'appointment',
    'appointment program',
    'appointment date',
    'appointment time',
    'appointment status',
    'reports to',
    'comment'
  ]
};

const EducationQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <EducationQuery />,
  labels: [
    'education level',
    'date received',
    'credit hours',
    'date enrolled',
    'comments',
    'created date',
  ]
};

const EmploymentQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 8
  },
  component: <EmploymentQuery />,
  labels: [
    'employment',
    'job title',
    'temp service name',
    'hire date',
    'travel time',
    'hourly wages',
    'annual salary',
    'employment status',
    'emp reason',
    'employment length years',
    'employment length months',
    'employment terminated',
    'termination date',
    'comments'
  ]
};

const DrugScreenQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  },
  component: <DrugScreenQuery />,
  labels: [
    'drug screen id',
    'case id',
    'screening date',
    'result date',
    'official id',
    'drug screen type',
    'comments',
    'cannabinoids',
    'cannabinoids ratio',
    'opiates',
    'benzodiazepines',
    'cocaine metabolite',
    'barbiturates',
    'phencyclidine',
    'buprenorphine',
    'spice',
    'lsd',
    'bath salts',
    'amphetamine',
    'methamphetamine',
    'oxycodone',
    'propoxyphene',
    'methadone',
    'ethanol',
    'pbt results',
    'p.b.t results',
    'creatine',
    'other (name)',
    'other (result)',
    'other (ratio)'
  ]
};

const CaseQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 8
  },
  component: <CaseQuery />,
  labels: [
    'case',
    'program entry date',
    'completion date',
    'max out date',
    'status name',
    'offense level',
    'offense description',
    'received description',
    'comments',
    'risk level',
    'disposition',
    'sentence date',
    'sentence years',
    'sentence days',
    'habitual enhancement',
    'low risk monitoring'
  ]
};

const LevelOfSupervisionObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <LevelOfSupervision />,
  labels: [
    'level of supervision',
    'case id',
    'component name',
    'begin date',
    'end date',
    'referral type',
    'status name',
    'official name'
  ]
};

const ProgramsQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <ProgramsQuery />,
  labels: [
    'program name'
  ]
};

const CommunitySupervisionObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <CommunitySupervision />,
  labels: [
    'community supervision',
    'case id',
    'assessment date',
    'criminal score',
    'family score',
    'accommodation score',
    'alcohol score',
    'companion score',
    'attitudes score'
  ]
};

const AdditionalChargesObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <AdditionalCharges />,
  labels: [
    'additional charges',
    'case id',
    'case number',
    'offense level',
    'offense description',
    'sentence term',
    'case details'
  ]
};

const TreatmentQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 8
  },
  component: <TreatmentQuery />,
  labels: [
    'treatment',
    'initial case management date',
    'goal date',
    'completion date',
    'provider name',
    'provider address',
    'provider city',
    'provider zip',
    'treatment type',
    'treatment status',
    'treatment end type',
    'case plan domain',
    'comments'
  ]
};

const CourtOrdersQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <CourtOrdersQuery />,
  labels: [
    'court orders',
    'entry date',
    'length conditions',
    'court',
    'report days',
    'progress report',
    'comments'
  ]
};

const CsstAssessmentObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <CsstAssessment />,
  labels: [
    'csst assessment',
    'case id',
    'assessment date',
    'arrest score',
    'felony score',
    'misconduct score',
    'prior score',
    'residential score',
    'employment score',
    'financial score',
    'drug use scpre',
    'fight score',
    'comments'
  ]
};

const ConductViolationQueryObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <ConductViolationQuery />,
  labels: [
    'conduct violation',
    'case id',
    'official name',
    'report date',
    'violation date',
    'violation time',
    'sanction type',
    'comments'
  ]
};

const PatAssessmentObject = {
  componentData: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4
  },
  component: <PatAssessment />,
  labels: [
    'pat assessment',
    'assessment id',
    'case id',
    'assessment date',
    'age score',
    'fta score',
    'jail score',
    'employment score',
    'drug use score',
    'severe use score'
  ]
};


const SearchableObjects = [DemographicsObject, AddressQueryObject, AutomotiveQueryObject, AppointmentQueryObject,
  EducationQueryObject, EmploymentQueryObject, DrugScreenQueryObject, CaseQueryObject, LevelOfSupervisionObject,
  ProgramsQueryObject, CommunitySupervisionObject, AdditionalChargesObject, TreatmentQueryObject, CourtOrdersQueryObject,
  CsstAssessmentObject, ConductViolationQueryObject, PatAssessmentObject];


export default SearchableObjects;
