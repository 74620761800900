/* eslint-disable */
import {
  Container, Card, CardHeader, CardContent, Typography,
  makeStyles, TableRow, TableCell, Table, Grid, Link, Divider, TableHead
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { getRWATInfo } from '../api';
import { useLocation } from 'react-router';

let RWATdataSkeleton = {
  cloestRelationScore: {
    label: 'Closest Relation Score',
    value: null
  },
  cogFlexScore: {
    label: 'Cognitive Flexibility Score',
    value: null
  },
  comAndLeisureScore: {
    label: 'Leisure Score',
    value: null
  },
  dCopeScore: {
    label: 'Dysfunctional Coping Score',
    value: null
  },
  eCopeScore: {
    label: 'Emotion-Focused Coping Score',
    value: null
  },
  educationScore: {
    label: 'Education Score',
    value: null
  },
  empathyScore: {
    label: 'Empathy Score',
    value: null
  },
  employScore: {
    label: 'Employment Score',
    value: null
  },
  hopeScore: {
    label: 'Hope Score',
    value: null
  },
  mostTimeRelationScore: {
    label: 'Most Time Relation Score',
    value: null
  },
  pCopeScore: {
    label: 'Problem-Focused Coping Score',
    value: null
  },
  perceptionOfWorthScore: {
    label: 'Perception of Worth Score',
    value: null
  },
  positiveRelationCount: {
    label: 'Positive Relation Count',
    value: null
  }

};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  valueCell: {
    paddingRight: '10px'
  },
  row: {
    borderBottomStyle: 'hidden'
  },
  cell: {
    padding: '12px'
  },
  label: {
    fontSize: 16
  },
  value: {
    fontSize: 14
  },
  question: {
    margin: '16px',
    paddingRight: '16px'
  },
  info: {
    paddingBottom: '20px'
  }
}));

const RWATinfo = () => {

  const classes = useStyles();

  const location = useLocation();

  const [state, setState] = useState(location.state.data);


  const participant = useContext(ParticipantContext);
  const [RWATdata, setRWATdata] = useState(RWATdataSkeleton);
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    getRWATInfo(participant)
      .then((data) => {
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            if (key in RWATdata) {
              setRWATdata(prevState => ({
                ...prevState,
                [key]: {
                  label: RWATdata[key].label,
                  value: value
                }
              }));
            }
          }
          setLoaded(true);
        }
      });
  }, [participant.id]);

  if (!loaded) {
    return null;
  } else {

    return (
      <Page>
        <Container maxWidth={'md'}>
          <Card>
            <CardContent>
              <CardHeader
                titleTypographyProps={{ variant: 'h5', component: 'span' }}
                title='RWAT Response Summary'
                style={{ backgroundColor: '#F4F6F8' }}
              />
              <Container>

                <Grid container direction={'column'}>
                  <Grid item>
                    <Grid container direction={'row'}>

                      <Grid item>
                        <Table>

                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <Typography variant={'h6'} className={classes.label}>
                                ASSESSMENT
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Typography className={classes.value}>
                                {state.assessmentType}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <Typography variant={'h6'} className={classes.label}>
                                STATUS
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Typography className={classes.value}>
                                {state.status}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <Typography variant={'h6'} className={classes.label}>
                                STARTED
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Typography className={classes.value}>
                                {state.meetingStartTime}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <Typography variant={'h6'} className={classes.label}>
                                COMPLETED
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Typography className={classes.value}>
                                {state.actualEndTime}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </Table>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ borderBottomWidth: 50 }} />

                <Container>
                  <Table>

                    <TableHead>
                      <TableCell>
                        <Typography variant={'h5'}>
                          Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h5'} align={'right'}>
                          Score
                        </Typography>
                      </TableCell>
                    </TableHead>

                    {Object.keys(RWATdata).map((key) => (
                      <TableRow>
                        <TableCell align={'justify'} size={'medium'}>

                          <Typography variant='h6'>
                            {RWATdata[key].label}
                          </Typography>
                        </TableCell>
                        <TableCell align={'left'}>

                          <Typography align={'right'}>
                            {Math.round(RWATdata[key].value * 100) / 100}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Container>
              </Container>
            </CardContent>
          </Card>
        </Container>
      </Page>
    );
  }
};

export default RWATinfo;
