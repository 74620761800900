/* eslint-disable */
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {Polygon, Popup, Circle, CircleMarker, LayersControl, LayerGroup, Tooltip} from 'react-leaflet';
import theme from 'src/theme';
import {getGeneralRestrictions, getGeoRestrictionProfile} from '../../../exclusion/api';
import {ParticipantContext} from "../../../../context/ParticipantContext";
import findObjectCenter from "../../../../utils/findObjectCenter";


const RestrictedGeneral = ({ setZoneGroup }) => {
  const [generalExclusionData, setGeneralExclusionData] = useState([]);
  const [generalState, setGeneralState] = useState({
    barRestricted: false,
    barTriggerDistance: 0,
    id: 0,
    playgroundRestricted: false,
    playgroundTriggerDistance: 0,
    schoolRestricted: false,
    schoolTriggerDistance: 0
  });

  const participant = useContext(ParticipantContext);

  useEffect(() => {

    getGeoRestrictionProfile(participant)
      .then((r) => {
        if (r.status === 200) {
          setGeneralState(r.data);
        }
      });

    getGeneralRestrictions(participant)
      .then((r) => {
        if (r.status === 200) {
          let tempData = []
          r.data.map((zone) => {
            if (zone.geom.type === 'Point') {
              tempData.push(zone)
            } else if (zone.geom.type === 'Polygon') {
              zone.geom.coordinates[0].map((coords) => {
                coords.reverse()
              })
              tempData.push(zone)
            }
          })
          setGeneralExclusionData(tempData)
        }
      });
  }, [participant.id]);

  let restrictedZoneGroup;
  useEffect(() => {
    restrictedZoneGroup = [];
    if (generalExclusionData.length != 0) {
      restrictedZoneGroup.push(
        <LayersControl.Overlay checked={true} name={'General Restriction Zones'}>
          <LayerGroup>
            {generalExclusionData.map((zone) => {
              let zoneColor;
              if (zone.category === 'school') {
                zoneColor = { color: 'blue', fillOpacity: 0.5, opacity: 1 };
              } else if (zone.category === 'playground') {
                zoneColor = { color: 'green', fillOpacity: 0.5, opacity: 1 };
              } else if (zone.category === 'bar') {
                zoneColor = { color: 'orange', fillOpacity: 0.5, opacity: 1 };
              }


              if (zone.geom.type === 'Point') {
                return (
                  <CircleMarker
                    center={[zone.geom.coordinates[1], zone.geom.coordinates[0]]}
                    pathOptions={zoneColor}
                    radius={2}
                    fillOpacity={1}
                    opacity={1}
                  />
                );

              } else if (zone.geom.type === 'Polygon') {
                return (
                  <Polygon
                    pathOptions={zoneColor}
                    positions={zone.geom.coordinates[0]}
                    fillOpacity={0.5}
                    opacity={1}
                  />
                );
              }
            })}
          </LayerGroup>
        </LayersControl.Overlay>
      );

      restrictedZoneGroup.push(
        <LayersControl.Overlay checked={true} name={'General Restriction Radius'}>
          <LayerGroup>
            {generalExclusionData.map((zone) => {
              if (generalState) {

                let center = findObjectCenter(zone);
                let radius;
                let zoneColor;
                let zoneInclude = false;
                if (zone.category === "school" && generalState.schoolRestricted)
                {
                  zoneInclude = true;
                  radius = generalState.schoolTriggerDistance;
                  zoneColor = { color: 'blue', fillOpacity: 0.2, opacity: 1 };
                }
                else if (zone.category === "bar" && generalState.barRestricted)
                {
                  zoneColor = { color: 'orange', fillOpacity: 0.2, opacity: 1 };
                  zoneInclude = true;
                  radius = generalState.barTriggerDistance;
                }
                else if (zone.category === 'playground' && generalState.playgroundRestricted)
                {
                  zoneInclude = true;
                  radius = generalState.playgroundTriggerDistance;
                  zoneColor = { color: 'green', fillOpacity: 0.2, opacity: 1 };
                }

                if (zoneInclude)
                {
                  return (
                    <Circle
                      center={{lat: center[1], lng: center[0]}}
                      radius={radius}
                      pathOptions={zoneColor}
                      //fillOpacity={0.1}
                      //opacity={0.7}
                    >
                      <Popup>
                        {zone.locationName}<br/>
                        ID: {zone.id}<br/>
                        Category: {zone.category}<br/>
                        Trigger Radius: {Math.round(radius * 3.28)} ft.<br/>
                        Center: {center[1].toFixed(5)}, {center[0].toFixed(5)}<br/>
                      </Popup>
                    </Circle>
                  );
                }
                else {
                  return (<></>)
                }
              }
            })};
          </LayerGroup>
        </LayersControl.Overlay>
      );
      setZoneGroup(restrictedZoneGroup);
    }
  }, [generalState, generalExclusionData]);

  return (
    <div>
      {restrictedZoneGroup}
    </div>
  );
}

RestrictedGeneral.propTypes = {
  className: PropTypes.string
};

export default RestrictedGeneral;
