import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import firebase from 'firebase/compat/app';

import './styles.css';

firebase.initializeApp({
  apiKey: "AIzaSyC1A5cSaGy3_pfj9Mpd2Vw8eBmRl9d3ws4",
  authDomain: "nij-authentication.firebaseapp.com",
  projectId: "nij-authentication",
  storageBucket: "nij-authentication.appspot.com",
  messagingSenderId: "17504360767",
  appId: "1:17504360767:web:663eceabb9dd7724b5db8a",
  measurementId: "G-FFXJGTTMFS"
});

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
