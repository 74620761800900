/* eslint-disable */
import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles, Radio, RadioGroup,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalPaper: {
    position: 'absolute',
    width: '560px',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  margin: {
    margin: '10px 0px 10px 0px'
  }
}));

const AddAppointmentDialog = ({ addAppointment, openDialog, handleCloseDialog }) => {
  const classes = useStyles();

  // variable to hold date input
  const [selectedDate, setSelectedDate] = useState(new Date())
  // variable to hold time input
  const [startTime, setStartTime] = useState(new Date())
  // default state
  const [state, setState] = useState({
    title: null,
    required: false,
    address: null,
    type: 'in-person',
    purpose: null,
    end_time: new Date().toISOString(),
    start_time: new Date().toISOString(),
    duration: 0,
  })

  // track if there is an error in the inputs
  const [error, setError] = useState(true)

  // handles changes in date,
  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  // handles changes in start time
  const handleTimeChange = (date) => {
    setStartTime(date)
  }

  // handles changes in type
  const handleTypeChange = (event) => {
    let value = event.target.value;

    setState((prevState) => {
      return {
        ...prevState,   // Spread Operator
        ['type']: value
      };
    });
  };

  // handles changes in required
  const handleRequiredChange = (event) => {
    let value = event.target.checked;

    setState((prevState) => {
      return {
        ...prevState,   // Spread Operator
        ['required']: value
      };
    });
  };

  // handles changes in state
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    // sets error value if duration is 0 or less
    if (name === 'duration') {
      if (value > 0) {
        setError(false)
      } else {
        setError(true)
      }
    }

    setState((prevState) => {
      return {
        ...prevState,   // Spread Operator
        [name]: value
      };
    });
  };

  // handles saving the new appointment
  const handleSave = (e) => {
    e.preventDefault();

    if (error) {
      return
    }

    let sentStartTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(),
      startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
    let sentEndTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(),
      startTime.getHours(), startTime.getMinutes() + parseInt(state.duration), startTime.getSeconds());

    addAppointment(state, sentStartTime, sentEndTime)

    setSelectedDate(new Date());
    setStartTime(new Date());
    setState({
      title: null,
      required: false,
      address: null,
      type: 'in-person',
      purpose: null,
      end_time: new Date().toISOString(),
      start_time: new Date().toISOString(),
      duration: 0
    });
    setError(true)

    handleCloseDialog();

  }

  return (
    <div>
      <form onSubmit={handleSave}>
        <Dialog open={openDialog} onClose={handleCloseDialog} disablePortal>
          <DialogContent>
            <FormControl fullWidth className={classes.margin} required>
              <TextField
                required
                name={'title'}
                label='Title'
                multiline
                variant={'filled'}
                value={state.title}
                onChange={handleChange}
              />
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils} required>
              <Grid container justify='space-between'>
                <Grid item>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='Date'
                    required
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </Grid>

                <Grid item>
                  <KeyboardTimePicker
                    margin='normal'
                    id='time-picker'
                    label='Start Time'
                    required

                    value={startTime}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            <FormControl fullWidth className={classes.margin} required>
              <TextField
                id={'duration'}
                name={'duration'}
                error={state.duration <= 0}
                helperText={'Value must be greater than 0'}
                label={'Duration (minutes)'}
                onChange={handleChange}
                type={'number'}
                value={state.duration}
                variant={'filled'}
              />
            </FormControl>

            <FormControl fullWidth required>
              <FormControlLabel
                control={<Checkbox
                  name={'required'}
                  checked={state.required}
                  onChange={handleRequiredChange}
                />}
                label='Required' />
            </FormControl>

            <FormControl fullWidth className={classes.margin} required>
              <TextField
                name={'address'}
                label='Appointment Address'
                multiline
                required
                variant={'filled'}
                value={state.address}
                onChange={handleChange}

              />
            </FormControl>

            <FormControl fullWidth className={classes.margin} required>
              <FormLabel id='demo-row-radio-buttons-group-label'>Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <FormControlLabel
                  value='in-person'
                  control={
                    <Radio
                      name={'in-person'}
                      checked={state.type === 'in-person'}
                      onChange={handleTypeChange}
                    />}
                  label='In-Person'
                />
                <FormControlLabel
                  value='zoom'
                  control={
                    <Radio
                      name={'zoom'}
                      checked={state.type === 'zoom'}
                      onChange={handleTypeChange}
                    />}
                  label='Zoom'
                />
                <FormControlLabel
                  value='phone'
                  control={
                    <Radio
                      name={'phone'}
                      checked={state.type === 'phone'}
                      onChange={handleTypeChange}
                    />}
                  label='Phone'
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth className={classes.margin} required>
              <TextField
                name={'purpose'}
                label='Description'
                multiline
                required

                rows={4}
                variant={'filled'}
                value={state.purpose}
                onChange={handleChange}
              />
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  )
}

export default AddAppointmentDialog;
