/* eslint-disable */
import React, { useContext, useMemo } from 'react';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

function LineChart ({ dataIn, timeUnit }) {
  const timeData = []
  const scoreData = []

  dataIn.map((instance) => {
    timeData.push(instance.time);
    scoreData.push(instance.sales);
  });

  const data = {
    labels: timeData,
    datasets: [
      {
        label: 'Score',
        data: scoreData,
        borderColor: 'limegreen',
        backgroundColor: '#32CD3260',
      },
    ]
  };

  return (
    <>
      <div className="lineChartContainer" style={{position: "relative"}}>
        <Line
          options={{
            plugins: {
              datalabels: {
                display: false,
              }
            },
            responsive: true,
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Period"
                }
              },
              y: {
                title: {
                  display: true,
                  text: "Score"
                }
              }
            }
          }}
          data={data}
        />
      </div>
    </>
  );
}

export default LineChart;
