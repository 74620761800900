/* eslint-disable */
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
const lcpProxy = 'http://localhost:8010/proxy'

export const getViolations = async (participant, period, startTime, endTime) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/analytics/violations`, {
      params: {
        endTime: endTime,
        participantId: participant.id,
        period: period,
        startTime: startTime,
      }
    })
    return data
  } catch (error) {
    console.log(error)
  }
};

export const getStressScores = async (participant, period, startTime, endTime) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/analytics/stress-scores`, {
      params: {
        endTime: endTime,
        participantId: participant.id,
        period: period,
        startTime: startTime,
      }
    })
    return data

  } catch (error) {
    console.log(error)
  }
};

export const getScreenTime = async (participant, period, startTime, endTime) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/analytics/screen-time/count`, {
      params: {
        endTime: endTime,
        participantId: participant.id,
        period: period,
        startTime: startTime,
      }
    })
    return data
  } catch (error) {
    console.log(error)
  }
};

export const getHeartRate = async (participant, period, startTime, endTime) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/analytics/heartrate/average`, {
      params: {
        endTime: endTime,
        participantId: participant.id,
        period: period,
        startTime: startTime,
      }
    })
    return data
  } catch (error) {
    console.log(error)
  }
};

export const getViolationData = async (participant) => {
  try {

    const { data } = await axios.get(`${BASE_URL}/api/nij/ai-sms/location/violation-data`, {
      params: {
        userId: participant.id
      }
    })

    return data

  } catch (error) {
    console.log(error.response)
  }
}

export const getHeartRateAll = async (period, startTime, endTime) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/analytics/heartrate/average/all`, {
      params: {
        endTime: endTime,
        period: period,
        startTime: startTime,
      }
    })
    return data
  } catch (error) {
    console.log(error)
  }
};


