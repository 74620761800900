/* eslint-disable */
import { useD3 } from '../../analytics/AnalyticsView/useD3';
import * as d3 from 'd3';
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

function ParallelCoordinatesChart ({ currentId, data, averageScore, widthIn, heightIn }) {
  const [wait, setWait] = useState(false);

  useEffect(() => {
    if (averageScore && data.length > 0)
    {
      setWait(true)
    }
  }, [averageScore, data]);


  // Color scale: give me a specie name, I return a color
  const color = d3.scaleOrdinal()
    .domain(["otherUsers", "average", "currentUser"])
    .range(["#003bffff", "#000000ff", "#FF0000ff"])

  const chartRef = useD3(
    (svg) => {

      if (wait)
      {
        d3.selectAll(".currentUser")
          .remove()
        d3.selectAll(".otherUsers")
          .remove()
        d3.selectAll(".lineAverage")
          .remove()
        d3.selectAll(".axis")
          .remove()

        // set the dimensions and margins of the graph
        const margin = {top: 30, right: 50, bottom: 10, left: 50}, //!!!! top 30 right 50, bottom 10 left 50
          width = widthIn - margin.left - margin.right, //460
          height = heightIn - margin.top - margin.bottom; //400

        svg
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform",
            `translate(${margin.left},${margin.top})`);

        // Here I set the list of dimension manually to control the order of axis:
        let dimensions;
        dimensions = ["Five Key", "Health", "Meeting", "Restriction"]

        // For each dimension, I build a linear scale. I store all in a y object
        const y = {}
        let i;
        for (i in dimensions) {
          let name = dimensions[i]
          y[name] = d3.scaleLinear()
            .domain([0, 400])
            .range([height, 25]) //.range([height, 0])
        }

        // Build the X scale -> it find the best position for each Y axis
        const x = d3.scalePoint()
          .range([45, width]) //.range([0, width])
          .domain(dimensions);

        // Highlight the data entry that is hovered
        const highlight = function (event, d) {
          let selected_species;
          selected_species = d.participantId == currentId ? "currentUser" : "otherUsers"

          // first every group turns grey
          d3.selectAll("." + "currentUser")
            .transition().duration(200)
            .style("stroke", "lightgrey")
            .style("opacity", "0.2")
          d3.selectAll("." + "otherUsers")
            .transition().duration(200)
            .style("stroke", "lightgrey")
            .style("opacity", "0.2")
          // Second the hovered specie takes its color
          d3.selectAll("." + selected_species)
            .transition().duration(200)
            .style("stroke", color(selected_species))
            .style("opacity", "1")
        }

        // Unhighlight
        const doNotHighlight = function (event, d) {
          d3.selectAll("." + "currentUser").transition().duration(200).delay(1000)
            .style("stroke",color("currentUser"))
            .style("opacity", "1")
          d3.selectAll("." + "otherUsers")
            .transition().duration(200).delay(1000)
            .style("stroke",color("otherUsers"))
            .style("opacity", "1")
        }

        // The path function takes in one data entry, and return x and y coordinates of the line to draw for this raw.
        function path(d) {
          return d3.line()(dimensions.map(function (p) {
            return [x(p), y[p](p == "Five Key" ? d.fiveKeyScore
              : (p == "Health" ? d.healthScore : (p == "Meeting" ? d.meetingScore : d.restrictionScore)))];
          }));
        }

        //Path function for Average line since it has different data
        function pathAverage(d) {
          return d3.line()(dimensions.map(function (p) { //change this mapping to the score data elements
            return [x(p), y[p](p == "Five Key" ? d.avgFivekeyPoint
              : (p == "Health" ? d.avgHealthPoint : (p == "Meeting" ? d.avgMeetingPoint : d.avgRestrictionPoint)))];
          }));
        }

        let tempD;

        // Draw the blue other user lines (currentUser gets drawn twice for reasons)
        svg
          .selectAll("myPath")
          .data(data)
          .join("path")
          .attr("class", function (d) {
            if (d.participantId == currentId) tempD = d;
            return "line " +  (d.participantId == currentId ? "currentUser" : "otherUsers")
          }) // 2 class for each line: 'line' and the group name
          .attr("d", path)
          .style("fill", "none")
          .style("stroke", function (d) {
            return (d.participantId == currentId ? color("currentUser") : color("otherUsers"))
          })
          //.attr("stroke-width", 1.5)
          .style("opacity", 1) //0.5
          .on("mouseover", highlight)
          .on("mouseleave", doNotHighlight)

        // Draw the black average line over the blue lines
        svg
          .selectAll("averagePath")
          .data([averageScore])
          .join("path")
          .attr("class", "lineAverage")
          .attr("d", pathAverage)
          .style("fill", "none")
          .style("stroke", color("average"))
          .attr("stroke-width", 1.2)
          .style("opacity", 1)
        .on("mouseover", highlight)
        .on("mouseleave", doNotHighlight)

        // Draw the real red Current User line over all others
        svg
          .selectAll("myPath")
          .data([tempD])
          .join("path")
          .attr("class", "currentUser")
          .attr("d", path)
          .style("fill", "none")
          .style("stroke", color("currentUser"))
          .attr("stroke-width", 1.5)
          .style("opacity", 1) //0.5
          .on("mouseover", highlight)
          .on("mouseleave", doNotHighlight)

        // Draw the axis:
        svg.selectAll("myAxis")
          // For each dimension of the dataset I add a 'g' element:
          .data(dimensions).enter()
          .append("g")
          .attr("class", "axis")
          .style("stroke", "#666")
          .style("stroke-width", "0.3")
          // I translate this element to its right position on the x axis
          .attr("transform", function (d) {
            return `translate(${x(d)})`
          })
          // And I build the axis with the call function
          .each(function (d) {
            d3.select(this).call(d3.axisLeft().ticks(9).scale(y[d]));
          })
          // Add axis title
          .append("text")
          .style("text-anchor", "middle")
          .style("font-size", "12px")
          .attr("y", 15) //.attr("y", -9)
          .text(function (d) {
            return d;
          })
          .attr("font-family", "sans-serif")
          //.attr("letter-spacing", ".05em")
          //.attr("font-weight", 600)
          .style("fill", "#666")


      }
    }, [wait, data, averageScore, widthIn]
  )


  return (
    <svg
      ref={chartRef}
      style={{
        height: 700,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area"/>
      <g className="x-axis"/>
      <g className="y-axis"/>
    </svg>
  );
};

ParallelCoordinatesChart.propTypes = {
  className: PropTypes.string
};

export default ParallelCoordinatesChart;
