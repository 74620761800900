import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import GameStats from './GameStats';
import TaskIndex from '../Tasks/TaskIndex';
import RewardIndex from '../Rewards/RewardIndex';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContent: {
    height: '100%'
  }
}));

const GameView = () => {

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={'Game and Tasks'}
    >
      <Card>

        <Grid container>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <CardContent>
              <GameStats />
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
          >
            <CardContent className={classes.cardContent}>
              <TaskIndex />
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
          >
            <CardContent className={classes.cardContent}>
              <RewardIndex />
            </CardContent>
          </Grid>


        </Grid>
      </Card>


    </Page>
  );
};

export default GameView;
