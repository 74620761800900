/* eslint-disable */
import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import scoreData24Hours from '../../../data/ScoreChartData_24Hours.json';
import scoreDataWeek from '../../../data/ScoreChartData_Week.json';
import scoreDataMonth from '../../../data/ScoreChartData_Month.json';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles, withStyles, Box, CardHeader, Button
} from '@material-ui/core';
import {ParticipantContext} from "../../../context/ParticipantContext";
import {getUserLevel, getUserScore} from "../api";
import BarChart from "../Charts/BarChart";
import NativeSelect from "@material-ui/core/NativeSelect";
import LineChart from "../Charts/LineChart";
import ScoreRanking from "./ScoreRanking";
import GameVista from './GameVista';
import GameVistaScreenshot from './GameVistaScreenshots';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background,
  },
  levelTextContainer: {
    margin: '0px 30px 0px 0px',
    width: '50%',
    height: 200,
  },
  gameViewContainer: {
    width: '50%',
    height: 250,
  },
  barChartContainer: {
    margin: '0px 30px 0px 0px',
    width: '50%',
  },
  lineChartContainer: {
    margin: '30px 0px 0px 0px',
    width: '40%'
  },
  progressBarContainer: {
    margin: '10px 100px 10px 100px',
  },
  progressBarText: {
    padding: '0px 0px 0px 10px',
    fontSize: '18px',
  },
  barChartDropdownText: {
    fontSize: '14px',
  },
  lineChart: {

  },
  levelText: {
    paddingLeft: '60px',
    paddingTop: '10px',
  },
  grayText: {
    paddingLeft: '60px',
    paddingTop: '10px',
    fontSize: 50,
    color: '#565656'
  },
  greenText: {
    paddingLeft: '60px',
    color: '#07F52D'
  },
  redText: {
    paddingLeft: '60px',
    color: '#FF0000'
  },
}));

function convertScore(score) {
  let level = score/100
  if (level >= 4) level = 3
  return(Math.floor(level))
}

function convertFiveKeyScoresToLevels(userData) {
  let levels = [0, 0, 0, 0, 0]

  levels[0] = convertScore(userData.copingScore)
  levels[1] = convertScore(userData.thinkingScore)
  levels[2] = convertScore(userData.relationshipScore)
  levels[3] = convertScore(userData.socialScore)

  return levels.join('')
}

const GameStats = ({ className, range, ...rest }) => {
  const classes = useStyles();

  const participantContext = useContext(ParticipantContext);
  const [userData, setUserData] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [todayChange, setTodayChange] = useState([382, 388]);

  const [lineData, setLineData] = useState(scoreData24Hours.data);
  const [chartTimeUnit, setChartTimeUnit] = useState("24Hours");

  const [levelNumber, setLevelNumber] = useState(0)

  const [imageKey, setImageKey] = useState(`00000`)

  useEffect(() => {
    getUserScore(participantContext)
      .then((data) => {
        setUserData(data)
        setTotalScore(data.score)
        setImageKey(convertFiveKeyScoresToLevels(data))
      });
  }, [participantContext.id]);

  useEffect(() => {
    getUserLevel(participantContext)
      .then((data) => {
        setLevelNumber(data.userLevel)
      });
  }, [participantContext.id]);

  useEffect(() => {

  }, [lineData]);

  const handleChangeChartType = (event) => {
    let type = event.target.value;
    setChartTimeUnit(type);
    if (type == "24Hours") {
      setLineData(scoreData24Hours.data);
    }
    if (type == "Week") {
      setLineData(scoreDataWeek.data);
    }
    if (type == "Month") {
      setLineData(scoreDataMonth.data);
    }
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title={'Game Stats'}
      />
      <CardContent>
        <Grid
          container
          spacing={1}>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
            className={classes.levelTextContainer}
          >
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h1"
              className={classes.levelText}
            >
              Level {levelNumber}<br />
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h1"
              className={classes.grayText}
            >
              {totalScore}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h1"
              className={todayChange[1] - todayChange[0] < 0 ? classes.redText : classes.greenText}
            >
              {todayChange[1] - todayChange[0] < 0 ? "" : "+"}
              {todayChange[1] - todayChange[0] < 0
                ? todayChange[1] - todayChange[0] + " (" + ((todayChange[0] - todayChange[1]) / todayChange[0] * 100).toFixed(1) + "%)"
                :todayChange[1] - todayChange[0] + " (" + ((todayChange[1] / todayChange[0] - 1) * 100).toFixed(2) + "%)"}
              <br />
            </Typography>

          </Grid>
          <Grid
            item
            lg={5}
            sm={6}
            xl={5}
            xs={12}
            className={classes.gameViewContainer}
          >
            <GameVistaScreenshot key={'screenshot'} fileName={`${imageKey}.png`} alt={`Game Vista Screenshot ${imageKey}`}/>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
            className={classes.barChartContainer}
          >
            <BarChart
              progress={userData}
            />
          </Grid>
          <Grid
            item
            lg={5}
            sm={6}
            xl={5}
            xs={12}
            className={classes.lineChartContainer}
          >
            <Grid item>
              <NativeSelect
                onChange={handleChangeChartType}
                inputProps={{
                  name: 'chartType',
                  id: 'barChartDropdown',
                }}
                className={classes.barChartDropdownText}
              >
                <option value="24Hours">24 hours</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </NativeSelect>
            </Grid>
            <LineChart
              dataIn={lineData}
              timeUnit={chartTimeUnit}
              className={classes.lineChart}
              color="SkyBlue"
            />
          </Grid>
        </Grid>
        <ScoreRanking />
      </CardContent>
    </Card>
  );
};

GameStats.propTypes = {
  className: PropTypes.string
};

export default GameStats;
