/* eslint-disable */
import { useState, useContext } from 'react';
import {
  Popover,
  Button,
  List,
  makeStyles,
} from '@material-ui/core';

import NotificationBadge from './NotificationBadge';

import { ParticipantContext } from '../../../context/ParticipantContext';
import { NotificationContext } from '../../../context/NotificationContext';

const useStyles = makeStyles(() => ({
  notificationButton: {
    minWidth: '0px',
    padding: '7px',
  },
}));

const NotificationIconList = () => {
  const classes = useStyles();
  const participantContext = useContext(ParticipantContext);
  const notificationContext = useContext(NotificationContext);

  const [popoverNotificationList, setPopoverNotificationList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const HandleNotificationBadgeClick = (event, type) => {
    let list = [];

    if (type == "meeting") {
      list = notificationContext.notificationByParticipant_meeting.get(participantContext.id);
    } else if (type == "device") {
      list = notificationContext.notificationByParticipant_device.get(participantContext.id);
    } else if (type == "battery") {
      list = notificationContext.notificationByParticipant_battery.get(participantContext.id);
    } else if (type == "heart") {
      list = notificationContext.notificationByParticipant_heart.get(participantContext.id);
    } else if (type == "stress") {
      list = notificationContext.notificationByParticipant_stress.get(participantContext.id);
    } else if (type == "geo") {
      list = notificationContext.notificationByParticipant_geo.get(participantContext.id);
    }

    if (list != []) {
      setPopoverNotificationList(
        <List>
          {list}
        </List>
      );
      setAnchorEl(event.currentTarget);
      return;
    }
    setPopoverNotificationList([]);
    setAnchorEl(null);
  };

  const HandleNotificationBadgeClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "meeting")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_meeting.get(participantContext.id)}
          type="meeting"
        />
      </Button>

      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "device")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_device.get(participantContext.id)}
          type="device"
        />
      </Button>

      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "battery")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_battery.get(participantContext.id)}
          type="battery"
        />
      </Button>

      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "heart")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_heart.get(participantContext.id)}
          type="heart"
        />
      </Button>

      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "stress")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_stress.get(participantContext.id)}
          type="stress"
        />
      </Button>

      <Button
        aria-describedby={popoverId}
        variant="contained"
        color="primary"
        onClick={(e) => HandleNotificationBadgeClick(e, "geo")}
        className={classes.notificationButton}
      >
        <NotificationBadge
          notificationData={notificationContext.notificationByParticipant_geo.get(participantContext.id)}
          type="geo"
        />
      </Button>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={HandleNotificationBadgeClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverNotificationList}
      </Popover>
    </>
  );
};

export default NotificationIconList;
