/* eslint-disable */
import axios from 'axios';
import FormData from 'form-data';

const CATEGORY_URL = `${process.env.REACT_APP_BASE_URL}/api/nij/ai-sms/reentry/profile/v2`;
const UPDATE_IMPORT_URL = `${process.env.REACT_APP_BASE_URL}/participant`;
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
const lcpProxy = 'http://localhost:8010/proxy';

export const getCategoryData = async (participant, category) => {
  try {
    const { data } = await axios.get(`${CATEGORY_URL}`, {
      params: {
        profileId: participant.id,
        category: category
      }
    });


    return data;

  } catch (error) {
    console.log(error);
  }
};

export const uploadFileData = async (participant, category, file) => {
  try {
    const filedata = new FormData();
    filedata.append('file', file);
    filedata.append('file_type', category);
    filedata.append('profileId', participant.id);

    return await axios({
      method: 'post',
      url: `${UPDATE_IMPORT_URL}/import`,
      data: filedata,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateAddress = async (participant, state, id) => {
  try {
    return await axios.put(`${UPDATE_IMPORT_URL}/address`, {
      addressType: state.addressType.value,
      countyName: state.countyName.value,
      createdDate: state.createdDate.value,
      leaseExpiration: state.leaseExpiration.value,
      lengthResidence: state.lengthResidence.value,
      neighborhoodIssues: state.neighbourhoodIssues.value,
      ownershipType: state.ownershipType.value,
      profileId: participant.id,
      updatedDate: state.updatedDate.value,
      id: id
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createAddress = async (participant, state, id) => {
  try {
    return await axios.post(`${UPDATE_IMPORT_URL}/address`, {
      addressType: state.addressType.value,
      countyName: state.countyName.value,
      createdDate: state.createdDate.value,
      leaseExpiration: state.leaseExpiration.value,
      lengthResidence: state.lengthResidence.value,
      neighborhoodIssues: state.neighbourhoodIssues.value,
      ownershipType: state.ownershipType.value,
      profileId: participant.id,
      updatedDate: state.updatedDate.value,
      id: id
    });

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateCase = async (participant, state, id) => {
  try {

    const response = await axios.put(`${UPDATE_IMPORT_URL}/cases`, {
      comments: state.comments.value,
      completionDate: state.completionDate.value,
      disposition: state.disposition.value,
      habitualEnhancement: state.habitualEnhancement.value,
      id: id,
      lowRiskMonitoring: state.lowRiskMonitoring.value,
      maxOutDate: state.maxOutDate.value,
      profileId: participant.id,
      offenseLevel: state.offenseLevel.value,
      offenseDescription: state.offenseDescription.value,
      programEntryDate: state.programEntryDate.value,
      receivedDescription: state.receivedDescription.value,
      riskLevel: state.riskLevel.value,
      sentenceDate: state.sentenceDate.value,
      sentenceDays: state.sentenceDays.value,
      sentenceYears: state.sentenceYears.value,
      statusName: state.statusName.value
    });


    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createCase = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/cases`, {
      comments: state.comments.value,
      completionDate: state.completionDate.value,
      disposition: state.disposition.value,
      habitualEnhancement: state.habitualEnhancement.value,
      id: id,
      lowRiskMonitoring: state.lowRiskMonitoring.value,
      maxOutDate: state.maxOutDate.value,
      profileId: participant.id,
      offenseLevel: state.offenseLevel.value,
      offenseDescription: state.offenseDescription.value,
      programEntryDate: state.programEntryDate.value,
      receivedDescription: state.receivedDescription.value,
      riskLevel: state.riskLevel.value,
      sentenceDate: state.sentenceDate.value,
      sentenceDays: state.sentenceDays.value,
      sentenceYears: state.sentenceYears.value,
      statusName: state.statusName.value
    });


    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateAdditionalCharges = async (participant, state, id) => {
  try {

    const response = await axios.put(`${UPDATE_IMPORT_URL}/additional-charges`, {
      caseDetails: state.caseDetails.value,
      caseId: state.caseId.value,
      caseNumber: state.caseNumber.value,
      id: id,
      offenseLevel: state.offenseLevel.value,
      offenseDescription: state.offenseDescription.value,
      profileId: participant.id,
      sentenceTerm: state.sentenceTerm.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createAdditionalCharges = async (participant, state, id) => {
  try {

    const response = await axios.post(`${UPDATE_IMPORT_URL}/additional-charges`, {
      caseDetails: state.caseDetails.value,
      caseId: state.caseId.value,
      caseNumber: state.caseNumber.value,
      id: id,
      offenseLevel: state.offenseLevel.value,
      offenseDescription: state.offenseDescription.value,
      profileId: participant.id,
      sentenceTerm: state.sentenceTerm.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateAppointment = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/appointments`, {
      appointmentDate: state.appointmentDate.value,
      appointmentProgramName: state.appointmentProgram.value,
      appointmentStatus: state.appointmentStatus.value,
      id: id,
      appointmentTime: state.appointmentTime.value,
      comment: state.comment.value,
      profileId: participant.id,
      reportsTo: state.reportsTo.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createAppointment = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/appointments`, {
      appointmentDate: state.appointmentDate.value,
      appointmentProgramName: state.appointmentProgram.value,
      appointmentStatus: state.appointmentStatus.value,
      id: id,
      appointmentTime: state.appointmentTime.value,
      comment: state.comment.value,
      profileId: participant.id,
      reportsTo: state.reportsTo.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateAutomotives = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/automotives`, {
      autoColor: state.autoColor.value,
      autoMake: state.autoMake.value,
      comments: state.comments.value,
      expirationDate: state.expirationDate.value,
      id: id,
      idType: state.idType.value,
      licenseStatus: state.licenseStatus.value,
      profileId: participant.id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createAutomotives = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/automotives`, {
      autoColor: state.autoColor.value,
      autoMake: state.autoMake.value,
      comments: state.comments.value,
      expirationDate: state.expirationDate.value,
      id: id,
      idType: state.idType.value,
      licenseStatus: state.licenseStatus.value,
      profileId: participant.id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateCommunitySupervisionAssessment = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/community-supervision-assessments`, {
      accommodationsScore: state.accommodationScore.value,
      alcoholScore: state.alcoholScore.value,
      assessmentDate: state.assessmentDate.value,
      attitudesScore: state.attitudeScore.value,
      caseId: state.caseId.value,
      companionsScore: state.companionScore.value,
      criminalScore: state.criminalScore.value,
      employmentScore: state.employmentScore.value,
      familyScore: state.familyScore.value,
      id: id,
      profileId: participant.id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createCommunitySupervisionAssessment = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/community-supervision-assessments`, {
      accommodationsScore: state.accommodationScore.value,
      alcoholScore: state.alcoholScore.value,
      assessmentDate: state.assessmentDate.value,
      attitudesScore: state.attitudeScore.value,
      caseId: state.caseId.value,
      companionsScore: state.companionScore.value,
      criminalScore: state.criminalScore.value,
      employmentScore: state.employmentScore.value,
      familyScore: state.familyScore.value,
      id: id,
      profileId: participant.id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateConductViolations = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/conduction-violations`, {
      caseId: state.caseId.value,
      comments: state.comments.value,
      id: id,
      officialName: state.officialName.value,
      profileId: participant.id,
      reportDate: state.reportDate.value,
      sanctionType: state.sanctionType.value,
      violationDate: state.violationDate.value,
      violationTime: state.violationTime.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createConductViolations = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/conduction-violations`, {
      caseId: state.caseId.value,
      comments: state.comments.value,
      id: id,
      officialName: state.officialName.value,
      profileId: participant.id,
      reportDate: state.reportDate.value,
      sanctionType: state.sanctionType.value,
      violationDate: state.violationDate.value,
      violationTime: state.violationTime.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateCourtOrders = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/court-orders`, {
      comments: state.comments.value,
      court: state.court.value,
      entryDate: state.entryDate.value,
      id: id,
      lengthConditions: state.lengthConditions.value,
      profileId: participant.id,
      progressReport: state.progressReport.value,
      reportDays: state.reportDays.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createCourtOrders = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/court-orders`, {
      comments: state.comments.value,
      court: state.court.value,
      entryDate: state.entryDate.value,
      id: id,
      lengthConditions: state.lengthConditions.value,
      profileId: participant.id,
      progressReport: state.progressReport.value,
      reportDays: state.reportDays.value
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateDemographics = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/demographics`, {
      age: state.age.value, //
      ccCaseManager: state.ccCaseManager.value, //
      communityServiceStatus: state.communityServicesStatus.value,
      courtServicesStatus: state.courtServiceStatus.value,
      csCaseManager: state.csCaseManager.value,
      currentlyDoc: state.currentlyDoc.option,
      dnaCollected: state.dnaCollected.option,
      docEprd: state.docEprd.value,
      excludeCollections: state.excludeCollections.option,
      gangMember: state.gangMember.option,
      gender: state.gender.value,
      hispanic: state.hispanic.option,
      homeHope: state.homeHope.option,
      lastAppointmentDate: state.lastAppointmentDate.value,
      nextAppointmentDate: state.nextAppointmentDate.value,
      probationStatus: state.probationStatus.value,
      probationType: state.probationType.value,
      profileId: participant.id,
      race: state.raceValue.value,
      registeredSexOffender: state.registeredSexOffender.option,
      udsCategory: state.udsCategory.value,
      usCitizen: state.usCitizen.option,
      veteran: state.veteran.option,
      violentOffender: state.violentOffender.option,
      warrant: state.warrant.option,
      warrantDate: state.warrantDate.value,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createDemographics = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/demographics`, {
      age: state.age.value, //
      ccCaseManager: state.ccCaseManager.value, //
      communityServicesStatus: state.communityServicesStatus.value,
      courtServicesStatus: state.courtServiceStatus.value,
      csCaseManager: state.csCaseManager.value,
      currentlyDoc: state.currentlyDoc.option,
      dnaCollected: state.dnaCollected.option,
      docEprd: state.docEprd.value,
      excludeCollections: state.excludeCollections.option,
      gangMember: state.gangMember.option,
      gender: state.gender.value,
      hispanic: state.hispanic.option,
      homeHope: state.homeHope.option,
      lastAppointmentDate: state.lastAppointmentDate.value,
      nextAppointmentDate: state.nextAppointmentDate.value,
      probationStatus: state.probationStatus.value,
      probationType: state.probationType.value,
      profileId: participant.id,
      race: state.raceValue.value,
      registeredSexOffender: state.registeredSexOffender.option,
      udsCategory: state.udsCategory.value,
      usCitizen: state.usCitizen.option,
      veteran: state.veteran.option,
      violentOffender: state.violentOffender.option,
      warrant: state.warrant.option,
      warrantDate: state.warrantDate.value,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateDrugScreens = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/drug-screens`, {
      amphetamine: state.amphetamine.option,
      barbiturates: state.barbiturates.option,
      bathSalts: state.bathSalts.option,
      benzodiazephines: state.benzodiazephines.option,
      buprenorphine: state.buprenorphine.option,
      cannabinoids: state.cannabinoids.option,
      cannabinoidsRatio: state.cannabinoidesRatio.value,
      caseId: state.caseId.value,
      cocaineMetabolite: state.cocaineMetabolite.option,
      comments: state.comments.value,
      creatinine: state.creatinine.value,
      drugScreenId: state.drugScreenId.value,
      drugScreeningType: state.drugScreeningType.value,
      ethanol: state.ethanol.option,
      lsd: state.lsd.option,
      methadone: state.methadone.option,
      methamphetamine: state.methamphetamine.option,
      officialId: state.officialId.value,
      opiates: state.opiates.option,
      otherName: state.otherName.value,
      otherRatio: state.otherRatio.value,
      otherResult: state.otherResult.option,
      oxycodone: state.oxycodone.option,
      pbtResults: state.pbtResults.value,
      phencyclidine: state.phencyclidine.option,
      profileId: participant.id,
      propoxyphene: state.propoxyphene.option,
      resultDate: state.resultDate.value,
      screeningDate: state.screeningDate.value,
      spice: state.spice.option,
      id: id
    });

    console.log(response);

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createDrugScreens = async (participant, state, id) => {
  try {


    const response = await axios.post(`${UPDATE_IMPORT_URL}/drug-screens`, {
      amphetamine: state.amphetamine.option,
      barbiturates: state.barbiturates.option,
      bathSalts: state.bathSalts.option,
      benzodiazephines: state.benzodiazephines.option,
      buprenorphine: state.buprenorphine.option,
      cannabinoids: state.cannabinoids.option,
      cannabinoidsRatio: state.cannabinoidesRatio.value,
      caseId: state.caseId.value,
      cocaineMetabolite: state.cocaineMetabolite.option,
      comments: state.comments.value,
      creatinine: state.creatinine.value,
      drugScreenId: state.drugScreenId.value,
      drugScreeningType: state.drugScreeningType.value,
      ethanol: state.ethanol.option,
      lsd: state.lsd.option,
      methadone: state.methadone.option,
      methamphetamine: state.methamphetamine.option,
      officialId: state.officialId.value,
      opiates: state.opiates.option,
      otherName: state.otherName.value,
      otherRatio: state.otherRatio.value,
      otherResult: state.otherResult.option,
      oxycodone: state.oxycodone.option,
      pbtResults: state.pbtResults.value,
      phencyclidine: state.phencyclidine.option,
      profileId: participant.id,
      propoxyphene: state.propoxyphene.option,
      resultDate: state.resultDate.value,
      screeningDate: state.screeningDate.value,
      spice: state.spice.option,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateEducation = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/education`, {
      comments: state.comments.value,
      createdDate: state.createdDate.value,
      creditHours: state.creditHours.value,
      dateEnrolled: state.dateEnrolled.value,
      dateReceived: state.dateReceived.value,
      educationLevel: state.educationLevel.value,
      profileId: participant.id,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const createEducation = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/education`, {
      comments: state.comments.value,
      createdDate: state.createdDate.value,
      creditHours: state.creditHours.value,
      dateEnrolled: state.dateEnrolled.value,
      dateReceived: state.dateReceived.value,
      educationLevel: state.educationLevel.value,
      profileId: participant.id,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.response;

  }
};

export const updateCsstAssessment = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/csst-assessments`, {
      caseId: state.caseId.value,
      assessmentDate: state.assessmentDate.value,
      arrestScore: state.arrestScore.value,
      felonyScore: state.felonyScore.value,
      misconductScore: state.misconductScore.value,
      priorScore: state.priorScore.value,
      educationScore: state.educationScore.value,
      employmentScore: state.employmentScore.value,
      financialScore: state.financialScore.value,
      drugUseScore: state.drugUseScore.value,
      fightScore: state.fightScore.value,
      profileId: participant.id,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.error;
  }
};

export const createCsstAssessment = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/csst-assessments`, {
      caseId: state.caseId.value,
      assessmentDate: state.assessmentDate.value,
      arrestScore: state.arrestScore.value,
      felonyScore: state.felonyScore.value,
      misconductScore: state.misconductScore.value,
      priorScore: state.priorScore.value,
      educationScore: state.educationScore.value,
      employmentScore: state.employmentScore.value,
      financialScore: state.financialScore.value,
      drugUseScore: state.drugUseScore.value,
      fightScore: state.fightScore.value,
      profileId: participant.id,
      id: id
    });

    return response;

  } catch (error) {
    console.log(error);
    return error.error;
  }
};

export const updatePatAssessment = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/pat-assessments`, {
      assessmentId: state.assessmentId.value,
      caseId: state.caseId.value,
      assessmentDate: state.assessmentId.value,
      ageScore: state.ageScore.value,
      ftaScore: state.ftaScore.value,
      jailScore: state.jailScore.value,
      employmentScore: state.employmentScore.value,
      residentialScore: state.residentialScore.value,
      drugUseScore: state.drugUseScore.value,
      severeUseScore: state.severeUseScore.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createPatAssessment = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/pat-assessments`, {
      assessmentId: state.assessmentId.value,
      caseId: state.caseId.value,
      assessmentDate: state.assessmentId.value,
      ageScore: state.ageScore.value,
      ftaScore: state.ftaScore.value,
      jailScore: state.jailScore.value,
      employmentScore: state.employmentScore.value,
      residentialScore: state.residentialScore.value,
      drugUseScore: state.drugUseScore.value,
      severeUseScore: state.severeUseScore.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateLevelsOfSupervision = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/levels-of-supervision`, {
      caseId: state.caseId.value,
      componentName: state.componentName.value,
      beginDate: state.beginDate.value,
      endDate: state.endDate.value,
      referralType: state.referralType.value,
      statusName: state.statusName.value,
      officialName: state.officialName.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createLevelsOfSupervision = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/levels-of-supervision`, {
      caseId: state.caseId.value,
      componentName: state.componentName.value,
      beginDate: state.beginDate.value,
      endDate: state.endDate.value,
      referralType: state.referralType.value,
      statusName: state.statusName.value,
      officialName: state.officialName.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateTreatments = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/treatments`, {
      initialCaseManagementDate: state.initialCaseManagementDate.value,
      goalDate: state.goalDate.value,
      completionDate: state.completionDate.value,
      providerName: state.providerName.value,
      providerAddress: state.providerAddress.value,
      providerCity: state.providerCity.value,
      providerState: state.providerState.value,
      providerZip: state.providerZip.value,
      treatmentType: state.treatmentType.value,
      treatmentStatus: state.treatmentStatus.value,
      treatmentEndType: state.treatmentEndType.value,
      casePlanDomain: state.casePlanDomain.value,
      comments: state.comments.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createTreatments = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/treatments`, {
      initialCaseManagementDate: state.initialCaseManagementDate.value,
      goalDate: state.goalDate.value,
      completionDate: state.completionDate.value,
      providerName: state.providerName.value,
      providerAddress: state.providerAddress.value,
      providerCity: state.providerCity.value,
      providerState: state.providerState.value,
      providerZip: state.providerZip.value,
      treatmentType: state.treatmentType.value,
      treatmentStatus: state.treatmentStatus.value,
      treatmentEndType: state.treatmentEndType.value,
      casePlanDomain: state.casePlanDomain.value,
      comments: state.comments.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateEmployment = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/employment`, {
      jobTitle: state.jobTitle.value,
      tempServiceName: state.tempServiceName.value,
      hireDate: state.hireDate.value,
      travelTime: state.travelTime.value,
      hourlyWages: state.hourlyWages.value,
      annualSalary: state.annualSalary.value,
      employmentStatus: state.employmentStatus.value,
      empReason: state.empReason.value,
      employmentLengthYears: state.employmentLengthYears.value,
      employmentLengthMonths: state.employmentLengthMonths.value,
      employmentTerminated: state.employmentTerminated.value,
      terminationDate: state.terminationDate.value,
      employmentLevel: state.employmentLevel.value,
      comments: state.comments.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createEmployment = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/employment`, {
      jobTitle: state.jobTitle.value,
      tempServiceName: state.tempServiceName.value,
      hireDate: state.hireDate.value,
      travelTime: state.travelTime.value,
      hourlyWages: state.hourlyWages.value,
      annualSalary: state.annualSalary.value,
      employmentStatus: state.employmentStatus.value,
      empReason: state.empReason.value,
      employmentLengthYears: state.employmentLengthYears.value,
      employmentLengthMonths: state.employmentLengthMonths.value,
      employmentTerminated: state.employmentTerminated.value,
      terminationDate: state.terminationDate.value,
      employmentLevel: state.employmentLevel.value,
      comments: state.comments.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateProgram = async (participant, state, id) => {
  try {
    const response = await axios.put(`${UPDATE_IMPORT_URL}/programs`, {
      programName: state.programName.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createProgram = async (participant, state, id) => {
  try {
    const response = await axios.post(`${UPDATE_IMPORT_URL}/programs`, {
      programName: state.programName.value,
      profileId: participant.id,
      id: id
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUserStatus = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/api/nij/ai-sms/user/status`, {
      params: {
        userId: participant.id
      }
    })
  } catch (error) {
    console.log(error);
    return error.response;
  }
}

