/* eslint-disable */
import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getParticipantInformation } from '../views/home/api';
import {getTasks} from "../views/game/api";


export const ParticipantContext = createContext();

export const ParticipantProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [taskCompletionRate, setTaskCompletionRate] = useState([]);
  const [id, setId] = useState(localStorage.getItem('participantId') === null ? 0 : JSON.parse(localStorage.getItem('participantId')));
  const [name, setName] = useState(localStorage.getItem('participantName') === null ? '' : JSON.parse(localStorage.getItem('participantName')));
  const [latestLocation, setLatestLocation] = useState(localStorage.getItem('participantLatestLocation') === null ? []: JSON.parse(localStorage.getItem('participantLatestLocation')));
  const [street, setStreet] = useState(localStorage.getItem('participantStreet') === null ? '' : JSON.parse(localStorage.getItem('participantStreet')));
  const [city, setCity] = useState(localStorage.getItem('participantCity') === null ? '' : JSON.parse(localStorage.getItem('participantCity')));
  const [state, setState] = useState(localStorage.getItem('participantState') === null ? '' : JSON.parse(localStorage.getItem('participantState')));
  const [running, setRunning] = useState(false);

  const navigate = useNavigate();

  const apiTimeout = 1000 * 60 * 5;

  useEffect(() => {
    if (list !== undefined) {
      if (list.length !== 0 && name === '') {
        var firstParticipant = list[0];
        setName(firstParticipant.name);
        localStorage.setItem('participantName', JSON.stringify(firstParticipant.name))
        setId(firstParticipant.id);
        localStorage.setItem('participantId', JSON.stringify(firstParticipant.id))
        setLatestLocation(firstParticipant.latestLocation);
        localStorage.setItem('participantLatestLocation', JSON.stringify(firstParticipant.latestLocation))
        if (firstParticipant.address) {
          setStreet(firstParticipant.address.street);
          localStorage.setItem('participantStreet', JSON.stringify(firstParticipant.address.street))
          setCity(firstParticipant.address.city);
          localStorage.setItem('participantCity', JSON.stringify(firstParticipant.address.city))
          setState(firstParticipant.address.state);
          localStorage.setItem('participantState', JSON.stringify(firstParticipant.address.state))
        } else {
          setStreet('');
          setCity('');
          setState('');
          localStorage.setItem('participantStreet', JSON.stringify(''))
          localStorage.setItem('participantCity', JSON.stringify(''))
          localStorage.setItem('participantState', JSON.stringify(''))
        }
      }

      if (list.length !== 0) {
        list.map((participant, i) => {
          let tmpTaskCompletionRate = 0;
          getTasks(participant)
            .then((data) => {
              if (data) {
                let tempAssignedTasks = 0;
                let tempCompletedtasks = 0;
                data.map((task) => {
                  if (task.status === 'COMPLETED') {
                    tempCompletedtasks++;
                  } else if (task.status === 'REWARD_CLAIMED') {
                    tempCompletedtasks++;
                  }
                  tempAssignedTasks++
                });
                if (tempAssignedTasks === 0) {
                  tmpTaskCompletionRate = 0;
                } else {
                  tmpTaskCompletionRate = (tempCompletedtasks / tempAssignedTasks).toFixed(1) * 100;
                }
                if (tmpTaskCompletionRate !== taskCompletionRate[i]) {
                  taskCompletionRate[i] = tmpTaskCompletionRate;
                }
              }
            });
        })
      }
    }
  }, [list]);

  function updateParticipantInformation() {
    if (localStorage.getItem('accessToken') && axios.defaults.headers.common['Authorization'] !== undefined) {
      getParticipantInformation()
        .then(r => {
          if (r) {
            if (r.status === 200) {
              var newParticipantArr = [];
              r.data.map((participant) => {
                newParticipantArr.push(participant);
              });
              setList(newParticipantArr);
            } else {
              navigate('/', { replace: true });
              setRunning(false);
            }
          } else {
            navigate('/', { replace: true });
            setRunning(false);
          }
        });
      setTimeout(updateParticipantInformation, apiTimeout);
    } else {
      navigate('/', { replace: true });
      setRunning(false);
    }
  }

  useEffect(() => {
    if (!running) {
      setRunning(true);
      updateParticipantInformation();
    }
  }, [localStorage.getItem('accessToken')]);

  return (
    <ParticipantContext.Provider
      value={{
        list, setList,
        taskCompletionRate, setTaskCompletionRate,
        id, setId,
        name, setName,
        latestLocation, setLatestLocation,
        street, setStreet,
        city, setCity,
        state, setState
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};
