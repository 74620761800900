/* eslint-disable */

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid, IconButton, Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import { AccessTime } from '@material-ui/icons';
import React from 'react';
import StatusButtonPopper from './StatusButtonPopper';
import DeleteIcon from '@material-ui/icons/Delete';
import AttendedButtonPopper from './AttendedButtonPopper';

const useStyles = makeStyles((theme) => ({
  lens: {
    padding: '10px'
  },
  title: {},
  icon: {
    verticalAlign: 'middle'
  },
  text: {
    display: 'inline-block'
  },
  buttonBlue: {
    color: 'white',
    backgroundColor: 'SteelBlue',
    padding: '2px 7px 2px 7px',
    '&:hover': {
      backgroundColor: 'SkyBlue'
    },
    width: 'fit-content'
  }
}));


const AppointmentDialog = ({ appointment, open, handleCloseDialog, handleApproveAppointment, handleDelete, handleAttendAppointment }) => {

  const classes = useStyles();

  if (appointment === null) {
    return (
      <>
      </>
    );
  } else {
    return (
      <div onWheel={handleCloseDialog}>
        <Dialog open={open} onClose={handleCloseDialog} disablePortal={true}>
          <DialogTitle style={{ backgroundColor: appointment.sender === 'participant' ? 'orange' : '#03a9f4'}}>

            <Grid container direction={'column'}>

              <Grid item>
                <Typography variant={'h3'}>
                  {appointment.title}
                </Typography>
              </Grid>

              <Grid item>
                {format(new Date(appointment.start_time), 'EEEE, LLLL d, uuuu')}
              </Grid>

              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <AccessTime className={classes.icon} />
                </Grid>
                <Grid item>
                  <div className={classes.text}>
                    {format(new Date(appointment.start_time), 'h:mm a') + ' - ' + format(new Date(appointment.end_time), 'h:mm a')}
                  </div>
                </Grid>

              </Grid>

              <Grid item>
                <div className={classes.text}>
                  Scheduled by {appointment.sender}
                </div>
              </Grid>

            </Grid>

          </DialogTitle>

          <DialogContent>
            <Grid container align={'center'}>

              <Grid container item justify={'center'} display="flex" alignItems="center" justifyContent="center" spacing={1}>
                <Grid item>
                  {appointment.type === 'in-person' ? (
                    <>
                      <Typography> In-Person </Typography>
                    </>
                  ) : appointment.type === 'phone' ? (
                    <>
                      <Typography> Phone </Typography>
                    </>
                  ) : appointment.type === 'zoom' ? (
                    <>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography> Zoom </Typography>
                        </Grid>
                        <Grid item>
                          <Link
                            href={appointment.zoomURL}
                            target={'_blank'}
                            underline={'none'}
                          >
                            <Button
                              onClick={(event) => {
                                event.stopPropagation()
                              }}
                              className={classes.buttonBlue}
                            >
                              Join Meeting
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </Grid>

                <Grid item>
                  <StatusButtonPopper
                    key={appointment.approved}
                    appointmentState={appointment}
                    approveAppointment={handleApproveAppointment}
                  />
                </Grid>

                <Grid item>
                  <AttendedButtonPopper
                    key={appointment.attended}
                    appointmentState={appointment}
                    attendAppointment={handleAttendAppointment}
                  />
                </Grid>

                <Grid item>
                  <IconButton>
                    <DeleteIcon
                      fontSize={'small'}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleDelete(event, appointment.id);
                        handleCloseDialog()
                      }}
                    />
                  </IconButton>

                </Grid>

              </Grid>
            </Grid>

          </DialogContent>

        </Dialog>
      </div>
    );
  }
};

export default AppointmentDialog;
