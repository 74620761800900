import React from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBar: {

  },
  alert: {
    width: '100%'
  }
}));

const SnackBarAlert = (props) => {
  const classes = useStyles();

  const open = props.open;
  const handleClose = props.handleClose;
  const severity = props.severity;
  const message = props.message

  return (
    <div className={classes.root}>
      <Snackbar
        open={open} autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={classes.snackBar}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          className={classes.alert}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBarAlert;
