/* eslint-disable */
import {
  useState,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import SortIcon from '@material-ui/icons/Sort';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import NotificationList from './ParticipantList/NotificationList';
import { ParticipantContext } from 'src/context/ParticipantContext';
import { NotificationContext } from 'src/context/NotificationContext';
import ConvertLocationStr from '../../../utils/ConvertLocationStr';
import geolocation_warning from '../../../image/geolocation_warning_32px.png';


const BorderLinearProgressActive = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 3,
    width: '88%',
  },
  colorPrimary: {
    backgroundColor: 'PowderBlue',
  },
  bar: {
    borderRadius: 3,
    backgroundColor: 'LightSeaGreen',
  },
}))(LinearProgress);

const BorderLinearProgressInactive = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 3,
    width: '88%',
  },
  colorPrimary: {
    backgroundColor: 'LightGrey',
  },
  bar: {
    borderRadius: 3,
    backgroundColor: 'DarkGray',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100vw',
    height: '100vh',
    overflow: 'unset',
  },
  container: {
    position: 'relative',
  },
  cardContainer: {
    padding: '6px',
    "&:last-child": {
      paddingBottom: '6px',
    },
  },
  participantContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'absolute',
    width: 'calc(100% - 300px)',
  },
  participantList: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    maxHeight: 430,
    overflow: 'auto',
    paddingTop: '0px',
  },
  leafletTooltip: {
    padding: '0px 4px 0px 4px !important',
  },
  searchContainer: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    padding: '0px 4px 0px 4px',
  },
  searchField: {
    width: '86%',
  },
  sortIcon: {
    width: '14%',
    color: 'DarkGray',
    minWidth: '0px',
    margin: '2px 0px',
    '&:hover': {
      color: 'LightSeaGreen',
      cursor: 'pointer',
    },
  },
  participantRow: {
    margin: '6px 0px 6px 0px',
    '&:hover': {
      backgroundColor: 'GhostWhite',
      cursor: 'pointer',
    },
  },
  participantInfoContainer: {
    padding: '0px 14px 0px 14px',
  },
  participantNameContainer: {
    margin: '0px 0px 0px 0px',
  },
  participantNotification: {
    display: 'inline-flex',
  },
  progressBarContainer: {
    padding: '0px 14px 0px 14px',
  },
  progressBarText: {
    padding: '0px 0px 0px 10px',
    fontSize: '12px',
  }
}));

const ParticipantList = ({ className, participantData, toggleCircleMarkerData, selectParticipant, mapSetCenter, ...rest }) => {
  const classes = useStyles();
  const [toggleCMState, setToggleCMState] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [firstParticipantSelected, setFirstParticipantSelected] = useState(false);
  const participantContext = useContext(ParticipantContext);
  const notificationContext = useContext(NotificationContext);
  const [searchValue, setSearchValue] = useState("");
  const [sorted, setSorted] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationByParticipantList, setNotificationByParticipantList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (searchValue == "") {
      if (participantContext != undefined) {
        if (participantContext.list.length != 0) {
          var tmpList = participantContext.list;
          if (sorted) {
            tmpList = tmpList.sort((participant1, participant2) => (participant1.name > participant2.name ? 1 : -1))
          } else {
            tmpList = tmpList.sort((participant1, participant2) => (participant1.id > participant2.id ? 1 : -1))
          }
          setParticipants(tmpList);
        }
      }
      if (participants.length != 0 && firstParticipantSelected == false && participantContext.id == 0) {
        setFirstParticipantSelected(true);
        var firstParticipant = participants[0];
        handleParticipantSelection(firstParticipant.id);
        mapSetCenter(ConvertLocationStr(firstParticipant.latestLocation));
      }
    }
  }, [participantContext]);

  const handleParticipantSelection = (participantId) => {
    var id;
    participants.map((participant) => {
      if (participant.id == participantId) {
        id = participant.id;
      }
    });

    setToggleCMState(!toggleCMState);
    selectParticipant(participantId);
  }

  const handleChangeSearchInput = (event) => {
    var prefix = event.target.value;
    setSearchValue(prefix);
    var tmpList = [];
    participantContext.list.map((participant, id) => {
      var name1 = participant.name.toLowerCase();
      var name2 = prefix.toLowerCase();
      if (name1.startsWith(name2)) {
        tmpList.push(participant);
      }
    });

    if (sorted) {
      tmpList = tmpList.sort((participant1, participant2) => (participant1.name > participant2.name ? 1 : -1))
    } else {
      tmpList = tmpList.sort((participant1, participant2) => (participant1.id > participant2.id ? 1 : -1))
    }

    setParticipants(tmpList);
  };

  const SortParticipantList = (type) => {
    HandleSortButtonClose();

    var tmpList;
    if (type == "name") {
      tmpList = participantContext.list.sort((participant1, participant2) => (participant1.name > participant2.name ? 1 : -1))
    } else if (type == "id") {
      tmpList = participantContext.list.sort((participant1, participant2) => (participant1.id > participant2.id ? 1 : -1))
    } else if (type == "notification") {
      if (notificationByParticipantList != []) {
        tmpList = participantContext.list.sort(CompareNotificationLength);
      } else {
        tmpList = participants;
      }
    }
    setSorted(!sorted);

    setParticipants(tmpList);
  };

  const GetNotificationLength = (participantId) => {
    if (notificationByParticipantList[participantId] == undefined) {
      return 0;
    } else {
      return notificationByParticipantList[participantId].length;
    }
  }

  const CompareNotificationLength = (participant1, participant2) => {
    let length1 = GetNotificationLength(participant1.id);
    let length2 = GetNotificationLength(participant2.id);
    if (length1 > length2) {
      return 1;
    } else {
      return -1;
    }
  }

  const HandleSortButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const HandleSortButtonClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      {...rest}
    >
      <CardContent className={classes.cardContainer}>
        <div className={classes.searchContainer}>
          <TextField
            id="search-input"
            placeholder="Search"
            variant="outlined"
            className={classes.searchField}
            onChange={event => handleChangeSearchInput(event)}
            size="small"
          />
          <Button
            aria-controls="sort-menu"
            aria-haspopup="true"
            onClick={HandleSortButtonClick}
            className={classes.sortIcon}
          >
            <SortIcon />
          </Button>
          <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={HandleSortButtonClose}
          >
            <MenuItem onClick={() => SortParticipantList("id")}>Sort by ID</MenuItem>
            <MenuItem onClick={() => SortParticipantList("name")}>Sort by Name</MenuItem>
            <MenuItem onClick={() => SortParticipantList("notification")}>Sort by Notification</MenuItem>
          </Menu>
        </div>

        <List dense className={classes.participantList}>
          {participants.length ? participants.map((participant, i) => {
            const labelId = `checkbox-list-secondary-label-${participant.id}`;
            const keyLabelListItemText = `listitem-text-${participant.id}`;
            const keyLabelListItemSecondary = `listitem-secondary-${participant.id}`;
            // let address = `${participant.address.street}, ${participant.address.city}, ${participant.address.state}`;
            let address = `Task Completion Rate`;
            return (
              <div
                className={classes.participantRow}
                button
                onClick={() => handleParticipantSelection(participant.id)}
              >
                <ListItem
                  key={participant.id}
                  className={classes.participantInfoContainer}
                >
                  {participant.id == participantContext.id ?
                    <ListItemText id={labelId} key={keyLabelListItemText} disableTypography
                      className={classes.participantNameContainer}
                      primary={<Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }}>{`${participant.name}`}</Typography>}
                      secondary={<Typography style={{ color: 'LightSeaGreen', 'font-weight': 'bold' }} variant="caption">{`${address}`}</Typography>}
                    />
                    :
                    <ListItemText id={labelId} key={keyLabelListItemText}
                      className={classes.participantNameContainer}
                      primary={<Typography>{`${participant.name}`}</Typography>}
                      secondary={<Typography variant="caption">{`${address}`}</Typography>}
                    />
                  }
                  <div>
                    <ListItemSecondaryAction key={keyLabelListItemSecondary}>
                      {participant.id == participantContext.id ?
                        <NotificationList
                          participantId={participant.id}
                          selected='true'
                        />
                        :
                        <NotificationList
                          participantId={participant.id}
                          selected='false'
                        />
                      }
                    </ListItemSecondaryAction>
                  </div>
                </ListItem>
                {participant.id == participantContext.id ?
                  <ListItem className={classes.progressBarContainer} key={`${participant.id}-progress`}>
                    <BorderLinearProgressActive variant="determinate" value={participantContext.taskCompletionRate[i]} />
                    <Typography variant="h6" style={{ color: 'LightSeaGreen' }} className={classes.progressBarText}>
                      {participantContext.taskCompletionRate[i]}%
                    </Typography>
                  </ListItem>
                  :
                  <ListItem className={classes.progressBarContainer} key={`${participant.id}-progress`}>
                    <BorderLinearProgressInactive variant="determinate" value={participantContext.taskCompletionRate[i]} />
                    <Typography variant="h6" style={{ color: 'DarkGray' }} className={classes.progressBarText}>
                      {participantContext.taskCompletionRate[i]}%
                    </Typography>
                  </ListItem>
                }
              </div>
            );
          }) : ''}
        </List>
      </CardContent>
    </Card >
  );
}

ParticipantList.propTypes = {
  className: PropTypes.string
};

export default ParticipantList;
