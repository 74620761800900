/* eslint-disable */
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
const lcpProxy = 'http://localhost:8010/proxy';

export const getUserGameStatus = async (userId, include404) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/nij/ai-sms/user/status`, {
      validateStatus: function(status) {
        if (include404) {
          return (status >= 200 && status < 300) || status === 404; // default + 404
        } else {
          return status >= 200 && status < 300; // default
        }
      },

      params: {
        userId: userId
      }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAveragePoints = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/nij/ai-sms/user/point/average`, {});
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTasks = async (participant) => {
  try {

    const { data } = await axios.get(`${BASE_URL}/api/nij/ai-sms/task/task-list`, {
      params: {
        userId: participant.id
      }
    });


    return data;

  } catch (error) {
    console.log(error);
  }
};

export const getRewards = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/gamification/rewards`, {
      params: {
        participantId: participant.id
      }
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addReward = async (participant, state) => {
  try {
    return await axios.post(`${BASE_URL}/gamification/reward`, {
      description: state.description,
      participantId: participant.id,
      status: state.status,
      targetPoints: state.targetPoints
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editReward = async (participant, state) => {
  try {
    return await axios.put(`${BASE_URL}/gamification/reward/${state.id}`, {
      description: state.description,
      participantId: participant.id,
      status: state.status,
      targetPoints: state.targetPoints,
      id: state.id
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteReward = async (participant, state) => {
  try {
    return await axios.delete(`${BASE_URL}/gamification/reward/${state.id}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUserScore = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/gamification/user-score`, {
      params: {
        participantId: participant.id
      }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserScoreAll = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/gamification/user-score/all`, {});
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserLevel = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/gamification/user-level`, {
      params: {
        participantId: participant.id
      }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRewardProgress = async (participant) => {
  try {
    return await axios.get(`${BASE_URL}/gamification/rewards/progress`, {
      params: {
        participantId: participant.id
      }
    })
  } catch (error) {
    console.log(error)
  }
}
