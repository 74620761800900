/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  List, ListItem, Divider, ListItemSecondaryAction, IconButton
} from '@material-ui/core';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { createEmployment, getCategoryData, updateEmployment } from '../api';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldListItem from '../components/TextFieldListItem';
import SnackBarAlert from '../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  column: {
    float: 'left',
    width: '100%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnLeft: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    width: '60%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  row: {
    display: 'flex'
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  },
  listKey: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  }
}));

const EmploymentQuery = ({ className, ...rest }) => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  let leftColumnSkeleton = {
    jobTitle: {
      label: 'Job Title',
      value: 'NO DATA'
    },
    tempServiceName: {
      label: 'Temp Service Name',
      value: 'NO DATA'
    },
    hireDate: {
      label: 'Hire Date',
      value: 'NO DATA'
    },
    travelTime: {
      label: 'Travel Time',
      value: 'NO DATA'
    },
    hourlyWages: {
      label: 'Hourly Wages',
      value: 'NO DATA'
    },
    annualSalary: {
      label: 'Annual Salary',
      value: 'NO DATA'
    },
    employmentStatus: {
      label: 'Employment Status',
      value: 'NO DATA'
    },
    empReason: {
      label: 'Emp Reason',
      value: 'NO DATA'
    }
  };

  let rightColumnSkeleton = {
    employmentLengthYears: {
      label: 'Employment Length Years',
      value: 'NO DATA'
    },
    employmentLengthMonths: {
      label: 'Employment Length Months',
      value: 'NO DATA'
    },
    employmentTerminated: {
      label: 'Employment Terminated',
      value: 'NO DATA'
    },
    terminationDate: {
      label: 'Termination Date',
      value: 'NO DATA'
    },
    employmentLevel: {
      label: 'Employment Level',
      value: 'NO DATA'
    },
    comments: {
      label: 'Comments',
      value: 'NO DATA'
    }
  };

  const [rightColumn, setRightColumn] = useState(rightColumnSkeleton);
  const [leftColumn, setLeftColumn] = useState(leftColumnSkeleton);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(``);
  const [severity, setSeverity] = useState(``);
  const [editable, setEditable] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [id, setID] = useState(null);

  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleRightColumnChange = (event) => {
    setRightColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: rightColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleLeftColumnChange = (event) => {
    setLeftColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: leftColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  useEffect(() => {
    getCategoryData(participant, 'EMPLOYMENT_QUERY')
      .then((data) => {
        if (data && data['employmentQuery']) {
          for (const [key, value] of Object.entries(data['employmentQuery'])) {
            if (key === 'id') {
              setID(value);
            }

            if (key in rightColumn) {
              setRightColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: rightColumn[key].label,
                  value: value
                }
              }));
            } else if (key in leftColumn) {
              setLeftColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: leftColumn[key].label,
                  value: value
                }
              }));
            }
          }
        } else {
          setRightColumn(rightColumnSkeleton);
          setLeftColumn(leftColumnSkeleton);
        }
      });
  }, [participant.id, canceled]);


  return (
    <>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          className={classes.container}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
          >
            <Typography
              color='textSecondary'
              variant='body1'
            >
              <List>
                <ListItem className={classes.sectionTitle}>
                  <b>Employment Query</b>
                  <ListItemSecondaryAction>
                    {editable ? (
                      <>
                        <IconButton aria-label='save' onClick={() => {
                          if (id) {
                            updateEmployment(participant, Object.assign({}, leftColumn, rightColumn), id)
                              .then(r => {
                                if (r) {
                                  if (r.status !== 200) {
                                    handleAlert(`Failed to save changes, try again later.`, 'error');
                                    setCanceled(!canceled);

                                  } else {
                                    handleAlert(`Changes saved!`, 'success');
                                  }
                                } else {
                                  handleAlert(`Failed to save changes, try again later.`, 'error');
                                  setCanceled(!canceled);
                                }
                              });
                          } else {
                            createEmployment(participant, Object.assign({}, leftColumn, rightColumn), id)
                              .then(r => {
                                if (r) {
                                  if (r.status !== 200) {
                                    handleAlert(`Failed to create new data, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  } else {
                                    handleAlert(`New data saved!`, 'success');
                                    setID(1);
                                  }
                                } else {
                                  handleAlert(`Failed to create new data, try again later.`, 'error');
                                  setCanceled(!canceled);
                                }
                              });
                          }
                          setEditable(false);
                        }}>
                          <SaveIcon />
                        </IconButton>
                        <IconButton aria-label='cancel' onClick={() => {
                          setCanceled(!canceled);
                          setEditable(false);
                        }}>
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton aria-label='edit' onClick={() => {
                        setEditable(true);
                      }}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <div className={classes.column}>
                      {Object.keys(leftColumn).map((key) => (
                        <TextFieldListItem
                          editable={editable}
                          name={key}
                          label={leftColumn[key].label}
                          value={leftColumn[key].value}
                          classes={classes}
                          changeHandler={handleLeftColumnChange}
                        />
                      ))}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <div className={classes.column}>
                      {Object.keys(rightColumn).map((key) => (
                        <TextFieldListItem
                          editable={editable}
                          name={key}
                          label={rightColumn[key].label}
                          value={rightColumn[key].value}
                          classes={classes}
                          changeHandler={handleRightColumnChange}
                        />
                      ))}
                    </div>
                  </Grid>
                </Grid>


              </List>
            </Typography>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
      <SnackBarAlert
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </>
  );
};

EmploymentQuery.propTypes = {
  className: PropTypes.string
};

export default EmploymentQuery;
