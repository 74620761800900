/* eslint-disable */
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const getParticipantInformation = async () => {
  try {
    return await axios.get(`${BASE_URL}/api/nij/ai-sms/user-info/all`, {});
  } catch (error) {
    console.log(error);
  }
};

export const getNotifications = async () => {
  try {
    return await axios.get(`${BASE_URL}/notifications`, {}, {});
  } catch (error) {
    console.log(error);
  }
}

export const GetViolationCheck = async (gpslogId) => {
  try {
    return await axios.get(`${BASE_URL}/api/nij/ai-sms/location/violation-check`, {
      gpslogId: gpslogId,
    }, {});
  } catch (error) {
    console.log(error);
  }
}

export const GetGeneralRestrictedZoneLocation = async (userId) => {
  try {
    return await axios.get(`${BASE_URL}/api/nij/ai-sms/location/general-restricted-user`, {
      params: {
        id: userId,
      }
    }, {});
  } catch (error) {
    console.log(error);
  }
}
