/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement } from 'chart.js';
const DoughnutLabel = require("chartjs-plugin-doughnutlabel-rebourne");

ChartJS.register(ArcElement, DoughnutLabel);

function DonutChart ({ completedData, assignedData, width, height, margin, color }) {
  let points  = completedData;
  let target  = assignedData;

  return (
    <>
      <div className="donutChartContainer" style={{
        position: "relative",
        height: height
      }}>
        <Doughnut data={{
          labels: [],
          datasets: [{
            data: [points, target - points],
            backgroundColor: [
              color,
              'lavender'
            ]
          }]
        }} options={{
          cutout: '80%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // @ts-ignore
            doughnutlabel: {
              labels: [
               /* {
                  text: 'To Goal',
                  font: {
                    size: '16'
                  }
                },*/
                {
                  text: ((points / target).toFixed(1) * 100) + "%",
                  font: {
                    size: '36'
                  }
                },
                {
                  text: `${points} / ${target}`,
                  font: {
                    size: '16'
                  }
                }
              ]
            },
            tooltip:
              {
                enabled: false,
              },
            datalabels: {
              display: false,
            }
          }
        }} />

      </div>
    </>
  );
}

export default DonutChart;
