/* eslint-disable */
import { ListItem, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  listKey: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  },
  columnLeft: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  row: {
    display: 'flex'
  },
}))

const TextFieldListItem = (props) => {
  const classes = useStyles();
  const key = props.name;
  const editable = props.editable;
  const handleChange = props.changeHandler;
  const label = props.label;
  const value = props.value;

  return (
    <div className={classes.row}>
      <div className={classes.columnLeft}>
        <ListItem className={classes.listKey} key={key}><b>{label}</b></ListItem>
      </div>
      <div className={classes.columnRight}>
        {editable ? (
          <TextField
            fullWidth
            key={key}
            multiline
            id={key}
            name={key}
            value={value}
            variant={'standard'}
            onChange={handleChange}
          />
        ) : (
          <>
            <ListItem>{value}</ListItem>
          </>
        )}
      </div>
    </div>
  );
};

export default TextFieldListItem
