/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../../context/ParticipantContext';
import {
  getGeneralRestrictions,
  getGeoRestrictionProfile,
  getPersonalRestrictions
} from '../../api';
import ExclusionZoneMap from '../Map/ExclusionZoneMap';
import { Card, CardContent, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConvertLocationStr from '../../../../utils/ConvertLocationStr';
import GeoRestrictionProfile from './GeoRestrictionProfile';
import PersonalExclusionTable from './PersonalExclusionTable';
import Page from '../../../../components/Page';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    paddingLeft: '0px',
    paddingRight: '0px'
  }
}));

const ExclusionZoneView = () => {
  const classes = useStyles();

  const participant = useContext(ParticipantContext);
  const [geoRestrictionProfile, setGeoRestrictionProfile] = useState({
    barRestricted: false,
    barTriggerDistance: 0,
    id: 0,
    playgroundRestricted: false,
    playgroundTriggerDistance: 0,
    schoolRestricted: false,
    schoolTriggerDistance: 0
  });
  const [generalExclusionData, setGeneralExclusionData] = useState([]);
  const [personalExclusionData, setPersonalExclusionData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);

  const [map, setMap] = useState(null);

  function MapFlyTo(location) {
    if (location.length && map) {
      map.flyTo(location, 14, {
        duration: 1
      });
    }
    return null;
  }

  const [updated, setUpdated] = useState(false)

  const update = () => {
    setUpdated(!updated)
  }

  useEffect(() => {
    if (participant.latestLocation != null) {
      if (participant.latestLocation.length !== 0) {
        setCurrentLocation(ConvertLocationStr(participant.latestLocation));
        MapFlyTo(ConvertLocationStr(participant.latestLocation));
      }
    }
  }, [map]);

  useEffect(() => {

    getGeoRestrictionProfile(participant)
      .then(async (r) => {
        if (r.status === 200) {
          r.data.schoolTriggerDistance = Math.ceil(r.data.schoolTriggerDistance * 3.28)
          r.data.playgroundTriggerDistance = Math.ceil(r.data.playgroundTriggerDistance * 3.28)
          r.data.barTriggerDistance = Math.ceil(r.data.barTriggerDistance * 3.28)
          setGeoRestrictionProfile(r.data);
          //setGeneralExclusionData(await ParseRestrictedZoneCSV(r.data.barRestricted, r.data.playgroundRestricted, r.data.schoolRestricted));
        }
      });

    getPersonalRestrictions(participant)
      .then((r) => {
        if (r.status === 200) {
          r.data.map((restriction) => {
            restriction.triggerDistance = Math.ceil(restriction.triggerDistance * 3.28)
          })
          setPersonalExclusionData(r.data);
        }
      });

    getGeneralRestrictions(participant)
      .then((r) => {
        if (r.status === 200) {
          let tempData = []
          r.data.map((zone) => {
            if (zone.geom.type === 'Point') {
              tempData.push(zone)
            } else if (zone.geom.type === 'Polygon') {
              zone.geom.coordinates[0].map((coords) => {
                coords.reverse()
              })
              tempData.push(zone)
            }
          })
          setGeneralExclusionData(tempData)
        }
      })

  }, [participant.id, updated]);

  return (
    <Page
      title={'Exclusion Zone'}
    >
      <Card>
          <Container maxWidth='true' className={classes.container}>
            <CardContent style={{ padding: '8px'}}>
              <ExclusionZoneMap generalExclusionData={generalExclusionData} personalExclusionData={personalExclusionData}
                                geoRestrictionProfile={geoRestrictionProfile} map={map} setMap={setMap}
                                currentLocation={currentLocation} />
            </CardContent>

            <CardContent style={{ padding: '8px'}}>
              <GeoRestrictionProfile geoRestrictionProfile={geoRestrictionProfile} update={update}/>

            </CardContent>

            <CardContent style={{ padding: '8px'}}>
              <PersonalExclusionTable personalExclusionData={personalExclusionData} MapFlyTo={MapFlyTo} update={update}/>

            </CardContent>


          </Container>
      </Card>
    </Page>
  );
};

export default ExclusionZoneView;
