/* eslint-disable */

import {
  Button,
  Card, CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider, Grid, makeStyles, TextField,
  Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { setGeneralExclusions } from '../../api';
import { ParticipantContext } from '../../../../context/ParticipantContext';

const selections = [
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingTop: '0px'
  }
}));

const GeoRestrictionProfileDialog = ({ geoRestrictionProfile, open, handleCloseDialog, handleAlert }) => {
  const classes = useStyles();

  const [state, setState] = useState(geoRestrictionProfile);

  const participant = useContext(ParticipantContext);

  useEffect(() => {
    setState(geoRestrictionProfile);
  }, [geoRestrictionProfile]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleRestrictedChange = (event) => {
    if (event.target.value === 'false') {
      setState({
        ...state,
        [event.target.name]: event.target.value === 'true',
        [event.target.getAttribute('data-linked')]: 0
      });
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.value === 'true'
      });
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (state.schoolRestricted && state.schoolTriggerDistance <= 0) {
      return
    } else if (state.barRestricted && state.barTriggerDistance <= 0) {
      return
    } else if (state.playgroundRestricted && state.playgroundTriggerDistance <= 0) {
      return
    }

    setGeneralExclusions(participant, state)
      .then((r) => {
        if (r) {
          if (r.status !== 200) {
            handleAlert(`Error: ${r.status} Try again later`, 'error');
            handleCloseDialog();
          } else {
            handleAlert(`Georestriction profile updated!`, 'success');
            handleCloseDialog();
          }
        } else {
          handleAlert(`No response from server, try again later`, 'error');
          handleCloseDialog();
        }
      });
  };

  return (
    <div >
      <form onSubmit={handleSave}>
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>
            <Typography>GEORESTRICTION PROFILE</Typography>
          </DialogTitle>

          <Divider/>

          <DialogContent>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardContent>
                <CardHeader
                  className={classes.cardHeader}
                  title={'Schools'}
                />

                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restricted'}
                        id={'schoolRestricted'}
                        name={'schoolRestricted'}
                        linked={''}
                        onChange={handleRestrictedChange}
                        select={true}
                        inputProps={{
                          style: { fontSize: '.85rem' },
                          placeholder: 'schoolRestricted', 'data-linked': 'schoolTriggerDistance'
                        }} SelectProps={{ native: true }}
                        value={state.schoolRestricted}
                        variant={'outlined'}
                      >
                        {selections.map((option) => (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restriction Radius'}
                        id={'schoolTriggerDistance'}
                        name={'schoolTriggerDistance'}
                        onChange={handleChange}
                        type={'number'}
                        error={state.schoolRestricted && state.schoolTriggerDistance <= 0}
                        disabled={!state.schoolRestricted}
                        inputProps={{ style: { fontSize: '.85rem' } }}
                        InputProps={{ endAdornment: <InputAdornment position={'end'}> ft </InputAdornment> }}
                        value={state.schoolTriggerDistance}
                        variant={'outlined'}
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </CardContent>

              <CardContent>
                <CardHeader
                  className={classes.cardHeader}
                  title={'Bars'}
                />

                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restricted'}
                        id={'barRestricted'}
                        name={'barRestricted'}
                        onChange={handleRestrictedChange}
                        select={true}
                        inputProps={{
                          style: { fontSize: '.85rem' },
                          placeholder: 'barRestricted', 'data-linked': 'barTriggerDistance'
                        }}
                        SelectProps={{ native: true }}
                        value={state.barRestricted}
                        variant={'outlined'}
                      >
                        {selections.map((option) => (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restriction Radius'}
                        id={'barTriggerDistance'}
                        name={'barTriggerDistance'}
                        onChange={handleChange}
                        error={state.barRestricted && state.barTriggerDistance <= 0}
                        type={'number'}
                        disabled={!state.barRestricted}
                        inputProps={{ style: { fontSize: '.85rem' } }}
                        InputProps={{ endAdornment: <InputAdornment position={'end'}> ft </InputAdornment> }}
                        value={state.barTriggerDistance}
                        variant={'outlined'}
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </CardContent>

              <CardContent>
                <CardHeader
                  className={classes.cardHeader}
                  title={'Playgrounds'}
                />

                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restricted'}
                        id={'playgroundRestricted'}
                        name={'playgroundRestricted'}
                        onChange={handleRestrictedChange}
                        select={true}
                        inputProps={{
                          style: { fontSize: '.85rem' },
                          placeholder: 'playgroundRestricted', 'data-linked': 'playgroundTriggerDistance'
                        }} SelectProps={{ native: true }}
                        value={state.playgroundRestricted}
                        variant={'outlined'}
                      >
                        {selections.map((option) => (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl>
                      <TextField
                        label={'Restriction Radius'}
                        id={'playgroundTriggerDistance'}
                        name={'playgroundTriggerDistance'}
                        onChange={handleChange}
                        error={state.playgroundRestricted && state.playgroundTriggerDistance <= 0}
                        type={'number'}
                        disabled={!state.playgroundRestricted}
                        inputProps={{ style: { fontSize: '.85rem' } }}
                        InputProps={{ endAdornment: <InputAdornment position={'end'}> ft </InputAdornment> }}
                        value={state.playgroundTriggerDistance}
                        variant={'outlined'}
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>

          </DialogContent>

          <Divider/>


          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button type={'submit'} onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>

  );
};

export default GeoRestrictionProfileDialog;
