/* eslint-disable */
import { useEffect } from 'react';
import * as L from 'leaflet';
import './styles.css'
import theme from 'src/theme';

const Legend = ({ map }) => {

  useEffect(() => {
    if (map) {
      const legend = L.control({position: 'topright'})

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        const labelText = ['General Restricted Zone', 'Specific Restricted Zone']
        const colors = [theme.palette.generalZone,  theme.palette.zone]
        const labels = []

        labels.push(
          '<div>'+
          '<em style="background:' +
          theme.palette.dot.blue +
          '"></em> ' +
          'Participant' +
          '</div>'
        )

        labels.push(
          '<div>'+
          '<em style="background:' +
          theme.palette.dot.red +
          '"></em> ' +
          'Selected Participant' +
          '</div>'
        )

        for (let i = 0; i < labelText.length; i++) {

          labels.push(
            '<div>'+
            '<p style="background:' +
            colors[i] +
            '"></p> ' +
            labelText[i] +
            '</div>'
          );
        }
        div.innerHTML = labels.join("<br>");
        return div;
      }

      legend.addTo(map)
    }
  }, [map])

  return null


}

export default Legend;
