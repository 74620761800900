/* eslint-disable */
import React, { useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  colors,
  makeStyles
} from '@material-ui/core';
import {
  CircleMarker,
  MapContainer, Popup,
  TileLayer,
  ZoomControl, LayersControl,
} from 'react-leaflet';
import VisitedMarker from './MapMaker/VisitedMarker';
import MeetingMarker from './MapMaker/MeetingMarker';
import ViolationMarker from './MapMaker/ViolationMarker';
import RestrictedGeneral from './MapMaker/RestrictedGeneral';
import RestrictedSpecific from './MapMaker/RestrictedSpecific';
import Note from "./MapMaker/Note";
import Container from "@material-ui/core/Container";
import ConvertLocationStr from "../../../utils/ConvertLocationStr";
import {ParticipantContext} from "../../../context/ParticipantContext";

const useStyles = makeStyles((width) => ({
  root: {
    height: '400px',
    width: width + 'px',
  },
  avatar: {
    backgroundColor: colors.grey,
    height: 70,
    width: 70,
    margin: '20% 35%',
  },
  avatarContainer: {
    padding: '20px 20px 20px 20px',
  },
  participantName: {
    padding: '27px 20px 0px 20px',
  },
  flexColScroll: {
    'flex-grow': 1,
    overflow: 'scroll',
    'min-height': '100%',
    height: '100%',
    'overflow-x': 'hidden',
  },
  flexSection: {
    'flex-grow': 1,
    display: 'flex',
    'flex-direction': 'column',
    'min-height': 0,
    height: 200,
    padding: '20px 3px 5px 5px',
  },
  map: {
    width: '100%',
    height: '100%',
    overflow: 'unset',
    zIndex: 0,
  },
  container: {
    position: 'relative',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: '100%',
    height: '100%',
    zIndex: 0
  },
}));

const Map = ({ className, violationsData, width, ...rest }) => {
  const classes = useStyles(width);
  const [dataUsedMeeting, setDataUsedMeeting] = useState([]);
  const [dataUsedVisited, setDataUsedVisited] = useState([]);
  const [dataUsedViolation, setDataUsedViolation] = useState(violationsData);

  const defaultPosition = [40.424238309369926, -86.92175786563782];
  const dotRadiusValue = 35;
  const [restrictedZoneGroup, setRestrictedZoneGroup] = useState([]);
  const [restrictedZoneGroupData, setRestrictedZoneGroupData] = useState([]);
  const [individualRestrictedLocationGroup, setIndividualRestrictedLocationGroup] = useState([]);
  const [violationObjects, setViolationObjects] = useState([]);
  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const participant = useContext(ParticipantContext);

  function MapFlyTo(location) {
    if (location.length && map) {
      map.flyTo(location, 14, {
        duration: 1
      });
    }
    return null;
  }

  useEffect(() => {
    if (participant.latestLocation != null) {
      if (participant.latestLocation.length !== 0) {
        setCurrentLocation(ConvertLocationStr(participant.latestLocation));
        MapFlyTo(ConvertLocationStr(participant.latestLocation));
      }
    }
  }, [map]);

  // Refresh each time one of these variables changes
  useEffect(() => {}, [individualRestrictedLocationGroup, dataUsedViolation, violationObjects]);

  useEffect(() => {
    setDataUsedViolation(violationsData)
  }, [violationsData]);

  useEffect(() => {
    setRestrictedZoneGroup(restrictedZoneGroupData);
  }, [restrictedZoneGroupData]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth='true' className={classes.container}>
        <MapContainer
          spacing={3}
          center={defaultPosition}
          zoom={14}
          zoomControl={false}
          className={classes.map}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <ZoomControl position="topleft" />


          <MeetingMarker meetingPlaces={dataUsedMeeting} radiusValue={dotRadiusValue}/>
          <VisitedMarker visitedPlaces={dataUsedVisited} radiusValue={dotRadiusValue} />


          <LayersControl position={'topright'}>
            <ViolationMarker violationPlaces={ dataUsedViolation } radiusValue={dotRadiusValue} setViolationObjects = {setViolationObjects}/>
            {violationObjects}

            {/* Individual Restricted Zone */}
            <RestrictedSpecific
              setZoneGroup = {setIndividualRestrictedLocationGroup}
            />
            {individualRestrictedLocationGroup}

            {/* General Restricted Zone */}
            <RestrictedGeneral setZoneGroup={setRestrictedZoneGroupData} />
            {restrictedZoneGroup}
          </LayersControl>

          <Note map={map}/>

          {currentLocation != null ? (
            <CircleMarker
              pathOptions={{ color: 'purple' }}
              center={currentLocation}
            >
              <Popup>
                Participant Latest Location:<br />
                {participant.latestLocation.substring(18, 26)}, {participant.latestLocation.substring(6, 15)}
              </Popup>
            </CircleMarker>
          ) : (
            <>
            </>
          )}

        </MapContainer>
      </Container>
    </Card>
  );
};

Map.propTypes = {
  className: PropTypes.string
};

export default Map;
