/* eslint-disable */
import { ListItem, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  listKey: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  },
  columnLeft: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  row: {
    display: 'flex'
  },
}))

const SelectFieldListItem = (props) => {
  const classes = useStyles();
  let key = props.name;
  let editable = props.editable;
  let handleChange = props.changeHandler;
  let label = props.label;
  let value = props.value;
  let selection = props.selection;
  let options = props.options;
  let displayed = value;

  if (value !== 'NO DATA') {
    displayed = options[selection].label
  }


  return (
    <div className={classes.row}>
      <div className={classes.columnLeft}>
        <ListItem className={classes.listKey} key={key}><b>{label}</b></ListItem>
      </div>
      <div className={classes.columnRight}>
        {editable ? (
          <TextField
            fullWidth
            multiline
            key={key}
            id={key}
            name={key}
            select={true}
            value={selection}
            inputProps={{ style: { fontSize: '.85rem' } }}
            SelectProps={{ native: true }}
            variant={'standard'}
            onChange={handleChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        ) : (
          <>
            <ListItem>{displayed}</ListItem>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectFieldListItem
