/* eslint-disable */
import { useD3 } from '../useD3';
import * as d3 from 'd3';

function ScrollableRangeChart({ data, heightIn, widthIn, setBrushDataRange }) {
  const parseDate = d3.timeParse("%Y-%m-%d");

  const margin = {top: 20, right: 20, bottom: 0, left: 40} //110
  const margin2 = {top: 230, right: 20, bottom: 30, left: 40}
  const width = +widthIn - margin.left - margin.right
  const height = +200 - margin.top - margin.bottom
  const height2 = +300 - margin2.top - margin2.bottom

  const x = d3.scaleTime().range([0, width]).domain(d3.extent(data, function(d) { return parseDate(d.date); }))
  const x2 = d3.scaleTime().range([0, width]).domain(x.domain())
  const y = d3.scaleLinear().range([height, 0]).domain([0, Math.max(d3.max(data, function(d) { return d.value; }), 3)])
  const y2 = d3.scaleLinear().range([height2, 0]).domain(y.domain())

  const xAxis = d3.axisBottom(x)
  const xAxis2 = d3.axisBottom(x2)

  const yAxisTicks = y.ticks()
    .filter(tick => Number.isInteger(tick));

  const yAxis = d3.axisLeft(y)
    .tickValues(yAxisTicks)
    .tickFormat(d3.format('d'))

  const ref = useD3(
    (svg) => {

      d3.selectAll(".axis--x--vio")
        .remove()
      d3.selectAll(".axis--y--vio")
        .remove()

      let zoombrush;
      let range;

      function brushed(event) {
        if (zoombrush) return; // ignore brush-by-zoom
        zoombrush = 1;
        let s = event.selection || x2.range();
        range = s.map(x2.invert, x2)
        setBrushDataRange(range)
        const timeDiff = range[0].getTimezoneOffset() * 60000;
        const adjustedDate = new Date(range[0].valueOf() - timeDiff);
        const beginDate = parseDate(adjustedDate.toISOString().substring(0, 10));
        x.domain(range);
        y.domain([0, Math.max(d3.max(data, d => parseDate(d.date) >= beginDate && parseDate(d.date) <= range[1] ? d.value : 0), 3)]);
        //focus.select(".area").attr("d", area);

        const yAxisTicks = y.ticks()
          .filter(tick => Number.isInteger(tick));

        const yAxis = d3.axisLeft(y)
          .tickValues(yAxisTicks)
          .tickFormat(d3.format('d'))

        focus.selectAll(".barvio")
          .attr("x", function(d) { return x(parseDate(d.date))})
          .attr("width", width / ((range[1].getTime() - range[0].getTime()) / (1000*60*60*24)));
        focus.select(".axis--x--vio").call(xAxis);
        focus.select(".axis--y--vio").call(yAxis);
        svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
          .scale(width / (s[1] - s[0]))
          .translate(-s[0], 0));
        svg.select(".selection").attr('display', "none"); // hide extra gray
        zoombrush = 0;
      }

      function zoomed(event) {
        if (zoombrush) return; // ignore zoom-by-brush
        zoombrush = 1;
        var t = event.transform;
        x.domain(t.rescaleX(x2).domain());
        //focus.select(".area").attr("d", area);
        focus.selectAll(".barvio")
          .attr("x", function(d) { return x(parseDate(d.date))})
          .attr("y", function(d) { return y(d.value); })
          .attr("width", width / ((range[1].getTime() - range[0].getTime()) / (1000*60*60*24)))
          .attr("height", function(d) { return height - y(d.value); });
        focus.select(".axis--x--vio").call(xAxis);
        focus.select(".axis--y--vio").call(yAxis);
        context.select(".brush").call(brush.move, x.range().map(t.invertX, t));
        zoombrush = 0;
      }

      const brush = d3.brushX()
        .extent([[0, 0], [width, height2]])
        .on("brush end", brushed);

      const zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", zoomed);

      const area = d3.area()
        .curve(d3.curveMonotoneX)
        .x(function(d) { return x(parseDate(d.date)); })
        .y0(height)
        .y1(function(d) { return y(d.value); });

      const area2 = d3.area()
        .curve(d3.curveMonotoneX)
        .x(function(d) { return x2(parseDate(d.date)); })
        .y0(height2)
        .y1(function(d) { return y2(d.value); });

      svg.append("defs").append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      const focus = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      const context = svg.append("g")
        .attr("class", "context")
        .attr("transform", "translate(" + margin2.left + "," + margin2.top + ")");

      /*
      focus.append("path")
        .datum(data)
        .attr("class", "area")
        .style("fill", "steelblue")
        .style("clip-path", "url(#clip)")
        .attr("d", area);
        */

      focus.selectAll(".barvio")
        .data(data)
        .enter().append("rect")
        .style("fill", "steelblue")
        .style("clip-path", "url(#clip)")
        .attr("class", "barvio")
        .attr("x", function(d) { return x(parseDate(d.date)); })
        .attr("y", function(d) { return y(d.value); })
        .attr("width", width / ((parseDate(data[data.length - 1].date).getTime() - parseDate(data[0].date).getTime()) / (1000*60*60*24)))
        .attr("height", function(d) { return height - y(d.value); });

      focus.append("g")
        .attr("class", "axis axis--x--vio")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      focus.append("g")
        .attr("class", "axis axis--y--vio")
        .call(yAxis);

      /*
      context.append("path")
        .datum(data)
        .attr("class", "area")
        .style("fill", "steelblue")
        .style("clip-path", "url(#clip)")
        .attr("d", area2);
        */

      context.selectAll(".barvio")
        .data(data)
        .enter().append("rect")
        .style("fill", "steelblue")
        .style("clip-path", "url(#clip)")
        .attr("class", "barvio")
        .attr("x", function(d) { return x2(parseDate(d.date)); })
        .attr("y", function(d) { return y2(d.value); })
        .attr("width", width / ((parseDate(data[data.length - 1].date).getTime() - parseDate(data[0].date).getTime()) / (1000*60*60*24)))
        .attr("height", function(d) { return height2 - y2(d.value); });

      context.append("g")
        .attr("class", "axis axis--x--vio")
        .attr("transform", "translate(0," + height2 + ")")
        .call(xAxis2);

      context.append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, x.range());

      svg.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("cursor", "move")
        .style("fill", "none")
        .style("pointer-event", "all")
        .call(zoom);
    },[data]);

  return (
    <svg
      ref={ref}
      style={{
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );

}

export default ScrollableRangeChart;
