/* eslint-disable */
import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Polygon,
  Circle,
} from 'react-leaflet';
import axios from 'axios';

import ConvertRestrictedLocation from '../../../utils/ConvertRestrictedLocation';

import { getPersonalRestrictions } from '../../exclusion/api';
import { ParticipantContext } from '../../../context/ParticipantContext';

import theme from 'src/theme';

const SpecificRestrictedZone = ({ setZoneGroup }) => {
  const participant = useContext(ParticipantContext);

  const pathOption = {
    color: theme.palette.zone,
    opacity: 0.5,
    fillOpacity: 0.6,
  };

  var individualRestrictedZoneGroup;
  useEffect(() => {
    individualRestrictedZoneGroup = [];
    if (participant != undefined) {
      getPersonalRestrictions(participant)
        .then((r) => {
          if (r.status === 200) {
            r.data.map((zone) => {
              var restrictedZone = [];
              if (zone.geom.type == "Polygon") {
                restrictedZone.push(zone.geom.coordinates[0]);
                individualRestrictedZoneGroup.push(
                  <Polygon
                    pathOptions={pathOption}
                    positions={ConvertRestrictedLocation(restrictedZone)}
                  />
                );
              } else if (zone.geom.type == "Point") {
                restrictedZone.push(zone.geom.coordinates[0]);
                restrictedZone.push(zone.geom.coordinates[1]);
                individualRestrictedZoneGroup.push(
                  <Circle
                    center={restrictedZone}
                    pathOptions={pathOption}
                    radius={zone.triggerDistance}
                  />
                );
              }
            });
            setZoneGroup(individualRestrictedZoneGroup);
          }
          else {
            setZoneGroup([]);
          }
        });
    }
  }, [participant.id]);

  return (
    <div>
      {individualRestrictedZoneGroup}
    </div>
  );
}

SpecificRestrictedZone.propTypes = {
  className: PropTypes.string
};

export default SpecificRestrictedZone;
