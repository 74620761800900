/* eslint-disable */
import { useContext } from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import NotificationBadge from './NotificationBadge';

import { NotificationContext } from 'src/context/NotificationContext';

const useStyles = makeStyles(() => ({
  root: {
    marginRight: '5px',
  },
  notificationButton: {
    minWidth: '0px',
    padding: '3px',
    color: 'LightSeaGreen',
  },
  notificationButtonNotSelected: {
    minWidth: '0px',
    padding: '3px',
    color: 'DarkGray',
  },
  badge: {
    backgroundColor: 'MediumTurquoise',
  },
  badgeNotSelected: {
    backgroundColor: 'LightSteelBlue',
  },
}));

const NotificationIconList = ({ participantId, selected }) => {
  const classes = useStyles();
  const notificationContext = useContext(NotificationContext);

  return (
    <div className={classes.root}>
      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_meeting.get(participantId)}
        selected={selected}
        type="meeting"
      />

      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_device.get(participantId)}
        selected={selected}
        type="device"
      />

      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_battery.get(participantId)}
        selected={selected}
        type="battery"
      />

      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_heart.get(participantId)}
        selected={selected}
        type="heart"
      />

      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_stress.get(participantId)}
        selected={selected}
        type="stress"
      />

      <NotificationBadge
        notificationData={notificationContext.notificationByParticipant_geo.get(participantId)}
        selected={selected}
        type="geo"
      />
    </div>
  );
};

export default NotificationIconList;
