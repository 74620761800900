import {
  Box,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer, Grid, Typography, Checkbox, withStyles, makeStyles, TablePagination
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import {FormControlLabel, FormGroup} from "@mui/material";

const useStyles = makeStyles(() => ({
  paperTitle: {
    paddingTop: '12px',
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const NewCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.text.primary
    },
    '&$disabled': {
      color: theme.palette.text.primary
    }
  },
  checked: {},
  disabled: {}
}))(Checkbox);

const ViolationsTable = ({ className, range, violationsData, setViolSync, ...rest }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const [sync, setSync] = useState(false);

  // variable to set max number of rows on the table, initialized to 5
  const [limit, setLimit] = useState(5);
  // variable to set the current table page, initialized to 0
  const [page, setPage] = useState(0);

  // changes the limit
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // changes the page
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    //refresh
  }, [violationsData]);

  const handleSyncChange = (event) => {
    if (sync)
    {
      setSync(false)
      setViolSync(false)
    }
    else {
      setSync(true)
      setViolSync(true)
    }
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title='Violation List'
        action={
          <FormGroup>
            <FormControlLabel control={<NewCheckbox
              labelPlacement={'start'}
              edge='end'
              checked={sync}
              onChange={handleSyncChange}
            />}/>
          </FormGroup>
        }
      />

        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>
                  Time and Date
                </TableCell>

                <TableCell>
                  GPS Location
                </TableCell>

                <TableCell>
                  Restricted Location
                </TableCell>

                <TableCell>
                  Violation Time
                </TableCell>
              </TableHead>

              <TableBody>
                {
                    violationsData.map((violation) => (
                        <TableRow>
                          <TableCell>
                            {new Date(violation.violationStartTime).toString()}
                          </TableCell>

                          <TableCell>
                            {violation.userLocation.coordinates[1]}, {violation.userLocation.coordinates[0]}
                          </TableCell>

                          <TableCell>
                            {violation.restrictedLocationName}
                          </TableCell>

                          <TableCell>
                            {violation.violationDuration} minutes
                          </TableCell>
                        </TableRow>
                      )
                    )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component='div'
            count={violationsData.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5]}
          />
        </Box>

    </Card>
  );
};

ViolationsTable.propTypes = {
  className: PropTypes.string
};

export default ViolationsTable;
