/* eslint-disable */

import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  CurrentTimeIndicator,
  AppointmentTooltip, Resources
} from '@devexpress/dx-react-scheduler-material-ui';
import {makeStyles, Paper, styled, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {ViewState} from '@devexpress/dx-react-scheduler';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {List, ListItem, ListItemIcon} from "@mui/material";
import ListItemText from "@material-ui/core/ListItemText";


const classes = {
  appointmentSelected: `appointment-selected`,
  appointment: `appointment`,
  apptContent: `apptContent`
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '65vh',
    maxHeight: '700px'
  }
}));


const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6
    }
  },
  [`&.${classes.appointmentSelected}`]: {
    borderRadius: '10px',
    borderWidth: '2px',
    borderColor: 'black',
    '&:hover': {
      opacity: 0.6
    }
  }
}));

const Appointment = (({children, handleClick, ...restProps}) => {
  let cssClass;
  if (restProps.data.selected) {
    cssClass = classes.appointmentSelected;
  } else {
    cssClass = classes.appointment;
  }

  return (
    <>
      <StyledAppointmentsAppointment
        key={restProps.data.selected}
        {...restProps}
        className={cssClass}
        onClick={(event) => {
          handleClick(event, restProps.data.id)
        }}
      >
        <React.Fragment>
          {children}
        </React.Fragment>
      </StyledAppointmentsAppointment>
    </>
  );
});

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({}));

const FlexibleSpace = () => (
  <StyledToolbarFlexibleSpace>
    <List>
      <ListItem>
        <ListItemIcon style={{justifyContent: 'center'}}>
          <FiberManualRecordIcon style={{color: '#03a9f4'}}/>
        </ListItemIcon>
        <ListItemText secondary="Program Specialist"/>

        <ListItemIcon style={{justifyContent: 'center'}}>
          <FiberManualRecordIcon style={{color: 'orange'}}/>
        </ListItemIcon>
        <ListItemText secondary="Participant"/>

      </ListItem>
    </List>

  </StyledToolbarFlexibleSpace>
);

const Calendar = ({appointments, handleClick}) => {
  const classes = useStyles();

  const [resources, setResources] = useState([
    {
      fieldName: 'sender',
      title: 'Sender',
      instances: [
        {id: 'participant', text: 'Participant'},
        {id: 'program specialist', text: 'Program Specialist'}
      ]
    }
  ])

  function myAppointment(props) {
    return (
      <Appointment
        {...props}
        handleClick={handleClick}
      />
    )
  }

  function flexibleSpace(props) {
    return (
      <FlexibleSpace/>
    )
  }

  return (
    <>
      <Paper className={classes.root}>
        <Scheduler data={appointments}>
          <ViewState
            defaultCurrentDate={new Date()}
          />

          <MonthView/>

          <Toolbar flexibleSpaceComponent={flexibleSpace}/>

          <DateNavigator/>

          <TodayButton/>

          <Appointments
            appointmentComponent={myAppointment}
          />

          <AppointmentTooltip/>

          <CurrentTimeIndicator updateInterval={10000} shadePreviousAppointments={true} shadePreviousCells={true}/>

          <Resources data={resources} palette={['orange', '#03a9f4']}/>

        </Scheduler>

      </Paper>

    </>
  );
};

export default Calendar;
