/* eslint-disable */
import { useState, useContext } from 'react';
import {
  Box, Button, Card, CardContent, CardHeader, TextField, Typography,
  Grid, Container, makeStyles
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router';
import { addAssessment } from '../api';
import { ParticipantContext } from '../../../context/ParticipantContext';

const assessment = [
  {
    value: 'Biopsychosocial Assessment',
    label: 'Biopsychosocial Assessment'
  },
  {
    value: 'RWAT',
    label: 'RWAT'
  },
  {
    value: 'Cultural Ecogram',
    label: 'Cultural Ecogram'
  }];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Assessment = () => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);
  const navigate = useNavigate();

  const [state, setState] = useState({
    assessmentType: 'Biopsychosocial Assessment',
    date: new Date(),
    startTime: new Date(),
    duration: 30
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleDate = (date) => {
    setState({
      ...state,
      date: date,
    });
  };

  const handleTime = (time) => {
    setState({
      ...state,
      startTime: time,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addAssessment(state, participant)
      .then(() => {
        navigate('/app/fivekey', { replace: true });
      });
  };


  return (
    <Page
      className={classes.root}
      title='Add Assessment'
    >
      <Container>
        <form name={'addassessment'} onSubmit={handleSubmit}>
          <Box spacing={10} sx={{ border: 0 }}>
            <Card>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>

                    <CardHeader
                      titleTypographyProps={{ variant: 'h5', component: 'span' }}
                      title='Add Primary Assessment'
                      style={{ backgroundColor: '#F4F6F8' }}
                    />
                  </Grid>
                  <Grid item>
                    <Container>
                      <Container>
                        <Container>
                          <Grid container direction='column' spacing={4}>

                            {/* Assessment Select HTML Start */}

                            <Grid item>
                              <Grid container direction='row' spacing={2}>

                                <Grid item md={6}>
                                  <label htmlFor={'key'}>
                                    <Typography variant='h6'>Select A Session</Typography>
                                  </label>
                                </Grid>

                                <Grid item md={6}>
                                  <TextField
                                    id={'assessmentType'}
                                    name={'assessmentType'}
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required={true}
                                    select={true}
                                    inputProps={{ style: { fontSize: '.85rem' } }}
                                    SelectProps={{ native: true }}
                                    value={state.assessmentType}
                                    variant={'outlined'}
                                  >
                                    {assessment.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>


                              </Grid>
                            </Grid>

                            {state.assessmentType !== 'RWAT' ? (
                              <>

                                {/* Date Picker HTML Start */}


                                <Grid item>
                                  <Grid container direction='row' spacing={2}>

                                    <Grid item md={6}>
                                      <label htmlFor={'date'}>
                                        <Typography variant='h6'>Date</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          id={'date'}
                                          name={'date'}
                                          required={true}

                                          fullWidth={true}
                                          inputVariant={'outlined'}
                                          format={'MM/dd/yyyy'}
                                          inputProps={{ style: { fontSize: '.85rem' } }}
                                          value={state.date}
                                          onChange={handleDate}
                                          KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                          }}
                                        >
                                        </KeyboardDatePicker>
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                  </Grid>
                                </Grid>

                                {/* Time Picker HTML Start*/}

                                <Grid item>
                                  <Grid container direction='row' spacing={2}>

                                    <Grid item md={6}>
                                      <label htmlFor={'time'}>
                                        <Typography variant='h6'>Start Time</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                          id={'time'}
                                          name={'time'}
                                          required={true}
                                          fullWidth={true}
                                          inputVariant={'outlined'}
                                          value={state.startTime}
                                          onChange={handleTime}
                                          inputProps={{ style: { fontSize: '.85rem' } }}
                                          KeyboardButtonProps={{
                                            'aria-label': 'change time'
                                          }}
                                        >
                                        </KeyboardTimePicker>
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                  </Grid>
                                </Grid>

                                {/* Duration Select Field HTML Start */}

                                <Grid item>
                                  <Grid container direction='row' spacing={2}>
                                    <Grid item md={6}>
                                      <label htmlFor={'duration'}>
                                        <Typography variant='h6'>Duration (minutes)</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <TextField
                                        id={'duration'}
                                        name={'duration'}
                                        required={true}
                                        fullWidth={true}
                                        type={'number'}
                                        inputProps={{ style: { fontSize: '.85rem' } }}
                                        value={state.duration}
                                        variant={'outlined'}
                                        onChange={handleChange}
                                      >
                                      </TextField>
                                    </Grid>

                                  </Grid>
                                </Grid>
                              </>

                            ) : (
                              <>
                              </>
                            )}

                            {/* Submit Button HTML Start */}

                            <Grid item>
                              <Box
                                align={'center'}
                                sx={{
                                  display: 'flex',
                                  p: 4
                                }}
                              >
                                <Button
                                  id={'submit'}
                                  name={'submit'}
                                  value={'Submit'}
                                  type={'submit'}
                                  color={'secondary'}
                                  variant={'contained'}
                                  align={'center'}
                                  underline={'hover'}
                                  size={'small'}
                                >
                                  Create Assessment
                                </Button>
                              </Box>
                            </Grid>

                          </Grid>
                        </Container>
                      </Container>
                    </Container>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </form>
      </Container>
    </Page>

  );


};


export default Assessment;
