/* eslint-disable */
import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  makeStyles
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Pane,
} from 'react-leaflet';
import Page from 'src/components/Page';
import {
  Card
} from '@material-ui/core';

import ParticipantList from './ParticipantList';
import CircleMarkerGroup from './CircleMarkerGroup';
import SelectedCircleMarker from './SelectedCircleMarker';
import SpecificRestrictedZone from './SpecificRestrictedZone';
import GeneralRestrictedZone from './GeneralRestrictedZone';
import RestrictedZoneToggleButton from './RestrictedZoneToggleButton';

import { ParticipantContext } from '../../../context/ParticipantContext';

import ConvertLocationStr from '../../../utils/ConvertLocationStr';
import Legend from './Legend'
import './styles.css'


const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '100vh',
    overflow: 'unset',
  },
  container: {
    position: 'relative',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  cardContainer: {
    height: '100%',
  },
  participantContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'absolute',
    width: 'calc(100% - 300px)',
  },
  participantList: {
    height: '416',
    width: 320,
    position: 'absolute',
    top: '30px',
    left: '20px',
    'z-index': theme.zIndex.drawer - 1,
    maxHeight: '416',
    overflow: 'auto',
  },
  mapnoteContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'absolute',
    width: 'calc(100% - 280px)',
  },
  mapnote: {
    position: 'absolute',
    top: '80px',
    left: 'calc(100% - 240px)',
    'z-index': theme.zIndex.drawer - 1,
  },
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  leafletTooltip: {
    padding: '0px 4px 0px 4px !important',
  },
  restrictedButtonContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'absolute',
    width: '100px',
    left: 'calc(100% - 100px)',
  },
  restrictedButtonGrid: {
    position: 'absolute',
    top: '15px',
    'z-index': theme.zIndex.drawer - 200,
    overflow: 'auto',
  },
  zoomButton: {
    paddingRight: '10px',
  },
}));

export default function HomeView() {
  const classes = useStyles();
  const [firstload, setFirstload] = useState(false);
  const participantContext = useContext(ParticipantContext);
  const [participants, setParticipants] = useState(participantContext.list);
  const [map, setMap] = useState(null);

  var position = [34.73, -86.60];

  const [individualRestrictedzone, setIndividualRestrictedzone] = useState([]);
  const [individualRestrictedzoneToggleOn, setIndividualRestrictedzoneToggleOn] = useState(true);
  const [individualRestrictedzoneData, setIndividualRestrictedzoneData] = useState([]);
  const [individualGeneralRestrictedzone, setIndividualGeneralRestrictedzone] = useState([]);
  const [individualGeneralRestrictedzoneData, setIndividualGeneralRestrictedzoneData] = useState([]);

  // Refresh each time one of these variables changes
  useEffect(() => {}, [map, individualRestrictedzoneData, individualGeneralRestrictedzoneData]);

  useEffect(() => {
    if (participantContext.latestLocation != null && firstload == false && map != null) {
      if (participantContext.latestLocation.length != 0) {
        MapSetCenter(ConvertLocationStr(participantContext.latestLocation));
        setFirstload(true);
      }
    }
  }, [participantContext, map]);

  useEffect(() => {
    if (individualRestrictedzoneToggleOn) {
      setIndividualRestrictedzone(individualRestrictedzoneData);
      setIndividualGeneralRestrictedzone(individualGeneralRestrictedzoneData);
    } else {
      setIndividualRestrictedzone([]);
      setIndividualGeneralRestrictedzone([]);
    }
  }, [individualRestrictedzoneToggleOn, individualRestrictedzoneData, individualGeneralRestrictedzoneData]);

  useEffect(() => {
    if (JSON.stringify(participantContext.list) != JSON.stringify(participants)) {
      setParticipants(participantContext.list);
      SelectParticipant(participantContext.name);
    }
  }, [participantContext]);

  const MapSetCenter = (center) => {
    if (map) {
      map.setView(center);
    }
  }

  const MapFlyTo = (location) => {
    if (location.length && map) {
      map.flyTo(location, 14, {
        duration: 1,
      });
    }
    return null;
  }

  const SelectParticipant = (participantId) => {
    if (participants != undefined) {
      participants.map((participant) => {
        if (participant.id == participantId) {
          MapFlyTo(ConvertLocationStr(participant.latestLocation));
          participantContext.setName(participant.name);
          localStorage.setItem('participantName', JSON.stringify(participant.name))
          participantContext.setId(participant.id);
          localStorage.setItem('participantId', JSON.stringify(participant.id))
          participantContext.setLatestLocation(participant.latestLocation);
          localStorage.setItem('participantLatestLocation', JSON.stringify(participant.latestLocation))
          if (participant.address) {
            participantContext.setStreet(participant.address.street);
            participantContext.setCity(participant.address.city);
            participantContext.setState(participant.address.state);
            localStorage.setItem('participantStreet', JSON.stringify(participant.address.street))
            localStorage.setItem('participantCity', JSON.stringify(participant.address.city))
            localStorage.setItem('participantState', JSON.stringify(participant.address.state))
          } else {
            participantContext.setStreet("");
            participantContext.setCity("");
            participantContext.setState("");
            localStorage.setItem('participantStreet', JSON.stringify(''))
            localStorage.setItem('participantCity', JSON.stringify(''))
            localStorage.setItem('participantState', JSON.stringify(''))
          }
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Page
        className={classes.root}
        title="Overview"
      ></Page>
      <Container maxWidth='true'>
        <Grid container className={classes.participantContainer}>
          <Grid item xs={12} className={classes.participantList}>
            <ParticipantList
              participantData={participants}
              selectParticipant={SelectParticipant}
              mapSetCenter={MapSetCenter}
            />
          </Grid>
        </Grid>
      </Container>


      <Container maxWidth='true'>
        <Grid container className={classes.restrictedButtonContainer}>
          <Grid item xs={6} className={classes.restrictedButtonGrid}>
            <RestrictedZoneToggleButton
              setToggle={setIndividualRestrictedzoneToggleOn}
            />
          </Grid>
        </Grid>
      </Container>

      <Card className={classes.cardContainer}>
        <Container maxWidth='true' className={classes.container}>
          <MapContainer spacing={3} center={position} zoom={14} zoomControl={false} scrollWheelZoom={true} className={classes.map} whenCreated={setMap}>
            <Pane name="selectedCircle" style={{ zIndex: 401 }} />
            <Pane name="selectedCircleTooltip" style={{ zIndex: 651 }} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <ZoomControl position="topright" className={classes.zoomButton} />

            <CircleMarkerGroup
              participantData={participants}
              selectParticipant={SelectParticipant}
            />

            <SelectedCircleMarker
              participantData={participants}
            />

            {/* Individual Restricted Zone */}
            <SpecificRestrictedZone
              setZoneGroup={setIndividualRestrictedzoneData}
            />

            {individualRestrictedzone}

            <GeneralRestrictedZone
              setZoneGroup={setIndividualGeneralRestrictedzoneData}
            />

            {individualGeneralRestrictedzone}

            <Legend map={map} />


          </MapContainer>
        </Container>
      </Card>
    </React.Fragment>
  );
}
