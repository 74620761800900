/* eslint-disable */
import { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  AppBar,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TitleContext } from '../../context/TitleContext';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import axios from 'axios';
import NotificationIcon from './NotificationIcon';

const officer = {
  avatar: '/static/images/avatars/avatar02.png',
  name: 'Case Manager'
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    padding: '0px 0px 0px 0px',
  },
  avatar: {
    width: 60,
    height: 60
  },
  appNameContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  appName: {
    color: 'white',
    'font-size': '1.25rem',
    padding: '0px 20px 0px 20px',
  },
  notificationIcon: {
    width: 'fit-content',
  },
  title: {
    fontSize: '1.1rem',
    width: '65vw',
  },
  specialistText: {
    height: '100%',
    padding: '0px 10px',
    color: 'DarkGray',
  },
  avatar: {
    width: 32,
    height: 32
  },
  name: {
    padding: '0px 10px 0px 5px',
    width: 'fit-content',
  },
  caseworkerDropdown: {
    position: 'absolute',
    top: '64px',
    left: 'calc(100% - 210px)',
    width: '180px',
    padding: '0px 10px 5px 10px',
    margin: '0px 10px 0px 10px',
    transition: 'opacity 0.3s',
    opacity: 1,
    zIndex: theme.zIndex.drawer,
  },
  listItemText: {
    padding: '0px 0px 0px 10px',
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const titleContext = useContext(TitleContext);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  axios.defaults.headers.common['Authorization'] = localStorage.getItem('accessToken')

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink
          to="/"
          onClick={() => { titleContext.setName("Overview"); }}
          className={classes.appNameContainer}
        >
          <Typography component="h1" align="center" noWrap className={classes.appName}>
            AI-SMS
          </Typography>
        </RouterLink>

        <NotificationIcon className={classes.notificationIcon} />

        <Typography component="h1" color="inherit" align="center" noWrap className={classes.title}>
          {titleContext.name}
        </Typography>

        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <List
              component="nav"
              className={classes.root}
            >
              <ListItem button onClick={handleClick}>
                <Typography
                  className={classes.specialistText}
                  variant="h6"
                >
                  Specialist
                </Typography>
                <Avatar
                  className={classes.avatar}
                  src={officer.avatar}
                />
                <Typography
                  className={classes.name}
                  color="inherit"
                  variant="h5"

                >
                  {officer.name}
                </Typography>
                {open ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
            </List>


            {open ?
              <List
                className={`${classes.caseworkerDropdown} ${classes.root}`}
              >
                <ListItem button component={RouterLink} to="/login" >
                  <ExitToAppIcon />
                  <ListItemText primary="Log out" className={classes.listItemText} />
                </ListItem>
              </List> : ''}
          </div>
        </ClickAwayListener>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
