const findObjectCenter = (location) => {

  if (location.geom.type === 'Point') {
    return location.geom.coordinates;
  }

  let avg = [0, 0];
  const locationCoordinates = location.geom.coordinates[0]

  locationCoordinates.forEach(v => {
    avg[1] += v[0];
    avg[0] += v[1];
  });


  avg[0] /= locationCoordinates.length;
  avg[1] /= locationCoordinates.length;

  return avg;
};

export default findObjectCenter;
