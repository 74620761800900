/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  List, ListItem, Divider, IconButton, ListItemSecondaryAction
} from '@material-ui/core';
import { ParticipantContext } from '../../../context/ParticipantContext';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { createDrugScreens, getCategoryData, updateDrugScreens } from '../api';
import TextFieldListItem from '../components/TextFieldListItem';
import SelectFieldListItem from '../components/SelectFieldListItem';
import SnackBarAlert from '../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  column: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  }
}));

const options = [
  {
    label: 'Negative',
    value: 0
  },
  {
    label: 'Positive',
    value: 1
  },

];

const DrugScreenQuery = ({ className, ...rest }) => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  let identifyingInfoColumnSkeleton = {
    drugScreenId: {
      label: 'Drug Screen ID',
      value: 'NO DATA'
    },
    caseId: {
      label: 'Case ID',
      value: 'NO DATA'
    },
    screeningDate: {
      label: 'Screening Date',
      value: 'NO DATA'
    },
    resultDate: {
      label: 'Result Date',
      value: 'NO DATA'
    },
    officialId: {
      label: 'Official ID',
      value: 'NO DATA'
    },
    drugScreeningType: {
      label: 'Drug Screen Type',
      value: 'NO DATA'
    },
    comments: {
      label: 'Comments',
      value: 'NO DATA'
    }
  };

  let leftResultsColumnSkeleton = {
    cannabinoids: {
      label: 'Cannabinoids',
      value: 'NO DATA',
      option: 0
    },
    cannabinoidesRatio: {
      label: 'Cannabinoids Ratio',
      value: 'NO DATA'
    },
    opiates: {
      label: 'Opiates',
      value: 'NO DATA',
      option: 0

    },
    benzodiazephines: {
      label: 'Benzodiazepines',
      value: 'NO DATA',
      option: 0

    },
    cocaineMetabolite: {
      label: 'Cocaine Metabolite',
      value: 'NO DATA',
      option: 0

    },
    barbiturates: {
      label: 'Barbiturates',
      value: 'NO DATA',
      option: 0

    },
    phencyclidine: {
      label: 'Phencyclidine',
      value: 'NO DATA',
      option: 0

    },
    buprenorphine: {
      label: 'Buprenorphine',
      value: 'NO DATA',
      option: 0

    },
    spice: {
      label: 'Spice',
      value: 'NO DATA',
      option: 0

    },
    lsd: {
      label: 'LSD',
      value: 'NO DATA',
      option: 0

    },
    bathSalts: {
      label: 'Bath Salts',
      value: 'NO DATA',
      option: 0

    }
  };

  let rightResultsColumnSkeleton = {
    amphetamine: {
      label: 'Amphetamine',
      value: 'NO DATA',
      option: 0
    },
    methamphetamine: {
      label: 'Methamphetamine',
      value: 'NO DATA',
      option: 0
    },
    oxycodone: {
      label: 'Oxycodone',
      value: 'NO DATA',
      option: 0
    },
    propoxyphene: {
      label: 'Propoxyphene',
      value: 'NO DATA',
      option: 0
    },
    methadone: {
      label: 'Methadone',
      value: 'NO DATA',
      option: 0
    },
    ethanol: {
      label: 'Ethanol',
      value: 'NO DATA',
      option: 0
    },
    pbtResults: {
      label: 'P.B.T Results',
      value: 'NO DATA'
    },
    creatinine: {
      label: 'Creatinine',
      value: 'NO DATA'
    },
    otherName: {
      label: 'Other (Name)',
      value: 'NO DATA'
    },
    otherResult: {
      label: 'Other (Result)',
      value: 'NO DATA',
      option: 0
    },
    otherRatio: {
      label: 'Other (Ratio)',
      value: 'NO DATA'
    }
  };

  const [identifyingInfoColumn, setIdentifyingInfoColumn] = useState(identifyingInfoColumnSkeleton);
  const [leftResultsColumn, setLeftResultsColumn] = useState(leftResultsColumnSkeleton);
  const [rightResultsColumn, setRightResultsColumn] = useState(rightResultsColumnSkeleton);
  const [editable, setEditable] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [id, setID] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(``);
  const [severity, setSeverity] = useState(``);

  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getCategoryData(participant, 'DRUG_SCREEN_QUERY')
      .then((data) => {
        if (data && data['drugScreenQuery']) {
          for (const [key, value] of Object.entries(data['drugScreenQuery'])) {
            if (key === 'id') {
              setID(value);
            }

            if (key in identifyingInfoColumn) {
              setIdentifyingInfoColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: identifyingInfoColumn[key].label,
                  value: value
                }
              }));
            } else if (key in leftResultsColumn) {
              if (key === 'cannabinoidesRatio') {
                setLeftResultsColumn(prevState => ({
                  ...prevState,
                  [key]: {
                    label: leftResultsColumn[key].label,
                    value: value
                  }
                }));
              } else {
                if (parseInt(value) === 0) {
                  setLeftResultsColumn(prevState => ({
                    ...prevState,
                    [key]: {
                      label: leftResultsColumn[key].label,
                      value: 'Negative',
                      option: 0
                    }
                  }));
                } else {
                  setLeftResultsColumn(prevState => ({
                    ...prevState,
                    [key]: {
                      label: leftResultsColumn[key].label,
                      value: 'Positive',
                      option: 1
                    }
                  }));
                }
              }
            } else if (key in rightResultsColumn) {
              if (key === 'pbtResults' || key === 'creatinine' || key === 'otherName' || key === 'otherRatio') {
                setRightResultsColumn(prevState => ({
                  ...prevState,
                  [key]: {
                    label: rightResultsColumn[key].label,
                    value: value
                  }
                }));
              } else {
                if (parseInt(value) === 0) {
                  setRightResultsColumn(prevState => ({
                    ...prevState,
                    [key]: {
                      label: rightResultsColumn[key].label,
                      value: 'Negative',
                      option: 0
                    }
                  }));
                } else {
                  setRightResultsColumn(prevState => ({
                    ...prevState,
                    [key]: {
                      label: rightResultsColumn[key].label,
                      value: 'Positive',
                      option: 1
                    }
                  }));
                }
              }
            }
          }
        } else {
          setIdentifyingInfoColumn(identifyingInfoColumnSkeleton);
          setRightResultsColumn(rightResultsColumnSkeleton);
          setLeftResultsColumn(leftResultsColumnSkeleton);
        }
      });
  }, [participant.id, canceled]);

  const handleIdentifyingInfoColumnChange = (event) => {
    setIdentifyingInfoColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: identifyingInfoColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleLeftResultsColumnChange = (event) => {
    if (event.target.name !== 'cannabinoidesRatio') {
      let value = '';
      if (event.target.value === '0') {
        value = 'Negative';
      } else {
        value = 'Positive';
      }
      setLeftResultsColumn(prevState => ({
        ...prevState,
        [event.target.name]: {
          label: leftResultsColumn[event.target.name].label,
          value: value,
          option: parseInt(event.target.value)
        }
      }));
    } else {
      setLeftResultsColumn(prevState => ({
        ...prevState,
        [event.target.name]: {
          label: leftResultsColumn[event.target.name].label,
          value: event.target.value
        }
      }));
    }
  };


  const handleRightResultsColumnChange = (event) => {
    if (event.target.name !== 'pbtResults' || event.target.name !== 'creatinine' || event.target.name !== 'otherName' || event.target.name !== 'otherRatio') {
      let value = '';
      if (event.target.value === '0') {
        value = 'Negative';
      } else {
        value = 'Positive';
      }
      setRightResultsColumn(prevState => ({
        ...prevState,
        [event.target.name]: {
          label: rightResultsColumn[event.target.name].label,
          value: value,
          option: parseInt(event.target.value)
        }
      }));
    } else {
      setRightResultsColumn(prevState => ({
        ...prevState,
        [event.target.name]: {
          label: rightResultsColumn[event.target.name].label,
          value: event.target.value
        }
      }));
    }
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            className={classes.container}
          >
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
            >
              <Typography
                color='textSecondary'
                variant='body1'
              >
                <List>
                  <ListItem className={classes.sectionTitle}>
                    <b>Drug Screen Query</b>
                    <ListItemSecondaryAction>
                      {editable ? (
                        <>
                          <IconButton aria-label='save' onClick={() => {
                            if (id) {
                              updateDrugScreens(participant, Object.assign({}, identifyingInfoColumn, leftResultsColumn, rightResultsColumn), id)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to save changes, try again later.`, 'error');
                                      setCanceled(!canceled);

                                    } else {
                                      handleAlert(`Changes saved!`, 'success');
                                    }
                                  } else {
                                    handleAlert(`Failed to save changes, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            } else {
                              createDrugScreens(participant, Object.assign({}, identifyingInfoColumn, leftResultsColumn, rightResultsColumn), 1)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to create new data, try again later.`, 'error');
                                      setCanceled(!canceled);
                                    } else {
                                      handleAlert(`New data saved!`, 'success');
                                      setID(1)
                                    }
                                  } else {
                                    handleAlert(`Failed to create new data, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            }

                            setEditable(false);
                          }}>
                            <SaveIcon />
                          </IconButton>
                          <IconButton aria-label='cancel' onClick={() => {
                            setCanceled(!canceled);
                            setEditable(false);
                          }}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton aria-label='edit' onClick={() => {
                          setEditable(true);
                        }}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={4}
                      xl={4}
                    >

                      <List>
                        {Object.keys(identifyingInfoColumn).map((key) => (
                          <TextFieldListItem
                            name={key}
                            editable={editable}
                            label={identifyingInfoColumn[key].label}
                            value={identifyingInfoColumn[key].value}
                            changeHandler={handleIdentifyingInfoColumnChange}
                          />
                        ))}
                      </List>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <List>
                        {Object.keys(leftResultsColumn).map((key) => {
                          if (key !== 'cannabinoidesRatio') {
                            return (
                              <SelectFieldListItem
                                editable={editable}
                                name={key}
                                label={leftResultsColumn[key].label}
                                value={leftResultsColumn[key].value}
                                classes={classes}
                                options={options}
                                selection={leftResultsColumn[key].option}
                                changeHandler={handleLeftResultsColumnChange}
                              />
                            );
                          } else {
                            return (
                              <TextFieldListItem
                                editable={editable}
                                name={key}
                                label={leftResultsColumn[key].label}
                                value={leftResultsColumn[key].value}
                                classes={classes}
                                changeHandler={handleLeftResultsColumnChange}
                              />
                            );

                          }

                        })}
                      </List>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <List>
                        {Object.keys(rightResultsColumn).map((key) => {
                          if (key !== 'pbtResults' && key !== 'creatinine' && key !== 'otherName' && key !== 'otherRatio') {
                            return (
                              <SelectFieldListItem
                                editable={editable}
                                name={key}
                                label={rightResultsColumn[key].label}
                                value={rightResultsColumn[key].value}
                                classes={classes}
                                options={options}
                                selection={rightResultsColumn[key].option}
                                changeHandler={handleRightResultsColumnChange}
                              />
                            );
                          } else {
                            return (
                              <TextFieldListItem
                                editable={editable}
                                name={key}
                                label={rightResultsColumn[key].label}
                                value={rightResultsColumn[key].value}
                                classes={classes}
                                changeHandler={handleRightResultsColumnChange}
                              />
                            );

                          }
                        })}
                      </List>
                    </Grid>

                  </Grid>
                </List>
              </Typography>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
      <SnackBarAlert
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </>
  );
};

DrugScreenQuery.propTypes = {
  className: PropTypes.string
};

export default DrugScreenQuery;
