import { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { getRewardProgress, getRewards } from '../api';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import RewardList from './RewardList';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {props.valueAsNumber}/{props.maxValue}
        </Typography>
      </Box>
    </Box>
  );
}

const RewardIndex = () => {

  const participant = useContext(ParticipantContext);

  const [rewards, setRewards] = useState([])

  const [updated, setUpdated] = useState(false)

  const [points, setPoints] = useState(0)
  const [targetPoints, setTargetPoints] = useState(0)

  const update = () => {
    setUpdated(!updated)
  }

  useEffect(() => {
    getRewardProgress(participant)
      .then((r) => {
        if (r) {
          setPoints(r.data.currentPoints)
          setTargetPoints(r.data.targetPoints)
        }
      })

    getRewards(participant)
      .then((r) => {
        if (r) {
          if (r.status === 200) {
            r.data.sort((a, b) => a.targetPoints < b.targetPoints ? -1 : a.targetPoints > b.targetPoints ? 1 : 0)
            setRewards(r.data)
          }
        }
      })
  }, [participant.id, updated])



  return (
    <Card style={{ height: '100%' }}>

      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title={'Rewards'}
      />

      <CardContent style={{paddingTop: 0}}>
        <Grid container>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Card style={{boxShadow: 'none', border: 'none'}}>
              <CardContent>
                <Typography>Progress to next Reward:</Typography>
                <LinearProgressWithLabel variant="determinate" value={points/targetPoints * 100} valueAsNumber={points} maxValue={targetPoints}/>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <RewardList rewards={rewards} update={update}/>
          </Grid>

        </Grid>
      </CardContent>


    </Card>
  )
}

export default RewardIndex
