import {
  Box,
  Button,
  Card,
  CardHeader,
  IconButton, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import NewRewardDialog from './NewRewardDialog';
import EditRewardDialog from './EditRewardDialog';
import SnackBarAlert from '../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContent: {
    height: '100%'
  },
  pendingBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'SteelBlue',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'SteelBlue'
    },
    pointerEvents: 'none'
  },
  claimedBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'Gold',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'Gold'
    },
    pointerEvents: 'none'
  },
}));

const ShowStatus = (props) => {
  let status = props.status;
  let classes = props.classes;
  let boxClass;

  if (status === 'claimed') {
    boxClass = classes.claimedBox;
  } else if (status === 'pending') {
    boxClass = classes.pendingBox;
  }

  return (
    <>
      <Button
        sx={{
          paddingTop: 0,
          '&:hover': {
            background: 'none'
          }
        }}
        variant='contained'
        className={boxClass}
      >
        {status === 'REWARD_CLAIMED' ? ('REWARD CLAIMED') : (status)}
      </Button>
    </>
  );
};

const RewardList = ({ rewards, update }) => {

  const classes = useStyles()

  const [open, setOpen] = useState(false);

  // Determines if the alert pop-up is open or not
  const [openAlert, setOpenAlert] = useState(false);
  // Message for the alert pop-up
  const [message, setMessage] = useState('');
  // Severity for the alert pop-up
  const [severity, setSeverity] = useState(``);

  // function to open the alert pop-up
  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
  };

  // function close the alert pop-up
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleCloseDialog = () => {
    update()
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const [selectedRewardForEdit, setSelectedRewardForEdit] = useState(null);

  const handleCloseEditDialog = () => {
    update()
    setOpenEdit(false);
  };

  return (
    <>
      <Card >

        <CardHeader
          titleTypographyProps={{ variant: 'h5', component: 'span' }}
          style={{ backgroundColor: '#F4F6F8' }}
          title='Reward List'
          action={
            <>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <AddIcon fontSize={'small'} />
              </IconButton>
            </>
          }
        />

        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell width={'10%'}>
                  Reward#
                </TableCell>

                <TableCell width={'50%'}>
                  Description
                </TableCell>

                <TableCell width={'15%'}>
                  TargetPoint
                </TableCell>

                <TableCell width={'15%'}>
                  Status
                </TableCell>

                <TableCell width={'10%'} />
              </TableHead>

              <TableBody>
                {rewards.map((reward) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {reward.id}
                      </TableCell>

                      <TableCell>
                        {reward.description}
                      </TableCell>

                      <TableCell>
                        {reward.targetPoints}
                      </TableCell>

                      <TableCell>
                        <ShowStatus
                          status={reward.status}
                          classes={classes}
                        />
                      </TableCell>

                      <TableCell>
                        <Button
                          variant='outlined'
                          underline='hover'
                          halfWidth
                          align='right'
                          size='small'
                          onClick={() => {
                            setOpenEdit(true);
                            setSelectedRewardForEdit(reward);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <NewRewardDialog open={open} handleCloseDialog={handleCloseDialog} handleAlert={handleAlert}/>
        <EditRewardDialog open={openEdit} handleCloseDialog={handleCloseEditDialog} reward={selectedRewardForEdit} handleAlert={handleAlert} />
        <SnackBarAlert
          open={openAlert}
          handleClose={handleClose}
          severity={severity}
          message={message}
        />

      </Card>
    </>
  );
};

export default RewardList;
