/* eslint-disable */

import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
const lcpProxy = 'http://localhost:8010/proxy';

export const getMeetings = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/scheduling/meetings`, {
      params: {
        participantID: participant.id,
      }
    });

    return data;

  } catch (error) {
    console.log(error);
  }
}

export const addMeeting = async (participant, id, state, startTime, endTime) => {
  try {
    return await axios.post(`${BASE_URL}/scheduling/meetings`, {
      approved: true,
      end_time: `${endTime.toISOString()}`,
      id: id,
      location: state.address,
      participantID: participant.id,
      receiver: 'participant',
      purpose: state.purpose,
      required: state.required,
      sender: "program specialist",
      start_time: `${startTime.toISOString()}`,
      title: state.title,
      type: state.type,
      zoomToken: "string"
    })

  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const approveMeeting = async (state, status) => {
  try {
    return await axios.post(`${BASE_URL}/scheduling/meetings/approve`, {
      approvalTime: new Date().toISOString(),
      approved: status,
      approvedBy: "case worker",
      caseWorkerId: 1,
      id: state.id,
      meetingId: state.id,
      participantId: parseInt(state.participantID),
      reason: "Approved/Rejected"
    })

  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const deleteMeeting = async (meeting) => {
  try {
    return await axios.delete(`${BASE_URL}/scheduling/meetings/${meeting.id}`)

  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const attendMeeting = async (state, status) => {
  try {
    return await axios.post(`${BASE_URL}/scheduling/meetings/complete`, {
      attended: status,
      id: state.id
    })

  } catch (error) {
    console.log(error)
    return error.response
  }
}
