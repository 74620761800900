/* eslint-disable */
import { createContext, useState } from "react";

export const TitleContext = createContext();

export const TitleProvider = ({children}) => {
  let currentTitle = window.location.href.split('/');
  currentTitle = currentTitle[currentTitle.length-1];
  if (currentTitle == "overview" || currentTitle == "") {
    currentTitle = "Overview";
  } else if (currentTitle == "apptask") {
    currentTitle = "App&Tasks or JOB";
  } else if (currentTitle == "participantstatus") {
    currentTitle = "Participant Status";
  } else if (currentTitle == "taskmanagement" ) {
    currentTitle = "Task Management";

  }
  else if (currentTitle == "addTask" ) {
    currentTitle = "Add New Task";

  }
  else if (currentTitle == "editTask" ) {
    currentTitle = "Edit Current Task";

  }
  else if (currentTitle == "goalapp") {
    currentTitle = "GOAL App";
  } else {
    currentTitle = currentTitle.charAt(0).toUpperCase() + currentTitle.slice(1);
  }

  const [name, setName] = useState(currentTitle);

  return (
      <TitleContext.Provider
        value={{
          name, setName,
        }}
      >
        {children}
      </TitleContext.Provider>
    );
  };