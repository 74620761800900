/* eslint-disable */
import {
  makeStyles,
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import GroupIcon from '@material-ui/icons/Group';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';

const useStyles = makeStyles(() => ({
  notificationButton: {
    minWidth: '0px',
    padding: '3px',
    color: 'LightSeaGreen',
  },
  notificationButtonNotSelected: {
    minWidth: '0px',
    padding: '3px',
    color: 'DarkGray',
  },
  badge: {
    backgroundColor: 'MediumTurquoise',
  },
  badgeNotSelected: {
    backgroundColor: 'LightSteelBlue',
  },
}));

const NotificationIcon = ({ type, fontSize }) => {
  const classes = useStyles();

  return (
    <>
      {type === "device" ? <PhonelinkRingIcon fontSize={fontSize} />
        : type === "meeting" ? <GroupIcon fontSize={fontSize} />
          : type === "battery" ? <BatteryAlertIcon fontSize={fontSize} />
            : type === "heart" ? <FavoriteIcon fontSize={fontSize} />
              : type === "stress" ? <FlashOnIcon fontSize={fontSize} />
                : type === "geo" ? <LocationOnIcon fontSize={fontSize} />
                  : <> </>}
    </>
  );
};

export default NotificationIcon;
