/* eslint-disable */
import {useState, useRef, useEffect, useContext} from 'react';
import {
  Card, CardContent,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Map from './Map';
import ViolationsTable from './ViolationsTable';
import {getViolationData} from "./api";
import {ParticipantContext} from "../../../context/ParticipantContext";
import Violation from "./Violation";
import ScreenTime from "./ScreenTime";
import StressScore from "./StressScore";
import HeartRate from "./HeartRate";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    minWidth: 1200
  },
  firstRow: {
    height: '300px',
  },
  flexContainer: {
    minHeight: '0px',
  },
}));

const AppTaskView = () => {
  const classes = useStyles();
  const pageRef = useRef();

  const participant = useContext(ParticipantContext);
  const [violationsData, setViolationsData] = useState([]);
  const [brushedViolationsData, setBrushedViolationsData] = useState(-1);

  const [brushDataRange, setBrushDataRange] = useState([]);
  const [violSync, setViolSync] = useState(false);

  const [width, setWidth] = useState(() => {
    const initialState = checkScreenWidth();
    return initialState;
  });

  useEffect( () => {
    setWidth(checkScreenWidth())
  }, [window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth]);

  function checkScreenWidth()
  {
    let screenWidth = (window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth)

    if (screenWidth > 1536)
    {
      return screenWidth - 336
    }
    else {
      return 1200
    }
  }

  useEffect(() => {
    pageRef.current.scrollIntoView();
  }, [pageRef]);

  useEffect(() => {
    let tempData = []
    getViolationData(participant)
      .then((data) => {
        if (data)
        {
          data.violationStackRecords.map((violation) => {
            if (violation.restrictLocation.type === 'Point') {
              tempData.push(violation)
            } else if (violation.restrictLocation.type === 'Polygon') {
              violation.restrictLocation.coordinates[0].map((coords) => {
                coords.reverse()
              })
              tempData.push(violation)
            }
          })

          setViolationsData(tempData);
        }
      });
  }, [participant.id]);

  useEffect(() => {
    if (violSync)
    {
      if (violationsData)
      {
        if (brushDataRange)
        {
          const newData = []

          for (let i=0; i < violationsData.length; i++) {
            let instanceDate = new Date(violationsData[i].violationStartTime.toString());

            if (instanceDate >= brushDataRange[0]) {
              if (instanceDate <= brushDataRange[1]) {
                newData.push({
                  id: violationsData[i].id,
                  userId: violationsData[i].userId,
                  userLocation: violationsData[i].userLocation,
                  restrictedLocationName: violationsData[i].restrictedLocationName,
                  restrictLocation: violationsData[i].restrictLocation,
                  violationDuration: violationsData[i].violationDuration,
                  violationStartTime: violationsData[i].violationStartTime,
                  violationEndTime: violationsData[i].violationEndTime
                });
              }
              else
              {
                break;
              }
            }
          }
          setBrushedViolationsData(newData);
        }
      }
    }
    else {
      setBrushedViolationsData(-1)
    }
  }, [violSync, brushDataRange]);

  return (
    <Page
      className={classes.root}
      title="Analytics"
    >
      <Card style={{height: '100%'}}>
        <CardContent>
          <Grid
            container
            spacing={1}
            className={classes.flexContainer}
            ref={pageRef}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Map
                violationsData = {brushedViolationsData !== -1 ? brushedViolationsData : violationsData}
                width = {width}
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Violation
                width = {width}
                setBrushDataRange = {setBrushDataRange}
              />

            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <ViolationsTable
                violationsData = {brushedViolationsData !== -1 ? brushedViolationsData : violationsData}
                setViolSync = {setViolSync}
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <ScreenTime
                width = {width}
                brushDataRange = {brushDataRange}
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <StressScore
                width = {width}
                brushDataRange = {brushDataRange}
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <HeartRate
                width = {width}
                brushDataRange = {brushDataRange}
              />
            </Grid>

          </Grid>

        </CardContent>

      </Card>

    </Page>
  );
};

export default AppTaskView;
