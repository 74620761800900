/* eslint-disable */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import Alert from '@material-ui/lab/Alert';
import { TitleContext } from '../../context/TitleContext';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  loginContent: {
    padding: "0px 0px 120px 0px",
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const titleContext = useContext(TitleContext);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');

  localStorage.clear()

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        className={classes.loginContent}
      >
        <Container maxWidth="sm">
          {alert ? <Alert severity='error'>{alertContent}</Alert> : <></>}
          <Formik
            initialValues={{
              // To make testing less repetitive by avoiding requiring the correct credential every time
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const auth = getAuth();
              const email = values.email;
              const password = values.password;
              setPersistence(auth, browserSessionPersistence).then(() => {
                signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                  const user = userCredential.user;
                  if (user) {
                    if (localStorage.length === 0) {
                      localStorage.setItem('accessToken', `Bearer ${user.accessToken}`)
                    }
                    axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`
                  }
                  navigate('/app/overview', { replace: true });
                })
              }).catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  setAlertContent(errorMessage);
                  setAlert(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      titleContext.setName("Overview");
                    }}
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
