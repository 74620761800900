/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';

import Page from 'src/components/Page';

import { Link } from 'react-router-dom';
import {
  Box, Button, Card, CardHeader, Divider, Table, TableBody, TableCell,
  TableHead, TableRow, Grid, CardContent, makeStyles, Typography, TableContainer
} from '@material-ui/core';
import { getAssessmentData, getSessionData } from '../api';
import { useNavigate } from 'react-router';

  import logo1 from '../../../icons/MeaningfulWorkTrajectoriesIcon.svg'
import logo2 from '../../../icons/PositiveRelationshipsIcon.svg'
import logo3 from '../../../icons/EffectiveCopingStrategiesIcon.svg'
import logo4 from '../../../icons/PositiveSocialEngagementIcon.svg'
  import logo5 from '../../../icons/HealthyThinkingPatternsIcon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  addButton: {
    paddingTop: '6px'
  },
  sessionTable: {
    paddingTop: '15px'
  },
  icon: {
    paddingTop: '17px'
  },
  grid: {
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid lightgray'
    }
  },
  sessionKeyText: {
    maxWidth: 200,
    maxHeight: 80,
    [theme.breakpoints.only('xl')]: {
    },
    [theme.breakpoints.only('lg')]: {
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 15
    }
  }
}));

const Fivekey = () => {
  const classes = useStyles();

  const participant = useContext(ParticipantContext);
  const navigate = useNavigate();

  const [assessmentList, setAssessmentList] = useState([]);

  const dataSkeleton = [
    {
      "key": "Meaningful Work Trajectories",
      "sessionList": []
    },
    {
      "key": "Positive Relationships",
      "sessionList": []
    },
    {
      "key": "Effective Coping Strategies",
      "sessionList": []
    },
    {
      "key": "Positive Social Engagement",
      "sessionList": []
    },
    {
      "key": "Healthy Thinking Patterns",
      "sessionList": []
    }
  ]

  const [sessionList, setSessionList] = useState(dataSkeleton);

  const goToAssessment = (data) => {
    if (data.assessmentType === 'RWAT') {
      navigate('/app/fivekey/RWATInfo', { state: { data }, replace: true });
    } else {
      navigate('/app/schedule', { state: { data }, replace: true });
    }
  };


  function SessionIcons(key){
    if (key === 'Meaningful Work Trajectories') {
      return (
        <img src={logo1} alt='mySvgImage' style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50}} />
      )
    } else if (key === 'Positive Relationships') {
      return (
        <img src={logo2} alt='mySvgImage' style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50}} />
      )
    } else if (key === 'Effective Coping Strategies') {
      return (
        <img src={logo3} alt='mySvgImage' style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50}} />
      )
    } else if (key === 'Positive Social Engagement') {
      return (
        <img src={logo4} alt='mySvgImage' style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50}} />
      )
    } else if (key === 'Healthy Thinking Patterns') {
      return (
        <img src={logo5} alt='mySvgImage' style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50}} />

      )
    }

  }

  const goToSession = (sessionLogId) => {
    navigate('/app/fivekey/caseInfo', { state: { sessionLogId: sessionLogId }, replace: true });
  };

  useEffect(() => {
    if (participant.id) {
      getAssessmentData(participant)
        .then((data) => {
          let assessmentList = [];
          if (data) {
            data.primaryAssessmentLogList.map((assessment) => {
              if (assessment.assessmentType !== 'RWAT') {
                assessment.meetingStartTime = (new Date(assessment.meetingStartTime * 1000)).toString();
              }
              assessmentList.push(assessment);
            });
            setAssessmentList(assessmentList);
          }
        });

      getSessionData(participant)
        .then((data) => {
          let sessionList = [];
          console.log(data)
          if (data) {
            data.sessionLogList.map((session) => {
              sessionList.push(session);
            });
            setSessionList(sessionList);

          }
        });

    }

  }, [participant.id]);


    return (
      <Page title='5 Key-Model'>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={12}
              >
                <Card>
                  <CardHeader
                    titleTypographyProps={{ variant: 'h5', component: 'span' }}
                    style={{ backgroundColor: '#F4F6F8' }}
                    title='PRIMARY ASSESSMENT'
                    action={
                      <Box className={classes.addButton}
                           Container
                           align='right'
                           sx={{
                             display: 'flex',
                             justifyContent: 'flex-end',
                             pt: 10
                           }}>
                        <Link to={{

                          pathname: '/app/fivekey/addAssessment'

                        }}>
                          <Button
                            color='secondary'
                            variant='contained'
                            underline='hover'
                            halfWidth
                            align='right'
                            size='small'
                          >
                            + Add Assessment
                          </Button>
                        </Link>
                      </Box>
                    }
                  />

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableCell>
                          Assessment
                        </TableCell>
                        <TableCell>
                          Status
                        </TableCell>
                        <TableCell>
                          Start Time
                        </TableCell>
                        <TableCell>
                          End Time
                        </TableCell>
                        <TableCell>

                        </TableCell>

                      </TableHead>
                      <TableBody>
                        {assessmentList.map((data) => (

                          <TableRow hover key={data.id}
                                    onClick={() => (goToAssessment(data))}
                          >
                            <TableCell>
                              {data.assessmentType}
                            </TableCell>
                            <TableCell>
                              {data.status}
                            </TableCell>

                            <TableCell>
                              {data.meetingStartTime}

                            </TableCell>
                            <TableCell>
                              {data.actualEndTime}

                            </TableCell>
                            <TableCell>
                              <Box
                                Container
                                align='right'
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  pt: 11,
                                  paddingTop: '0px'
                                }}>
                                <Link to={{
                                  pathname: '/app/fivekey/assessmentInfo'
                                }}>
                                  <Button
                                    color='secondary'
                                    variant='contained'
                                    underline='hover'
                                    halfWidth
                                    align='right'
                                    size='small'
                                  >
                                    {data.assessmentType === 'RWAT' ? (
                                      <>
                                        VIEW
                                      </>
                                    ) : (
                                      <>
                                        START
                                      </>
                                    )}
                                  </Button>
                                </Link>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                xl={12}
              >

                <Card>
                  <CardHeader
                    titleTypographyProps={{ variant: 'h5', component: 'span' }}
                    style={{ backgroundColor: '#F4F6F8' }}
                    title='CASE CONCEPTUALIZATION'

                    action={
                      <Box className={classes.addButton}
                           Container
                           align='right'
                           sx={{
                             display: 'flex',
                             justifyContent: 'flex-end',
                             pt: 10
                           }}>
                        <Link to={{
                          pathname: '/app/fivekey/addSession'
                        }}>
                          <Button
                            color='secondary'
                            variant='contained'
                            underline='hover'
                            halfWidth
                            align='right'
                            size='small'
                          >
                            + Add Session
                          </Button>
                        </Link>
                      </Box>
                    }
                  />
                  <Divider />

                  <Grid container direction='row' justify={'space-between'} spacing={1}
                        className={classes.sessionTable}>
                    {sessionList.map((sessionType, index) => (

                      <Grid
                        className={classes.grid}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={2}
                      >
                        <Table>
                          <TableHead>
                            <TableCell className={classes.sessionKeyText}>
                              {sessionType.key}
                            </TableCell>
                            <TableCell style={{ padding: 0, textAlign: 'center'}}>
                              {/* {SessionIcons(sessionType.key)} */}
                            </TableCell>
                          </TableHead>
                          <TableBody>

                            {sessionType.sessionList.map((session) => (
                              <TableRow hover fullWidth style={{ maxHeight: 80}}>

                                <TableCell>
                                  {session.sessionTitle}
                                </TableCell>

                                <TableCell>
                                  <Button
                                    variant='outlined'
                                    underline='hover'
                                    halfWidth
                                    align='right'
                                    size='small'
                                    onClick={() => {
                                      goToSession(session.sessionLogId);
                                    }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                      </Grid>
                    ))}
                  </Grid>
                </Card>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Page>
    );
};

export default Fivekey;

