import React, { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import { getUserStatus } from '../api';
import ParticipantInfoSearchBar from '../components/ParticipantInfoSearchBar';
import { Link } from 'react-router-dom';
import { FileUploadOutlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  column: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  },
  listKey: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  },
  columnLeft: {
    float: 'left',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  }
}));

function displayDate(date) {
  let newDate = new Date(date);
  return `${newDate.getMonth()}/${newDate.getDate()}/${newDate.getFullYear()}`;
}

const UserStatus = ({ setDisplayedObjects }) => {
  const classes = useStyles();

  const participant = useContext(ParticipantContext);

  const [userStatus, setUserStatus] = useState({});

  useEffect(() => {
    getUserStatus(participant)
      .then((r) => {
        setUserStatus(r.data);
      });
  }, [participant.id]);

  return (
    <Card
      className={classes.root}
    >

      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title='Participant Status'
        action={
          <>
            <Grid container>
              <Grid item>
                <ParticipantInfoSearchBar setDisplayedObjects={setDisplayedObjects} />
              </Grid>
              <Grid item>

                <Link to={{
                  pathname: '/app/participantstatus/upload'
                }}
                >
                  <IconButton>
                    <FileUploadOutlined />

                  </IconButton>


                </Link>
              </Grid>
            </Grid>

          </>

        }
      />

      <CardContent>

        <Grid
          container
          className={classes.container}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
          > <Typography
            color='textSecondary'
            variant='body1'
          >
            <List>
              <ListItem className={classes.sectionTitle}>
                <b>Participant Program Information</b>
              </ListItem>
              <Divider />
              <div>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid
                      item
                    >
                      <b>Program Dates</b>

                    </Grid>

                    <Grid
                      item
                    >
                      {displayDate(userStatus.programStartTime)} - {displayDate(userStatus.programEndTime)}
                    </Grid>
                  </Grid>
                </ListItem>
              </div>


            </List>
          </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserStatus;
