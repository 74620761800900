/* eslint-disable */
import React, {useState, useEffect, useContext} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles, withStyles, Checkbox, CardHeader
} from '@material-ui/core';
import measurement from 'src/context/GlobalVariables';
import { ParticipantContext } from '../../../context/ParticipantContext';
import {getHeartRate, getHeartRateAll} from "./api";
import ScrolledLineChart from "./Charts/ScrolledLineChart";
import {FormControlLabel, FormGroup} from "@mui/material";

const useStyles = makeStyles((width) => ({
  root: {
    height: '100%',
    width: width + 'px'
  },
  chartContainer: {
    height: 250,
  },
  paperTitleContainer: {
    padding: '12px',
    width: '90%'
  },
  syncContainer: {
    padding: '12px',
    width: '1%'
  },
  checkboxContainer: {
    width: '7%',
    paddingTop: '5px',
    //paddingBottom: '5px',
  },
  paperTitle: {
    paddingTop: '5px',
    //paddingBottom: '5px',
  },
  blueText: {
    paddingTop: '5px',
    paddingLeft: '14px',
    color: "SkyBlue",
    fontSize: 12
  },
  redText: {
    paddingTop: '5px',
    color: "Red",
    fontSize: 12
  },
}));

const NewCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.text.primary
    },
    '&$disabled': {
      color: theme.palette.text.primary
    }
  },
  checked: {},
  disabled: {}
}))(Checkbox);

const HeartRate = ({ className, width, brushDataRange, ...rest }) => {
  const classes = useStyles(width);
  const participant = useContext(ParticipantContext);
  const tempViol = [];    //temp value to use as initial state of data until the API useEffect is called
  tempViol.push({
    date: "2022-01-01",
    value: 0,
  });
  const [data, setData] = useState(tempViol);
  const [dangerPoint, setDangerPoint] = useState(75);
  const [storeID, setStoreID] = useState(undefined);
  const [sync, setSync] = useState(false);

  useEffect(() => {
    let endTime;
    let startTime;
    const currentDate = new Date();

    startTime = currentDate.getFullYear().toString() + "-01-01T00:00:00Z";
    endTime = currentDate.getFullYear().toString() + "-12-31T23:59:59Z";

    //prevents recalling the API unnecessarily
    //filter out ids 0 and 1 since I think they're garbage ids? Will have to confirm that later
    if (((participant.id !== storeID) || (data.length === 1)) && (participant.id !== 0) && (participant.id !== 1))
    {
      let tempArray = [];
      getHeartRate(participant, "day", startTime, endTime)
        .then((data) => {
          if (data) {
            data.map((instance) => {
              tempArray.push({
                date: instance.startTime.substring(0, 10),
                value: instance.bpm,
              });
            });

            setData(tempArray);
          }
        }); //END API CALL

    }
    setStoreID(participant.id)

  }, [participant.id]);

  /*
useEffect(() => {
  let endTime;
  let startTime;
  const currentDate = new Date();

  startTime = currentDate.getFullYear().toString() + "-01-01T00:00:00Z";
  endTime = currentDate.getFullYear().toString() + "-12-31T23:59:59Z";



  let tempAllArray = []
  getHeartRateAll("day", startTime, endTime)
    .then((data) => {
      if (data) {
        data.map((instance) => {
          tempAllArray.push({
            date: instance.startTime.substring(0, 10),
            value: instance.bpm,
          });
        });

        setAllData(tempAllArray);
      }
    }); //END API CALL

}, [once]);

useEffect(() => {
}, [data, storeID])
*/

  const handleSyncChange = (event) => {
    if (sync)
    {
      setSync(false)
    }
    else {
      setSync(true)
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{variant: 'h6', component: 'span'}}
        style={{backgroundColor: '#F4F6F8'}}
        title='HEART RATE (AVERAGE BPM PER DAY)'
        action={
          <FormGroup>
            <FormControlLabel control={<NewCheckbox
              labelPlacement={'start'}
              edge='end'
              checked={sync}
              onChange={handleSyncChange}
            />}/>
          </FormGroup>

        }
      />
      <CardContent>
        <Grid
          container
          spacing={3}
          className={classes.chartContainer}
        >
          <Typography
            display="inline"
            className={classes.blueText}
          >
            — Average rate
          </Typography>
          <Typography
            display="inline"
            className={classes.redText}
          >
            &nbsp; — Dangerous level
          </Typography>
          <ScrolledLineChart
            data={data}
            dangerPoint = {dangerPoint}
            widthIn={width} //{measurement.chartWidth}
            heightIn={measurement.chartHeight - 20}
            brushDataRange = {brushDataRange}
            id = {"heart"}
            sync = {sync}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

HeartRate.propTypes = {
  className: PropTypes.string
};

export default HeartRate;
