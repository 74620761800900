/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Polygon,
  CircleMarker, Marker, Popup, Circle, LayersControl, LayerGroup
} from 'react-leaflet';
import theme from 'src/theme';
import { getPersonalRestrictions } from '../../../exclusion/api';
import { ParticipantContext } from '../../../../context/ParticipantContext';
import findObjectCenter from "../../../../utils/findObjectCenter";


const RestrictedSpecific = ({ setZoneGroup }) => {
  const [restrictedZoneData, setRestrictedZoneData] = useState([]);
  const zoneColor1 = { color: theme.palette.dot.yellow };
  const participant = useContext(ParticipantContext);

  useEffect(() => {
    getPersonalRestrictions(participant)
      .then((r) => {
        if (r.status === 200) {
          setRestrictedZoneData(r.data);
        }
      });
  }, [participant.id]);


  let restrictedZones;
  useEffect(() => {
    restrictedZones = [];
    if (restrictedZoneData.length != 0) {
      restrictedZones.push(
        <LayersControl.Overlay checked={true} name={'Personal Restriction Zones'}>
          <LayerGroup>
            {restrictedZoneData.map((zone) => {
              if (zone.geom.type == 'Polygon') {
                //THIS CODE PROBABLY WORKS BUT CANNOT BE TESTED RIGHT NOW
                return (
                  <Polygon
                    //pathOptions={zoneColor1}
                    positions={zone.geom.coordinates[0]}
                    fillOpacity={0.5}
                    opacity={1}
                  />
                );
                //END CODE THAT MAY OR MAY NOT WORK
              } else if (zone.geom.type == 'Point') {
                return (
                  <CircleMarker
                    center={[zone.geom.coordinates[0], zone.geom.coordinates[1]]}
                    //pathOptions={zoneColor1}
                    radius={2}
                    fillOpacity={1}
                    opacity={1}
                  />
                );
              }
            })}
          </LayerGroup>

        </LayersControl.Overlay>
      );

      restrictedZones.push(
        <LayersControl.Overlay checked={true} name={'Personal Restriction Radius'}>
          <LayerGroup>
            {restrictedZoneData.map((zone) => {
              let center = findObjectCenter(zone);

              return (
                <Circle
                  center={{ lat: center[0], lng: center[1] }} // May need to be reversed
                  radius={zone.triggerDistance}
                  //pathOptions={zoneColor1}
                  fillOpacity={0.2}
                  opacity={0.7}
                >
                  <Popup>
                    {zone.locationName}<br />
                    ID: {zone.id}<br />
                    Address: {zone.address}<br />
                    Category: {zone.category}<br />
                    Trigger Radius: {Math.round(zone.triggerDistance * 3.28)} ft.<br />
                    Comments: {zone.comments}<br />
                    Center: {center[0].toFixed(5)}, {center[1].toFixed(5)}<br />
                  </Popup>
                </Circle>
              );
            })}

          </LayerGroup>
        </LayersControl.Overlay>
      );

      setZoneGroup(restrictedZones);
    }
  }, [restrictedZoneData]);

  return (
    <div>
      {restrictedZones}
    </div>
  );
};

RestrictedSpecific.propTypes = {
  className: PropTypes.string
};

export default RestrictedSpecific;
