import React, { useState } from 'react';
import {
  Box, Button,
  Card,
  CardHeader,
  makeStyles,
  Table,
  TableCell, TableHead, TablePagination,
  TableRow, TableSortLabel, TableContainer
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  tableCell: {
    borderBottom: 'none'
  },
  completedBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'YellowGreen',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'YellowGreen'
    },
    pointerEvents: 'none'
  },
  failedBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'IndianRed',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'IndianRed'
    },
    pointerEvents: 'none'
  },
  pendingBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'SteelBlue',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'SteelBlue'
    },
    pointerEvents: 'none'
  },
  ongoingBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'Gray',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'Gray'
    },
    pointerEvents: 'none'
  },
  claimedBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'Gold',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'Gold'
    },
    pointerEvents: 'none'
  },
  deletedBox: {
    borderRadius: 10,
    color: 'white',
    backgroundColor: 'Silver',
    padding: '2px 7px 2px 7px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'Silver'
    },
    pointerEvents: 'none'
  }
}));

const ShowStatus = (props) => {
  let status = props.status;
  let classes = props.classes;
  let boxClass;

  if (status === 'COMPLETED') {
    boxClass = classes.completedBox;
  } else if (status === 'REWARD_CLAIMED') {
    status = 'CLAIMED'
    boxClass = classes.claimedBox;
  } else if (status === 'ONGOING') {
    boxClass = classes.ongoingBox;
  } else if (status === 'PENDING') {
    boxClass = classes.pendingBox;
  } else if (status === 'DELETED') {
    boxClass = classes.deletedBox;
  } else if (status === 'FAILED') {
    boxClass = classes.failedBox;
  }

  return (
    <>
      <Button
        sx={{
          paddingTop: 0,
          '&:hover': {
            background: 'none'
          }
        }}
        variant='contained'
        className={boxClass}
      >
        {status === 'REWARD_CLAIMED' ? ('REWARD CLAIMED') : (status)}
      </Button>
    </>
  );
};

const TaskList = ({ tasks, setTasks }) => {

  const classes = useStyles();

  const [sortingBy, setSortingBy] = useState(null);
  const [order, setOrder] = useState('asc');

  // variable to set max number of rows on the table, initialized to 5
  const [limit, setLimit] = useState(5);
  // variable to set the current table page, initialized to 0
  const [page, setPage] = useState(0);


  // changes the limit
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // changes the page
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const SortByStatus = () => {
    setSortingBy('status');
    if (order === 'asc') {
      setOrder('desc');
      const sorted = tasks.sort((a, b) => {
        return (a.status_value > b.status_value) ? -1 : ((a.status_value < b.status_value) ? 1 : 0);
      });
      setTasks(sorted);

    } else {
      setOrder('asc');
      const sorted = tasks.sort((a, b) => {
        return (a.status_value < b.status_value) ? -1 : ((a.status_value > b.status_value) ? 1 : 0);
      });
      setTasks(sorted);

    }
  };

  const SortByTaskType = () => {
    setSortingBy('task');
    if (order === 'asc') {
      setOrder('desc');
      const sorted = tasks.sort((a, b) => {
        return (a.task_value > b.task_value) ? -1 : ((a.task_value < b.task_value) ? 1 : 0);
      });
      setTasks(sorted);

    } else {
      setOrder('asc');
      const sorted = tasks.sort((a, b) => {
        return (a.task_value < b.task_value) ? -1 : ((a.task_value > b.task_value) ? 1 : 0);
      });
      setTasks(sorted);
    }
  };

  const SortByPoints = () => {
    setSortingBy('points');
    if (order === 'asc') {
      setOrder('desc');
      const sorted = tasks.sort((a, b) => {
        return (a.rewardAmt1 > b.rewardAmt1) ? -1 : ((a.rewardAmt1 < b.rewardAmt1) ? 1 : 0);
      });
      setTasks(sorted);

    } else {
      setOrder('asc');
      const sorted = tasks.sort((a, b) => {
        return (a.rewardAmt1 < b.rewardAmt1) ? -1 : ((a.rewardAmt1 > b.rewardAmt1) ? 1 : 0);
      });
      setTasks(sorted);
    }
  };

  if (!tasks) {
    return (
      <>
      </>
    );
  } else {
    return (
      <Card className={classes.root}>
        <CardHeader
          titleTypographyProps={{ variant: 'h5', component: 'span' }}
          style={{ backgroundColor: '#F4F6F8' }}
          title='Task List'
        />

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableCell width={'38%'}>
                Title
              </TableCell>

              <TableCell width={'35%'}>
                Description
              </TableCell>

              <TableCell width={'10%'}>
                <TableSortLabel
                  active={sortingBy === 'task'}
                  direction={sortingBy === 'task' ? (order) : 'asc'}
                  onClick={() => {
                    SortByTaskType();
                  }}
                >
                  Task Type
                </TableSortLabel>
              </TableCell>

              <TableCell width={'4%'}>
                <TableSortLabel
                  active={sortingBy === 'points'}
                  direction={sortingBy === 'points' ? (order) : 'asc'}
                  onClick={() => {
                    SortByPoints();
                  }}
                >
                  Points
                </TableSortLabel>
              </TableCell>

              <TableCell width={'10%'}>
                <TableSortLabel
                  active={sortingBy === 'status'}
                  direction={sortingBy === 'status' ? (order) : 'asc'}
                  onClick={() => {
                    SortByStatus();
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableHead>

            {tasks.slice(page * limit, page * limit + limit).map((task, i) => {
              return (
                <TableRow>
                  <TableCell width={'38%'}>
                    {task.title}
                  </TableCell>

                  <TableCell width={'35%'}>
                    {task.description}
                  </TableCell>

                  <TableCell width={'10%'}>
                    {task.taskType}
                  </TableCell>

                  <TableCell width={'4%'}>
                    {task.rewardAmt1}
                  </TableCell>

                  <TableCell
                    width={'10%'}
                    style={{
                      padding: '8px'
                    }}
                  >
                    <ShowStatus
                      status={task.status}
                      classes={classes}
                    />
                  </TableCell>

                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={tasks.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />

      </Card>
    );
  }


};

export default TaskList;
