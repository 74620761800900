/* eslint-disable */
import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

import {
  Typography,
  ListItem,
  makeStyles,
} from '@material-ui/core';

import { getNotifications } from '../views/home/api';
import { ParticipantContext } from './ParticipantContext';

import theme from 'src/theme';

const useStyles = makeStyles(() => ({
  notificationButton: {
    minWidth: '0px',
    padding: '7px',
  },
  notificationListItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.light,
    }
  }
}));

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  //const [notificationByParticipant, setNotificationByParticipant] = useState([]);
  const [notificationByParticipant, setNotificationByParticipant] = useState(new Map());
  const [currentNotificationLength, setCurrentNotificationLength] = useState(0);

  const participantContext = useContext(ParticipantContext);

  const [notificationByParticipant_meeting, setNotificationByParticipant_meeting] = useState(new Map());
  const [notificationByParticipant_device, setNotificationByParticipant_device] = useState(new Map());
  const [notificationByParticipant_battery, setNotificationByParticipant_battery] = useState(new Map());
  const [notificationByParticipant_heart, setNotificationByParticipant_heart] = useState(new Map());
  const [notificationByParticipant_stress, setNotificationByParticipant_stress] = useState(new Map());
  const [notificationByParticipant_geo, setNotificationByParticipant_geo] = useState(new Map());

  const UpdateNotification = () => {
    if (localStorage.getItem('accessToken') && axios.defaults.headers.common['Authorization'] !== undefined) {
      getNotifications()
        .then(r => {
          if (r) {
            if (r.status === 200) {
              var newNotification = [];
              r.data.map((participant) => {
                newNotification.push(participant);
              });
              // Set list if it is different from the previous one
              if (list !== newNotification) {
                setList(newNotification);
                ClassifyNotificationByParticipant();
                ClassifyNotificationByParticipantAndType();
              }
            }
          }
        });
    }
  }

  const ClassifyNotificationByParticipant = () => {
    if (list != null) {
      let tmpNotificationList = new Map();
      list.map((notification) => {
        const id = Number(notification.participantId);
        if (tmpNotificationList.get(id) == undefined) {
          tmpNotificationList.set(id, [])
        }
        tmpNotificationList.get(id).push(notification)
      });
      setNotificationByParticipant(tmpNotificationList);

      if (notificationByParticipant.get(participantContext.id) != undefined) {
        setCurrentNotificationLength(notificationByParticipant.get(participantContext.id).length);
      } else {
        setCurrentNotificationLength(0);
      }
    }
  }

  const ClassifyNotificationByParticipantAndType = () => {
    let notifications = list;
    if (notifications != null) {
      if (notificationByParticipant != undefined) {
        let tmpMeeting = new Map();
        let tmpDevice = new Map();
        let tmpBattery = new Map();
        let tmpHeart = new Map();
        let tmpStress = new Map();
        let tmpGeo = new Map();
        const iterator1 = notificationByParticipant.entries();
        for (const user of iterator1) {
          if (notificationByParticipant.get(user[0]).length !== 0) {
            tmpMeeting.set(user[0], [])
            tmpDevice.set(user[0], [])
            tmpBattery.set(user[0], [])
            tmpHeart.set(user[0], [])
            tmpStress.set(user[0], [])
            tmpGeo.set(user[0], [])

            notificationByParticipant.get(user[0]).forEach(item => {
              if (item.type == "meeting") {
                tmpMeeting.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              } else if (item.type == "device") {
                tmpDevice.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              } else if (item.type == "battery") {
                tmpBattery.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              } else if (item.type == "heart") {
                tmpHeart.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              }
              else if (item.type == "stress") {
                tmpStress.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              }
              else if (item.type == "geo") {
                tmpGeo.get(user[0]).push(
                  <ListItem className={classes.notificationListItem}>
                    <React.Fragment>
                      <Typography variant="h6" color="inherit">{item.message}</Typography>
                    </React.Fragment>
                  </ListItem>
                )
              }
            })
          }
        }
        setNotificationByParticipant_meeting(tmpMeeting);
        setNotificationByParticipant_device(tmpDevice);
        setNotificationByParticipant_battery(tmpBattery);
        setNotificationByParticipant_heart(tmpHeart);
        setNotificationByParticipant_stress(tmpStress);
        setNotificationByParticipant_geo(tmpGeo);
      }
    }
  }

  useEffect(() => {
    UpdateNotification();
  }, [participantContext]);

  return (
    <NotificationContext.Provider
      value={{
        list, setList,
        notificationByParticipant, setNotificationByParticipant,
        currentNotificationLength, setCurrentNotificationLength,
        notificationByParticipant_meeting, notificationByParticipant_device,
        notificationByParticipant_battery, notificationByParticipant_heart,
        notificationByParticipant_stress, notificationByParticipant_geo,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
