/* eslint-disable */
import {
  Badge,
  makeStyles,
} from '@material-ui/core';

import NotificationIcon from 'src/utils/NotificationIcon';

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: 'Crimson',
  },
}));

const NotificationBadge = ({ notificationData, type }) => {
  const classes = useStyles();

  return (
    <>
      {notificationData !== undefined ?
        notificationData.length !== 0 ?
            <Badge
              badgeContent={notificationData.length}
              color="secondary"
              classes={{ badge: classes.badge }}
            >
              <NotificationIcon type={type} />
            </Badge>
          : <> </> : <> </>}
    </>
  );
};

export default NotificationBadge;
