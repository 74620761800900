/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AnalyticsView from 'src/views/analytics/AnalyticsView';
import ScheduleView from 'src/views/schedule/ScheduleView';
import ParticipantStatusView from 'src/views/profile/ParticipantStatusView';
import Overview from 'src/views/home/Overview';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import DocumentsView from 'src/views/documents/DocumentsView';
import Fivekey from 'src/views/fivekey/Mainview';
import Assessment from 'src/views/fivekey/AddAssessment'
import Session from 'src/views/fivekey/AddSession'
import RWATinfo from 'src/views/fivekey/RWATinfo'
import CaseInfo from 'src/views/fivekey/CaseConInfo'
import ParticipantStatusUpload from './views/profile/ParticipantStatusUpload';
import AssessmentInfo from './views/fivekey/AssessmentInfo';
import GameView from './views/game/Overview';
import ExclusionZoneView from './views/exclusion/ExclusionZoneView/Overview';
import ParticipantListView from './views/participantlist/ParticipantListView/Overview';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'overview', element: <Overview /> },
      { path: 'participantlist', element: <ParticipantListView/>},
      { path: 'participantstatus', element: <ParticipantStatusView /> },
      { path: 'participantstatus/upload', element: <ParticipantStatusUpload />},
      { path: 'exclusionzone', element: <ExclusionZoneView/>},
      { path: 'schedule', element: <ScheduleView /> },
      { path: 'documents', element: <DocumentsView /> },
      { path: 'fivekey', element: <Fivekey/> },
      { path: 'fivekey/addAssessment', element: <Assessment/> },
      { path: 'fivekey/addSession', element: <Session/> },
      { path: 'fivekey/RWATInfo', element: <RWATinfo/> },
      { path: 'fivekey/caseInfo', element: <CaseInfo/> },
      { path: 'fivekey/assessmentInfo', element: <AssessmentInfo /> },
      { path: 'analytics', element: <AnalyticsView /> },
      { path: 'game', element: <GameView/>},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
