/* eslint-disable */
import {
  Badge,
  makeStyles,
} from '@material-ui/core';

import NotificationIcon from 'src/utils/NotificationIcon';

const useStyles = makeStyles(() => ({
  notificationButton: {
    minWidth: '0px',
    padding: '3px',
    color: 'LightSeaGreen',
  },
  notificationButtonNotSelected: {
    minWidth: '0px',
    padding: '3px',
    color: 'DarkGray',
  },
  badge: {
    backgroundColor: 'Crimson',
  },
  badgeNotSelected: {
    backgroundColor: 'LightSteelBlue',
  },
}));

const NotificationBadge = ({ notificationData, selected, type }) => {
  const classes = useStyles();

  return (
    <>
      {notificationData !== undefined ?
        notificationData.length !== 0 ?
          selected == 'true' ?
            <Badge
              badgeContent={notificationData.length}
              color="secondary"
              className={classes.notificationButton}
              classes={{ badge: classes.badge }}
            >
              <NotificationIcon type={type} fontSize="small" />
            </Badge>
            :
            <Badge
              badgeContent={notificationData.length}
              color="secondary"
              className={classes.notificationButtonNotSelected}
              classes={{ badge: classes.badgeNotSelected }}
            >
              <NotificationIcon type={type} fontSize="small" />
            </Badge>
          : <> </> : <> </>}
    </>
  );
};

export default NotificationBadge;
