/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  List, ListItem,
  Divider, ListItemSecondaryAction, IconButton
} from '@material-ui/core';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { createEducation, getCategoryData, updateEducation } from '../api';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldListItem from '../components/TextFieldListItem';
import SnackBarAlert from '../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  column: {
    float: 'left',
    width: '100%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  }
}));

const EducationQuery = ({ className, ...rest }) => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  let dataSkeleton = {
    educationLevel: {
      label: 'Education Level',
      value: 'NO DATA'
    },
    dateReceived: {
      label: 'Date Received',
      value: 'NO DATA'
    },
    creditHours: {
      label: 'Credit Hours',
      value: 'NO DATA'
    },
    dateEnrolled: {
      label: 'Date Enrolled',
      value: 'NO DATA'
    },
    comments: {
      label: 'Comments',
      value: 'NO DATA'
    },
    createdDate: {
      label: 'Created Date',
      value: 'NO DATA'
    }
  };

  const [state, setState] = useState(dataSkeleton);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(``);
  const [severity, setSeverity] = useState(``);
  const [editable, setEditable] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [id, setID] = useState(null);

  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleStateChange = (event) => {
    setState(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: state[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  useEffect(() => {
    getCategoryData(participant, 'EDUCATION_QUERY')
      .then((data) => {
        if (data && data['educationQuery']) {
          for (const [key, value] of Object.entries(data['educationQuery'])) {
            if (key === 'id') {
              setID(value);
            }
            if (key in state) {
              if (value == null) {
                setState(prevState => ({
                  ...prevState,
                  [key]: {
                    label: state[key].label,
                    value: 'N/A'
                  }
                }));
              } else {
                setState(prevState => ({
                  ...prevState,
                  [key]: {
                    label: state[key].label,
                    value: value
                  }
                }));
              }
            }
          }
        } else {
          setState(dataSkeleton);
        }
      });
  }, [participant.id, canceled]);


  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            className={classes.container}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Typography
                color='textSecondary'
                variant='body1'
              >
                <List>
                  <ListItem className={classes.sectionTitle}>
                    <b>Education Query</b>
                    <ListItemSecondaryAction>
                      {editable ? (
                        <>
                          <IconButton aria-label='save' onClick={() => {
                            if (id) {
                              updateEducation(participant, state, id)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to save changes, try again later.`, 'error');
                                      setCanceled(!canceled);

                                    } else {
                                      handleAlert(`Changes saved!`, 'success');
                                    }
                                  } else {
                                    handleAlert(`Failed to save changes, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            } else {
                              createEducation(participant, state, 1)
                                .then(r => {
                                    if (r) {
                                      if (r.status !== 200) {
                                        handleAlert(`Failed to create new data, try again later.`, 'error');
                                        setCanceled(!canceled);
                                      } else {
                                        handleAlert(`New data saved!`, 'success');
                                        setID(1)
                                      }
                                    } else {
                                      handleAlert(`Failed to create new data, try again later.`, 'error');
                                      setCanceled(!canceled);
                                    }
                                  });
                            }

                            setEditable(false);
                          }}>
                            <SaveIcon />
                          </IconButton>
                          <IconButton aria-label='cancel' onClick={() => {
                            setCanceled(!canceled);
                            setEditable(false);
                          }}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton aria-label='edit' onClick={() => {
                          setEditable(true);
                        }}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  {Object.keys(state).map((key) => (
                    <TextFieldListItem
                      name={key}
                      editable={editable}
                      label={state[key].label}
                      value={state[key].value}
                      changeHandler={handleStateChange}
                    />
                  ))}
                </List>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SnackBarAlert
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </>
  );
};

EducationQuery.propTypes = {
  className: PropTypes.string
};

export default EducationQuery;
