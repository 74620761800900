/* eslint-disable */
import {
  Container, Card, CardHeader, CardContent, Typography,
  makeStyles, TableRow, TableCell, Table, Grid, Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  row: {
    borderBottomStyle: 'hidden'
  },
  cell: {
    padding: '12px'
  },
  label: {
    fontSize: 16
  },
  value: {
    fontSize: 14
  },
  question:{
    margin: '16px',
    paddingRight: '16px'
  }
}));

const AssessmentInfo = () => {

  const classes = useStyles();

  const location = useLocation();

  const [state, setState] = useState(location.state.data)

  return (
    <Container maxWidth={'md'}>
      <Card>
        <CardContent>
          <CardHeader
            titleTypographyProps={{ variant: 'h5', component: 'span' }}
            title='Assessment Information'
            style={{ backgroundColor: '#F4F6F8' }}
          />

          <Grid container direction={'column'}>
            <Grid item>
              <Grid container direction={'row'}>

                <Grid item>
                  <Table>

                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}>
                        <Typography variant={'h6'} className={classes.label}>
                          ASSESSMENT
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography className={classes.value}>
                          {state.assessmentType}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}>
                        <Typography variant={'h6'} className={classes.label}>
                          STATUS
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography className={classes.value}>
                          {state.status}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}>
                        <Typography variant={'h6'} className={classes.label}>
                          STARTED
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography className={classes.value}>
                          {state.meetingStartTime}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.row}>
                      <TableCell className={classes.cell}>
                        <Typography variant={'h6'} className={classes.label}>
                          COMPLETED
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography className={classes.value}>
                          {state.actualEndTime}
                        </Typography>
                      </TableCell>
                    </TableRow>

                  <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>
                      <Typography variant={'h6'} className={classes.label}>
                        ZOOM
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography className={classes.value}>
                        <Link href={state.meetingURL}
                              target={"_blank"}
                              rel={"noopener"}
                              rel={"noreferrer"}
                        >
                          {state.meetingURL}

                        </Link>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>

                </Grid>
              </Grid>
            </Grid>
          </Grid>



        </CardContent>
      </Card>
    </Container>
  );
};


export default AssessmentInfo;
