/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  Divider,
  IconButton,
  ListItemSecondaryAction,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { ParticipantContext } from '../../../context/ParticipantContext';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { createDemographics, getCategoryData, updateDemographics } from '../api';
import SnackBarAlert from '../../../components/SnackBarAlert';
import TextFieldListItem from '../components/TextFieldListItem';
import SelectFieldListItem from '../components/SelectFieldListItem';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  container: {
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  bigColumn: {
    padding: '0px 10px 0px 10px'
  },
  columnLeft: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  columnRight: {
    float: 'left',
    width: '50%',
    '& li': {
      overflowWrap: 'break-word',
      display: 'block'
    }
  },
  row: {
    display: 'flex'
  },
  sectionTitle: {
    color: theme.palette.text.sectionTitle
  },
  bigList: {
    width: '100%'
  },
  selectionColumn: {
    width: '75%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  }
}));

const options = [
  {
    label: 'No',
    value: 0
  },
  {
    label: 'Yes',
    value: 1
  },
];

const NewCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.text.primary
    },
    '&$disabled': {
      color: theme.palette.text.primary
    }
  },
  checked: {},
  disabled: {}
}))(Checkbox);

const Demographics = ({ className, ...rest }) => {
  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  let participantInfoColumnSkeleton = {
    age: {
      label: 'Age',
      value: 'NO DATA'
    },
    raceValue: {
      label: 'Race',
      value: 'NO DATA'
    },
    gender: {
      label: 'Gender',
      value: 'NO DATA'
    },
    lastAppointmentDate: {
      label: 'Last Appointment Date',
      value: 'NO DATA'
    },
    nextAppointmentDate: {
      label: 'Next Appointment Date',
      value: 'NO DATA'
    }
  };

  let statusColumnSkeleton = {
    udsCategory: {
      label: 'UDS Category',
      value: 'NO DATA'
    },
    courtServiceStatus: {
      label: 'Court Services Status',
      value: 'NO DATA'
    },
    probationStatus: {
      label: 'Probation Status',
      value: 'NO DATA'
    },
    probationType: {
      label: 'Probation Type',
      value: 'NO DATA'
    },
    csCaseManager: {
      label: 'CS Case Manager',
      value: 'NO DATA'
    },
    communityServicesStatus: {
      label: 'Community Services Status',
      value: 'NO DATA'
    },
    ccCaseManager: {
      label: 'CC Case Manager',
      value: 'NO DATA'
    }
  };

  let warrantSkeleton = {
    warrant: {
      label: 'Warrant',
      value: 'NO DATA',
      option: 0
    }
  };

  let statusColumnSkeletonTwo = {
    warrantDate: {
      label: 'Warrant Date',
      value: 'NO DATA'
    },
    docEprd: {
      label: 'DOC EPRD',
      value: 'NO DATA'
    }
  };

  let currentlyDocSkeleton = {
    currentlyDoc: {
      label: 'Currently in D.O.C.',
      value: 'NO DATA',
      option: 0
    }
  };

  let selectionColumnSkeleton = {
    registeredSexOffender: {
      label: 'Sex Offender',
      value: false,
      option: 0,
    },
    violentOffender: {
      label: 'Violent Offender',
      value: false,
      option: 0,
    },
    gangMember: {
      label: 'Gang Member',
      value: false,
      option: 0,
    },
    veteran: {
      label: 'Veteran',
      value: false,
      option: 0,
    },
    usCitizen: {
      label: 'US Citizen',
      value: false,
      option: 0,
    },
    hispanic: {
      label: 'Hispanic',
      value: false,
      option: 0,
    },
    homeHope: {
      label: 'Home Hope',
      value: false,
      option: 0,
    },
    dnaCollected: {
      label: 'DNA Collected',
      value: false,
      option: 0,
    },
    excludeCollections: {
      label: 'Exclusion Zone',
      value: false,
      option: 0,
    }

  };

  const [participantInfoColumn, setParticipantInfoColumn] = useState(participantInfoColumnSkeleton);
  const [statusColumn, setStatusColumn] = useState(statusColumnSkeleton);
  const [warrant, setWarrant] = useState(warrantSkeleton);
  const [statusColumnTwo, setStatusColumnTwo] = useState(statusColumnSkeletonTwo);
  const [currentlyDoc, setCurrentlyDoc] = useState(currentlyDocSkeleton);
  const [selectionColumn, setSelectionColumn] = useState(selectionColumnSkeleton);
  const [editable, setEditable] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [id, setID] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(``);
  const [severity, setSeverity] = useState(``);

  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  useEffect(() => {
    getCategoryData(participant, 'DEMOGRAPHICS')
      .then((data) => {
        if (data && data['demographics']) {
          for (const [key, value] of Object.entries(data['demographics'])) {
            if (key === 'id') {
              setID(value);
            }

            if (key === 'race') {
              setParticipantInfoColumn(prevState => ({
                ...prevState,
                ['raceValue']: {
                  label: participantInfoColumn['raceValue'].label,
                  value: value
                }
              }));
            } else if (key in participantInfoColumn) {
              setParticipantInfoColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: participantInfoColumn[key].label,
                  value: value
                }
              }));
            } else if (key in statusColumn) {
              setStatusColumn(prevState => ({
                ...prevState,
                [key]: {
                  label: statusColumn[key].label,
                  value: value
                }
              }));
            } else if (key in statusColumnSkeletonTwo) {
              setStatusColumnTwo(prevState => ({
                ...prevState,
                [key]: {
                  label: statusColumnTwo[key].label,
                  value: value
                }
              }));
            } else if (key in selectionColumn) {
              if (parseInt(value) === 1) {
                setSelectionColumn(prevState => ({
                  ...prevState,
                  [key]: {
                    label: selectionColumn[key].label,
                    value: true,
                    option: 1
                  }
                }));
              } else {
                setSelectionColumn(prevState => ({
                  ...prevState,
                  [key]: {
                    label: selectionColumn[key].label,
                    value: false,
                    option: 0
                  }
                }));
              }
            } else if (key in warrant) {
              if (parseInt(value) === 1) {
                setWarrant(prevState => ({
                  ...prevState,
                  [key]: {
                    label: warrant[key].label,
                    value: true,
                    option: 1
                  }
                }));
              } else {
                setWarrant(prevState => ({
                  ...prevState,
                  [key]: {
                    label: warrant[key].label,
                    value: false,
                    option: 0,
                  }
                }));
              }
            } else if (key in currentlyDoc) {
              if (parseInt(value) === 1) {
                setCurrentlyDoc(prevState => ({
                  ...prevState,
                  [key]: {
                    label: currentlyDoc[key].label,
                    value: true,
                    option: 1,
                  }
                }));
              } else {
                setCurrentlyDoc(prevState => ({
                  ...prevState,
                  [key]: {
                    label: currentlyDoc[key].label,
                    value: false,
                    option: 0
                  }
                }));
              }
            }
          }
        } else {
          setSelectionColumn(selectionColumnSkeleton);
          setParticipantInfoColumn(participantInfoColumnSkeleton);
          setStatusColumn(statusColumnSkeleton);
          setWarrant(warrantSkeleton);
          setCurrentlyDoc(currentlyDocSkeleton);
        }
      });
  }, [participant.id, canceled]);

  const handleParticipantInfoColumnChange = (event) => {
    setParticipantInfoColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: participantInfoColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleStatusColumnChange = (event) => {
    setStatusColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: statusColumn[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleStatusColumnTwoChange = (event) => {
    setStatusColumnTwo(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: statusColumnTwo[event.target.name].label,
        value: event.target.value
      }
    }));
  };

  const handleSelectionColumnChange = (event) => {
    let option;
    if (event.target.checked) {
      option = 1
    } else {
      option = 0
    }

    setSelectionColumn(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: selectionColumn[event.target.name].label,
        value: event.target.checked,
        option: option
      }
    }));
  };

  const handleWarrantChange = (event) => {
    let value = '';
    if (event.target.value === '0') {
      value = 'No';
    } else {
      value = 'Yes';
    }
    setWarrant(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: warrant[event.target.name].label,
        value: value,
        option: parseInt(event.target.value)

      }
    }));
  };

  const handleCurrentlyDocChange = (event) => {
    let value = '';
    if (event.target.value === '0') {
      value = 'No';
    } else {
      value = 'Yes';
    }
    setCurrentlyDoc(prevState => ({
      ...prevState,
      [event.target.name]: {
        label: currentlyDoc[event.target.name].label,
        value: value,
        option: parseInt(event.target.value)

      }
    }));
  };


  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            className={classes.container}
          >
            <Grid item className={classes.bigList}>
              <Typography
                color='textSecondary'
                variant='body1'
              >
                <List>
                  <ListItem className={classes.sectionTitle}>
                    <b>Demographics</b>
                    <ListItemSecondaryAction>
                      {editable ? (
                        <>
                          <IconButton aria-label='save' onClick={() => {
                            if (id) {
                              updateDemographics(participant, Object.assign({}, participantInfoColumn, statusColumn, warrant, statusColumnTwo, currentlyDoc, selectionColumn)
                                , id)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to save changes, try again later.`, 'error');
                                      setCanceled(!canceled);
                                    } else {
                                      handleAlert(`Changes saved!`, 'success');
                                    }
                                  } else {
                                    handleAlert(`Failed to save changes, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            } else {
                              createDemographics(participant, Object.assign({}, participantInfoColumn, statusColumn, warrant, statusColumnTwo, currentlyDoc, selectionColumn)
                                , 1)
                                .then(r => {
                                  if (r) {
                                    if (r.status !== 200) {
                                      handleAlert(`Failed to create new data, try again later.`, 'error');
                                      setCanceled(!canceled);

                                    } else {
                                      handleAlert(`New data saved!`, 'success');
                                      setID(1)
                                    }
                                  } else {
                                    handleAlert(`Failed to create new data, try again later.`, 'error');
                                    setCanceled(!canceled);
                                  }
                                });
                            }

                            setEditable(false);
                          }}>
                            <SaveIcon fontSize={'small'} />
                          </IconButton>
                          <IconButton aria-label='cancel' onClick={() => {
                            setCanceled(!canceled);
                            setEditable(false);
                          }}>
                            <CancelIcon fontSize={'small'} />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton aria-label='edit' onClick={() => {
                          setEditable(true);
                        }}>
                          <EditIcon fontSize={'small'} />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={4}
                      xl={4}
                    >
                      <List>
                        {Object.keys(participantInfoColumn).map((key) => (
                          <TextFieldListItem
                            name={key}
                            editable={editable}
                            label={participantInfoColumn[key].label}
                            value={participantInfoColumn[key].value}
                            changeHandler={handleParticipantInfoColumnChange}
                          />
                        ))}
                      </List>

                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <List>
                        {Object.keys(statusColumn).map((key) => (
                          <TextFieldListItem
                            name={key}
                            editable={editable}
                            label={statusColumn[key].label}
                            value={statusColumn[key].value}
                            changeHandler={handleStatusColumnChange}
                          />
                        ))}

                        {Object.keys(warrant).map((key) => (
                          <SelectFieldListItem
                            editable={editable}
                            name={key}
                            label={warrant[key].label}
                            value={warrant[key].value}
                            classes={classes}
                            options={options}
                            selection={warrant[key].option}
                            changeHandler={handleWarrantChange}
                          />
                        ))}

                        {Object.keys(statusColumnTwo).map((key) => (
                          <TextFieldListItem
                            name={key}
                            editable={editable}
                            label={statusColumnTwo[key].label}
                            value={statusColumnTwo[key].value}
                            changeHandler={handleStatusColumnTwoChange}
                          />
                        ))}

                        {Object.keys(currentlyDoc).map((key) => (
                          <SelectFieldListItem
                            editable={editable}
                            name={key}
                            label={currentlyDoc[key].label}
                            value={currentlyDoc[key].value}
                            classes={classes}
                            options={options}
                            selection={currentlyDoc[key].option}
                            changeHandler={handleCurrentlyDocChange}
                          />
                        ))}
                      </List>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <List>
                        {Object.keys(selectionColumn).map((key) => (
                          <div className={classes.row}>
                            <div className={classes.selectionColumn}>
                              <ListItem>
                                <b>
                                  {selectionColumn[key].label}
                                </b>
                                {editable ? (
                                  <ListItemSecondaryAction>
                                    <NewCheckbox
                                      name={key}
                                      edge='end'
                                      checked={selectionColumn[key].value}
                                      onChange={handleSelectionColumnChange}
                                    />
                                  </ListItemSecondaryAction>
                                ) : (
                                  <ListItemSecondaryAction>
                                    <NewCheckbox
                                      edge='end'
                                      disabled
                                      checked={selectionColumn[key].value}
                                    />
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            </div>
                          </div>
                        ))}
                      </List>
                    </Grid>

                  </Grid>
                </List>
              </Typography>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
      <SnackBarAlert
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </>
  )
    ;
};

Demographics.propTypes = {
  className: PropTypes.string
};

export default Demographics;
