/* eslint-disable */
import { useD3 } from '../useD3';
import * as d3 from 'd3';
import {useEffect} from "react";

function ScrolledLineChart({ data, dangerPoint, heightIn, widthIn, brushDataRange, id, sync }) {
  const parseDate = d3.timeParse("%Y-%m-%d");

  const margin = {top: 20, right: 20, bottom: 0, left: 40} //110
  //const margin2 = {top: 230, right: 20, bottom: 30, left: 40}
  const width = +widthIn - margin.left - margin.right
  const height = +200 - margin.top - margin.bottom
  //const height2 = +300 - margin2.top - margin2.bottom

  const dangerData = [{
    date: data[0].date,
    value: dangerPoint
  }, {
    date: data[data.length - 1].date,
    value: dangerPoint
  }]

  const x = d3.scaleTime().range([0, width]).domain(d3.extent(data, function(d) { return parseDate(d.date); }))
  //const x2 = d3.scaleTime().range([0, width]).domain(x.domain())
  const y = d3.scaleLinear().range([height, 0]).domain([0, Math.max(d3.max(data, function(d) { return d.value; }), 3)])

  //const y2 = d3.scaleLinear().range([height2, 0]).domain(y.domain())

  const xAxis = d3.axisBottom(x)
  //const xAxis2 = d3.axisBottom(x2)

  const yAxisTicks = y.ticks()
    .filter(tick => Number.isInteger(tick));

  const yAxis = d3.axisLeft(y)
    .tickValues(yAxisTicks)
    .tickFormat(d3.format('d'))

  const ref = useD3(
    (svg) => {
      d3.selectAll(".axis--x--" + id).remove();
      d3.selectAll(".axis--y--" + id).remove();
      d3.select(".area" + id).remove();
      d3.select(".line" + id).remove();
      d3.select(".danger" + id).remove();
      d3.selectAll("#marker").remove();

      const zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]]);

      const line = d3.line()
        .defined(d => !isNaN(d.value))
        .x(function (d) { return x(parseDate(d.date)); })
        .y(function (d) { /*if (d.value < 0) { return y(0) }*/ return y(d.value) });

      const clip = svg.append("defs").append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);

      const clip2 = svg.append("defs").append("svg:clipPath")
        .attr("id", "clip2")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height + 5)
        .attr("x", 0)
        .attr("y", -5);

      const lineChart = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .attr("clip-path", "url(#clip2)");

      const dangerLine = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .attr("clip-path", "url(#clip2)");

      const focus = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      const area = d3.area()
        .curve(d3.curveMonotoneX)
        .x(function(d) { return x(parseDate(d.date)); })
        .y0(height)
        .y1(function(d) { return y(d.value); });

      focus.append("g")
        .attr("class", "axis axis--x--" + id)
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      focus.append("g")
        .attr("class", "axis axis--y--" + id)
        .call(yAxis);

      lineChart.append("path")
        .datum(data)
        .attr("class", "line" + id)
        .style("fill", "none")
        .style("stroke", "SkyBlue")
        .style("stroke-width", 1.5)
        .attr("d", line);

      dangerLine.append("path")
        .datum(dangerData)
        .attr("class", "danger" + id)
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", data.length == 1 ? 5 : 1.5)
        .attr("stroke-opacity", data.length == 1 ? "0.3" : "1")
        .attr("d", line);

      function markers()
      {
        svg.selectAll("marker")
          .data(data)
          .enter()
          .append("circle")
          .attr("class", "marker")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
          .attr("clip-path", "url(#clip2)")
          .attr("fill", function(d) {return d.value < dangerPoint ? "blue" : "red"})
          .attr("stroke", "none")
          .attr("cx", function(d) { return x(parseDate(d.date)) })
          .attr("cy", function(d) { return y(d.value) })
          .attr("r", 2)
          .attr("id", "marker");
      }

      markers();

      svg.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("cursor", "move")
        .style("fill", "none")
        .style("pointer-event", "all")
        .call(zoom);


      if (sync)
      {
        const timeDiff = brushDataRange[0].getTimezoneOffset() * 60000;
        const adjustedDate = new Date(brushDataRange[0].valueOf() - timeDiff);
        const beginDate = parseDate(adjustedDate.toISOString().substring(0, 10));
        x.domain(brushDataRange);
        y.domain([0, Math.max(d3.max(data, d => parseDate(d.date) >= beginDate && parseDate(d.date) <= brushDataRange[1] ? d.value : 0), 3)]);

        const yAxisTicks = y.ticks()
          .filter(tick => Number.isInteger(tick));

        const yAxis = d3.axisLeft(y)
          .tickValues(yAxisTicks)
          .tickFormat(d3.format('d'))

        focus.select(".axis--x--" + id).call(xAxis);
        focus.select(".axis--y--" + id).call(yAxis);
        focus.select(".area" + id).attr("d", area);
        lineChart.select(".line" + id).attr("d", line);
        dangerLine.select(".danger" + id).attr("d", line);
        d3.selectAll("#marker").remove();
        markers()
      }
    },[data, brushDataRange, sync]);

  return (
    <svg
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );

}

export default ScrolledLineChart;
