/* eslint-disable */
import React, { useEffect, useState } from 'react';
import SearchableObjects from '../ParticipantStatusView/SearchableObjects';
import { IconButton, Input, makeStyles, Paper } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@material-ui/icons/Cancel';

function Hilitor(id, tag)
{
  // Original JavaScript code by Chirp Internet: chirpinternet.eu
  // Please acknowledge use of this code by including this header.

  // private variables
  var targetNode = document.getElementById(id) || document.body;
  var hiliteTag = tag || "MARK";
  var skipTags = new RegExp("^(?:" + hiliteTag + "|SCRIPT|FORM|SPAN)$");
  var colors = ["#ff6", "#a0ffff", "#9f9", "#f99", "#f6f"];
  var wordColor = [];
  var colorIdx = 0;
  var matchRegExp = "";
  var openLeft = false;
  var openRight = false;

  // characters to strip from start and end of the input string
  var endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', "g");

  // characters used to break up the input string into words
  var breakRegExp = new RegExp('[^\\w\'-]+', "g");

  this.setEndRegExp = function(regex) {
    endRegExp = regex;
    return endRegExp;
  };

  this.setBreakRegExp = function(regex) {
    breakRegExp = regex;
    return breakRegExp;
  };

  this.setMatchType = function(type)
  {
    switch(type)
    {
      case "left":
        this.openLeft = false;
        this.openRight = true;
        break;

      case "right":
        this.openLeft = true;
        this.openRight = false;
        break;

      case "open":
        this.openLeft = this.openRight = true;
        break;

      default:
        this.openLeft = this.openRight = false;

    }
  };

  this.setRegex = function(input)
  {
    input = input.replace(endRegExp, "");
    //input = input.replace(breakRegExp, "|");
    input = input.replace(/^\||\|$/g, "");
    if(input) {
      var re = "(" + input + ")";
      if(!this.openLeft) {
        re = "\\b" + re;
      }
      if(!this.openRight) {
        re = re + "\\b";
      }
      matchRegExp = new RegExp(re, "i");
      return matchRegExp;
    }
    return false;
  };

  this.getRegex = function()
  {
    var retval = matchRegExp.toString();
    retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
    retval = retval.replace(/\|/g, " ");
    return retval;
  };

  // recursively apply word highlighting
  this.hiliteWords = function(node)
  {
    if(node === undefined || !node) return;
    if(!matchRegExp) return;
    if(skipTags.test(node.nodeName)) return;

    if(node.hasChildNodes()) {
      for(var i=0; i < node.childNodes.length; i++)
        this.hiliteWords(node.childNodes[i]);
    }
    if(node.nodeType == 3) { // NODE_TEXT

      var nv, regs;

      if((nv = node.nodeValue) && (regs = matchRegExp.exec(nv))) {

        var match = document.createElement(hiliteTag);
        match.appendChild(document.createTextNode(regs[0]));
        match.style.backgroundColor = wordColor[regs[0].toLowerCase()];
        match.style.color = "#000";

        var after = node.splitText(regs.index);
        after.nodeValue = after.nodeValue.substring(regs[0].length);
        node.parentNode.insertBefore(match, after);

      }
    }
  };

  // remove highlighting
  this.remove = function()
  {
    var arr = document.getElementsByTagName(hiliteTag), el;
    while(arr.length && (el = arr[0])) {
      var parent = el.parentNode;
      parent.replaceChild(el.firstChild, el);
      parent.normalize();
    }
  };

  // start highlighting at target node
  this.apply = function(input)
  {
    this.remove();
    if(input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ""))) {
      return;
    }
    if(this.setRegex(input)) {
      this.hiliteWords(targetNode);
    }
    return matchRegExp;
  };

}

// Portions of this sourced from https://github.com/TeamWertarbyte/material-ui-search-bar/blob/master/src/components/SearchBar/SearchBar.js

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    margin: "auto 16px",
    width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
  },
}));


const ParticipantInfoSearchBar = ({ setDisplayedObjects }) => {

  const classes = useStyles()
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false)

  useEffect(() => {

    return () => {
      var myHilitor = new Hilitor("participant-status-view"); // id of the element to parse
      myHilitor.setMatchType('open')
      myHilitor.apply('');
    }
  }, [])

  const handleChange = (event) => {



    var myHilitor = new Hilitor("participant-status-view"); // id of the element to parse
    myHilitor.setMatchType('open')
    myHilitor.apply(event.target.value);


    //window[highlightWords(event.target.value)]
    let tempInput = event.target.value.toLowerCase();
    setInput(event.target.value);
    if (event.target.value === '') {
      setDisplayedObjects(SearchableObjects);
      return;
    }
    let displayedObjects = [];
    SearchableObjects.map((card) => {
      if (card.labels.findIndex(s => s.includes(tempInput)) > -1) {
        displayedObjects.push(card);
      }
    });

    setDisplayedObjects(displayedObjects);
  };

  return (
    <>
      {open ? (
        <Paper className={classes.root}>
          <div className={classes.searchContainer}>
            <Input
              value={input}
              onChange={handleChange}
              disableUnderline={true}
              placeholder={'Search'}
              autoFocus={true}
            >
            </Input>
          </div>
          <IconButton
            onClick={(event) => {
              event.target.value = ''
              handleChange(event)
              setOpen(false)
            }}
          >
              <CancelIcon/>
          </IconButton>
        </Paper>
      ) : (
        <IconButton
          onClick={() => setOpen(true)}
        >
          <SearchIcon/>
        </IconButton>

      )}


    </>
  );
};

export default ParticipantInfoSearchBar;
