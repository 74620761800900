/* eslint-disable */
import theme from 'src/theme';
import { useEffect } from 'react';
import * as L from 'leaflet';
import './styles.css'

const Note = ({ map }) => {
  useEffect(() => {
    if (map) {
      const legend = L.control({position: 'bottomright'})

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        const labelText = ['Participant', 'Violation', 'Personal Restriction', 'Restricted Schools', 'Restricted Playgrounds', 'Restricted Bars']
        const colors = ['purple', theme.palette.dot.red, '#3388ff', 'blue', 'green', 'orange']
        const labels = []

        for (let i = 0; i < labelText.length; i++) {
          if (i < 2)
          {
            labels.push(
              '<div>'+
              '<em style="background:' +
              colors[i] +
              '"></em> ' +
              labelText[i] +
              '</div>'
            );
          }
          else {
            labels.push(
              '<div>'+
              '<p style="background:' +
              colors[i] +
              '"></p> ' +
              labelText[i] +
              '</div>'
            );
          }
        }
        div.innerHTML = labels.join("<br>");
        return div;
      }

      legend.addTo(map)
    }
  }, [map])

  return null
}

export default Note;
