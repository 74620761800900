/* eslint-disable */
import React, {useState, useEffect, useContext} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  CardHeader,
} from '@material-ui/core';
import measurement from 'src/context/GlobalVariables';
import { ParticipantContext } from '../../../context/ParticipantContext';
import {getViolations} from "./api";
import ScrollableRangeChart from "./Charts/ScrollableRangeChart";

const useStyles = makeStyles((width) => ({
  root: {
    height: '100%',
    width: width + 'px'
  },
  chartContainer: {
   // padding: '0px 0px 0px 0px',
    height: 300,
   // width: width + 'px'
  },
  paperTitleContainer: {
    padding: '12px',
  },
  paperTitle: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  barChartDropdownText: {
    fontSize: '14px',
  },
}));

const Violation = ({ className, width, setBrushDataRange, ...rest }) => {
  const classes = useStyles(width);
  //const [chartTimeUnit, setChartTimeUnit] = useState("Day");
  const participant = useContext(ParticipantContext);
  const tempViol = [];    //temp value to use as initial state of data until the API useEffect is called
  tempViol.push({
    date: "2022-01-01",
    value: 0,
  });
  const [data, setData] = useState(tempViol);
  //const [period, setPeriod] = useState("day");
  const [storeID, setStoreID] = useState(undefined);
  //const [weekData, setWeekData] = useState(tempViol);
  //const [monthData, setMonthData] = useState(tempViol);

  useEffect(() => {
    let endTime;
    let startTime;
    const currentDate = new Date();

    startTime = currentDate.getFullYear().toString() + "-01-01T00:00:00Z";
    endTime = currentDate.getFullYear().toString() + "-12-31T23:59:59Z";

    //prevents recalling the API unnecessarily
    //filter out ids 0 and 1 since I think they're garbage ids? Will have to confirm that later
    if (((participant.id !== storeID) || (data.length === 1)) && (participant.id !== 0) && (participant.id !== 1))
    {
      let tempArray = [];
      getViolations(participant, "day", startTime, endTime)
        .then((data) => {
          if (data) {
            data.map((instance) => {
              tempArray.push({
                date: instance.startTime.substring(0, 10),
                value: instance.count,
              });
            });

            setData(tempArray);
          }
        }); //END API CALL

    }
    setStoreID(participant.id)

  }, [participant.id]);

  useEffect(() => {
  }, [data, storeID]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{ variant: 'h6', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title='VIOLATION COUNT (PER DAY)'
      />
      <CardContent>
        <Grid
          container
          spacing={3}
          className={classes.chartContainer}
        >
          <ScrollableRangeChart
            data={data}
            widthIn={width} //{measurement.chartWidth}
            heightIn={measurement.chartHeight - 20}
            setBrushDataRange = {setBrushDataRange}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

Violation.propTypes = {
  className: PropTypes.string
};

export default Violation;
