/* eslint-disable */
import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  RadioGroup,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  NativeSelect,
  Typography,
  Grid,
  Avatar,
  styled,
  Paper,
  Container,
  FormGroup, TableRow, TableCell, Table, makeStyles
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import caseData from '../../../data/CaseQandA.json';
import moment from 'moment';
import { useLocation } from 'react-router';
import { getSessionInfo } from '../api';


const useStyles = makeStyles((theme) => ({
  row: {
    borderBottomStyle: 'hidden'
  },
  cell: {
    padding: '12px'
  },
  label: {
    fontSize: 16
  },
  value: {
    fontSize: 14
  },
  question:{
    margin: '16px',
    paddingRight: '16px'
  }
}));

const CaseInfo = () => {

  const classes = useStyles();

  const location = useLocation();

  const [loaded, setLoaded] = useState(false)

  const [values, setValues] = useState({
    associatedKey: '',
    endTime: null,
    response: {},
    sessionTitle: '',
    startTime: null,
    status: '',
    sessionId: null
  });

  useEffect(() => {
    getSessionInfo(location.state.sessionLogId)
      .then((data) => {
        if (data) {
          for (let [key, value] of Object.entries(data)) {
            if (key in values) {
              if (key === 'startTime' && value !== null || key === 'endTime' && value !== null) {
                value = new Date(value).toString()
              } else if (key === 'startTime' && value == null || key === 'endTime' && value == null){
                value = 'PENDING'
              }
              setValues(prevState => ({
                ...prevState,
                [key]: value
              }));
            }
          }
        }

        setLoaded(true)
      });
  }, [location.state.sessionLogId]);

  if (!loaded) {
    return null
  } else {
    return (


      <Container>
        <Card>
          <CardContent>
            <CardHeader
              titleTypographyProps={{ variant: 'h5', component: 'span' }}
              title='Session Response'
              style={{ backgroundColor: '#F4F6F8' }}
            />

            <Grid container direction={'column'}>
              <Grid item>
                <Grid container direction={'row'}>

                  <Grid item>
                    <Table>

                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <Typography variant={'h6'} className={classes.label}>
                            KEY
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography className={classes.value}>
                            {values.associatedKey}

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <Typography variant={'h6'} className={classes.label}>
                            SESSION
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography className={classes.value}>
                            {values.sessionTitle}

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <Typography variant={'h6'} className={classes.label}>
                            STATUS
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography className={classes.value}>
                            {values.status}

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <Typography variant={'h6'} className={classes.label}>
                            STARTED
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography className={classes.value}>
                            {values.startTime}

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <Typography variant={'h6'} className={classes.label}>
                            COMPLETED
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography className={classes.value}>
                            {values.endTime}

                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>

                  </Grid>
                </Grid>
              </Grid>

              <Divider />

              {Object.keys(values.response).map((key) => (
                <Grid container direction={'row'} justify={'space-evenly'} className={classes.question} spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      {key}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {values.response[key]}
                    </Typography>
                  </Grid>
                </Grid>
              ))}


            </Grid>
          </CardContent>


        </Card>

      </Container>

    );
  }


};


export default CaseInfo;
