/* eslint-disable */
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/nij/ai-sms`;
const lcpProxy = 'http://localhost:8010/proxy/';

export const getAssessmentData = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/reentry/primary-assessment/list`, {
      params: {
        participantId: participant.id
      }
    });
    return data;

  } catch (error) {
    console.log(error);
  }
};

export const getSessionData = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/reentry/session/list`, {
      params: {
        participantId: participant.id
      }
    });
    return data;

  } catch (error) {
    console.log(error);
  }
};

export const addAssessment = async (state, participant) => {
  try {
    const response = await axios.post(`${BASE_URL}/reentry/primary-assessment`, {
      participantId: participant.id,
      assessmentType: state.assessmentType,
      startTime: new Date(state.date.getFullYear(), state.date.getMonth(), state.date.getDate(),
        state.startTime.getHours(), state.startTime.getMinutes(), state.startTime.getSeconds()).toISOString(),
      duration: state.duration,
    });

    return response.data;

  } catch (error) {
    console.log(error);

  }
};

export const addSession = async (state, participant) => {
  try {
    if (state.type === "CHAT") {
      const { data } = await axios.post(`${BASE_URL}/reentry/session`, {
        participantId: participant.id,
        sessionId: state.session,
        associatedKey: state.key,
        sessionType: state.type
      });
    } else {
      const { data } = await axios.post(`${BASE_URL}/reentry/session`, {
        participantId: participant.id,
        sessionId: state.session,
        associatedKey: state.key,
        sessionType: state.type,
        duration: state.duration,
        startTime: new Date(state.date.getYear(), state.date.getMonth(), state.date.getDate(),
          state.startTime.getHours(), state.startTime.getMinutes(), state.startTime.getSeconds()).toISOString()
      });
    }
    return data;

  } catch (error) {
    console.log(error);
  }
};

export const getSessionList = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/reentry/session/all`);
    return data;

  } catch (error) {
    console.log(error);
  }
};

export const getRWATInfo = async (participant) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/reentry/primary-assessment/rwat`, {
      params: {
        participantId: participant.id
      }
    });

    return data;

  } catch (error) {
    console.log(error);
  }
}

export const getSessionInfo = async (sessionLogId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/reentry/session`, {
      params: {
        sessionLogId: sessionLogId
      }
    });

    return data;

  } catch (error) {
    console.log(error);
  }
}
