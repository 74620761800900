import React, { useContext, useEffect, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import {Card, CardContent, CardHeader, Grid, makeStyles, Typography} from '@material-ui/core';
import TaskList from './TaskList';
import { getTasks } from '../api';
import DonutChart from "../Charts/DonutChart";

const useStyles = makeStyles((theme) => ({
  root: {},
  donutChartContainer: {
    width: '50%',
    height: 350, //275
    "text-align": "center"
  },
  paperTitle: {
    paddingTop: '20px',
  }
}));


const TaskIndex = () => {

  const classes = useStyles();
  const participant = useContext(ParticipantContext);

  const [tasks, setTasks] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [ongoingTasks, setOngoingTasks] = useState(0);
  const [failedTasks, setFailedTasks] = useState(0);
  const [pendingTasks, setPendingTasks] = useState(0);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight);

  useEffect( () => {
    setScreenHeight(window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight)
  }, [window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight]);

  useEffect(() => {
    getTasks(participant)
      .then((data) => {
        if (data) {
          let tempTasks = [];
          let tempAssignedTasks = 0;
          let tempCompletedtasks = 0;
          let tempOngoingTasks = 0;
          let tempPendingTasks = 0;
          let tempFailedTasks = 0;
          data.map((task, i) => {
            if (task.taskType === 'FIVEKEY') {
              task.task_value = 4;
            } else if (task.taskType === 'HEALTH') {
              task.task_value = 3;
            } else if (task.taskType === 'RESTRICTION') {
              task.task_value = 2;
            } else if (task.taskType === 'SCHEDULING') {
              task.task_value = 1;
            } else {
              task.task_value = 0;
            }

            if (task.status === 'COMPLETED') {
              task.status_value = 5;
              tempCompletedtasks++;
            } else if (task.status === 'REWARD_CLAIMED') {
              task.status_value = 4;
              tempCompletedtasks++;
            } else if (task.status === 'PENDING') {
              task.status_value = 3;
              tempPendingTasks++;
            } else if (task.status === 'ONGOING') {
              task.status_value = 2;
              tempOngoingTasks++;
            } else if (task.status === 'FAILED') {
              task.status_value = 1;
              tempFailedTasks++;
            } else {
              task.status_value = 0;
            }
            tempAssignedTasks++;
            tempTasks.push(task);
          });
          setAssignedTasks(tempAssignedTasks);
          setCompletedTasks(tempCompletedtasks);
          setFailedTasks(tempFailedTasks);
          setOngoingTasks(tempOngoingTasks);
          setPendingTasks(tempPendingTasks);
          setTasks(tempTasks);
        }

      });
  }, [participant.id]);

  return (
    <Card style={{ height: '100%'}}>
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title={'Tasks'}
      />

      <CardContent style={{paddingTop: 0}}>

        <Grid container>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            container
            spacing={1}>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
              className={classes.donutChartContainer}
            >
              <DonutChart
                completedData={completedTasks}
                assignedData={assignedTasks}
                height={26600/screenHeight + "vh"}
                color='#07F52D'
              />
              <Typography
                gutterBottom
                variant="h4"
                className={classes.paperTitle}
              >
                Completion Rate<br />
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
              className={classes.donutChartContainer}
            >
              <DonutChart
                completedData={failedTasks}
                assignedData={assignedTasks}
                height={26600/screenHeight + "vh"}
                color="#FF0000"
              />
              <Typography
                gutterBottom
                variant="h4"
                className={classes.paperTitle}
              >
                Failure Rate<br />
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <TaskList tasks={tasks} setTasks={setTasks}/>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

export default TaskIndex
