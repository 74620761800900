/* eslint-disable */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Polygon,
  Circle,
} from 'react-leaflet';

import { GetGeneralRestrictedZoneLocation } from '../api';
import { ParticipantContext } from '../../../context/ParticipantContext';

import theme from 'src/theme';

const GeneralRestrictedZone = ({ setZoneGroup }) => {
  const participant = useContext(ParticipantContext);

  const pathOption = {
    color: theme.palette.generalZone,
    opacity: 0.5,
    fillOpacity: 0.6,
  };

  const RevertPointCoordination = (pointList) => {
    let tmpPoint = [];
    let tmpPointList = [];
    pointList.map((point) => {
      tmpPoint = [];
      tmpPoint.push(point[1]);
      tmpPoint.push(point[0]);
      tmpPointList.push(tmpPoint);
    });
    return tmpPointList;
  }

  var individualRestrictedZoneGroup;
  useEffect(() => {
    individualRestrictedZoneGroup = [];
    if (participant != undefined) {
      GetGeneralRestrictedZoneLocation(participant.id)
        .then((r) => {
          if (r) {
            // console.log("General Restricted Zone:", r.data);
            let data = r.data;
            data.map((zone) => {
              if (zone.geom.type == "Polygon") {
                zone.geom.coordinates[0].map((coords) => {
                  coords.reverse()
                })
                individualRestrictedZoneGroup.push(
                  <Polygon
                    pathOptions={pathOption}
                    positions={zone.geom.coordinates[0]}
                  />
                );
              } else if (zone.geom.type == "Point") {
                individualRestrictedZoneGroup.push(
                  <Circle
                    center={[zone.geom.coordinates[1], zone.geom.coordinates[0]]}
                    pathOptions={pathOption}
                    radius={10}
                  />
                );
              }
            });
            setZoneGroup(individualRestrictedZoneGroup);
          }
          else {
            setZoneGroup([]);
          }
        });
    }
  }, [participant.id]);

  return (
    <div>
      {individualRestrictedZoneGroup}
    </div>
  );
}

GeneralRestrictedZone.propTypes = {
  className: PropTypes.string
};

export default GeneralRestrictedZone;
