/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { Button, Grow, Paper, Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  buttonRed: {
    color: 'white',
    backgroundColor: 'IndianRed',
    padding: '2px 7px 2px 7px',
    '&:hover': {
      backgroundColor: 'SandyBrown'
    },
    width: ''
  },
  buttonGreen: {
    color: 'white',
    backgroundColor: 'YellowGreen',
    padding: '2px 7px 2px 7px',
    '&:hover': {
      backgroundColor: 'MediumSeaGreen'
    },
    width: 'fit-content'
  },
  buttonBlue: {
    color: 'white',
    backgroundColor: 'SteelBlue',
    padding: '2px 7px 2px 7px',
    '&:hover': {
      backgroundColor: 'SkyBlue'
    },
    width: 'fit-content'
  },
  popper: {
    zIndex: 1300
  }
}));

const AttendedButtonPopper = ({ appointmentState, attendAppointment }) => {
  const classes = useStyles();

  // determines if the popper is open
  const [open, setOpen] = useState(false);
  // holds the appointment for the popper
  // reference to anchor the popper
  const anchorRef = useRef(null);

  // handles submitting the new status and closing the popper
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    appointmentState.attended = event.target.id === 'attended'
    attendAppointment(appointmentState, event.target.id === 'attended');
  };

  // handles opening the popper
  const handleButtonClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  // changes the css for the button
  function changeClass(approved) {
    if (approved) {
      return classes.buttonGreen;
    } else {
      return classes.buttonRed;
    }
  }

  // handles closing the popper when the user clicks away from the popper
  const handleClickAwayClose = (event) => {
    event.stopPropagation()
    setOpen(false);
  }

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleButtonClick}
        variant='contained'
        className={changeClass(appointmentState.attended)}
      >
        {appointmentState.attended === true ? (
          'Attended'
        ) : (
          'Missed'
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={classes.popper}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAwayClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow'>
                  <MenuItem onClick={handleClose} id={'attended'}>Attended</MenuItem>
                  <MenuItem onClick={handleClose} id={'missed'}>Missed</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>

          </Grow>
        )}

      </Popper>
    </div>
  );
};

export default AttendedButtonPopper;
