/* eslint-disable */
import {
  Box,
  Button, Card,
  CardHeader, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@material-ui/core';
import React, { useState } from 'react';
import AddPersonalRestrictionDialog from '../Dialogs/AddPersonalRestrictionDialog';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import EditPersonalRestrictionDialog from '../Dialogs/EditPersonalRestrictionDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  addButton: {
    paddingTop: '6px'
  },
  header: {
    marginBottom: '15px'
  }
}));

const PersonalExclusionTable = ({ personalExclusionData, MapFlyTo, update }) => {
  const classes = useStyles();


  const [openAdd, setOpenAdd] = useState(false);

  const [openEdit, setOpenEdit] = useState(false)

  const [selectedRestrictionForEdit, setSelectedRestrictionForEdit] = useState(null)

  // Determines if the alert pop-up is open or not
  const [openAlert, setOpenAlert] = useState(false);
  // Message for the alert pop-up
  const [message, setMessage] = useState('');
  // Severity for the alert pop-up
  const [severity, setSeverity] = useState(``);

  // function to open the alert pop-up
  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
  };

  // function close the alert pop-up
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleCloseAddDialog = () => {
    update();
    setOpenAdd(false);
  };

  const handleCloseEditDialog = () => {
    update();
    setOpenEdit(false);
  };

  return (
    <Card className={classes.root}>

      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title='PERSONAL RESTRICTION'

        action={
          <Box className={classes.addButton}
               Container
               align='right'
               sx={{
                 display: 'flex',
                 justifyContent: 'flex-end',
                 pt: 10
               }}>
            <Button
              color='secondary'
              variant='contained'
              underline='hover'
              halfWidth
              align='right'
              size='small'
              onClick={() => setOpenAdd(true)}
            >
              + Personal Restriction
            </Button>
            <AddPersonalRestrictionDialog open={openAdd} handleCloseDialog={handleCloseAddDialog} handleAlert={handleAlert} />
          </Box>
        }
      />

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>
                Location Name
              </TableCell>

              <TableCell>
                Trigger Radius (ft)
              </TableCell>

              <TableCell>
                Comments
              </TableCell>

              <TableCell>
                Coordinates
              </TableCell>

              <TableCell>
                Address
              </TableCell>

              <TableCell>
                Options
              </TableCell>

            </TableHead>

            <TableBody>
              {personalExclusionData.map((restriction) => (
                <TableRow
                  hover
                  onClick={() => {
                    MapFlyTo([restriction.geom.coordinates[0], restriction.geom.coordinates[1]]);
                  }}>

                  <TableCell>
                    {restriction.locationName}
                  </TableCell>

                  <TableCell>
                    {restriction.triggerDistance}
                  </TableCell>

                  <TableCell>
                    {restriction.comments}
                  </TableCell>

                  {restriction.geom.type === 'Point' ? (
                    <TableCell>
                      {restriction.geom.coordinates[0]}, {restriction.geom.coordinates[1]}
                    </TableCell>
                  ) : (
                    <TableCell>
                      N/A
                    </TableCell>
                  )}

                  <TableCell>
                    {restriction.address !== null ? (
                      <>
                        {restriction.address}
                      </>
                    ) : (
                      <>
                        N/A
                      </>
                    )}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant='outlined'
                      underline='hover'
                      halfWidth
                      align='right'
                      size='small'
                      onClick={() => {
                        setOpenEdit(true);
                        setSelectedRestrictionForEdit(restriction)
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>


      <EditPersonalRestrictionDialog open={openEdit} handleCloseDialog={handleCloseEditDialog} handleAlert={handleAlert} personalRestriction={selectedRestrictionForEdit}/>

      <SnackBarAlert
        open={openAlert}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </Card>
  );
};

export default PersonalExclusionTable;
