/* eslint-disable */
import { useEffect } from 'react';
import * as L from 'leaflet';
import './styles.css'

const Legend = ({ map }) => {

  useEffect(() => {
    if (map) {
      const legend = L.control({position: 'bottomright'})

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        const labelText = ['Exclusion Zone Radius', 'School Zone', 'Playground Zone', 'Bar Zone']
        const colors = ['#3388ff', 'blue', 'green', 'orange']
        const labels = []

        labels.push(
          '<div>'+
          '<em style="background:' +
          'purple' +
          '"></em> ' +
          'Participant' +
          '</div>'
        )

        for (let i = 0; i < labelText.length; i++) {

          labels.push(
            '<div>'+
            '<p style="background:' +
            colors[i] +
            '"></p> ' +
            labelText[i] +
            '</div>'
          );
        }
        div.innerHTML = labels.join("<br>");
        return div;
      }

      legend.addTo(map)
    }
  }, [map])

  return null


}

export default Legend;
