/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addSession, getSessionList } from '../api';
import { useNavigate } from 'react-router';
import Page from '../../../components/Page';
import { ParticipantContext } from '../../../context/ParticipantContext';

const key = [
  {
    value: 'Meaningful Work Trajectories',
    label: 'Meaningful Work Trajectories'
  },
  {
    value: 'Positive Relationships',
    label: 'Positive Relationships'
  },
  {
    value: 'Effective Coping Strategies',
    label: 'Effective Coping Strategies'
  },
  {
    value: 'Positive Social Engagement',
    label: 'Positive Social Engagement'
  },
  {
    value: 'Healthy Thinking Patterns',
    label: 'Healthy Thinking Patterns'
  }
];

const type = [
  {
    value: 'ZOOM',
    label: 'ZOOM'
  },
  {
    value: 'CHAT',
    label: 'CHAT'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Session = (props) => {
  const classes = useStyles();

  const participant = useContext(ParticipantContext);

  const navigate = useNavigate();

  const [sessions, setSessions] = useState([]);

  const [state, setState] = useState({
    key: 'Meaningful Work Trajectories',
    session: 1,
    type: 'CHAT',
    date: new Date(),
    startTime: new Date(),
    duration: 30
  });

  useEffect(() => {
    getSessionList()
      .then((data) => {
        let sessionsList = [];
        if (data) {
          data.sessionList.map((session) => {
            const sessionDict = {
              id: session.sessionId,
              title: session.sessionTitle
            };
            sessionsList.push(sessionDict);
          });
          setSessions(sessionsList);
        }
      });
  }, [participant.id]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleDate = (date) => {
    setState({
      ...state,
      date: date,
    });
  };

  const handleTime = (time) => {
    setState({
      ...state,
      startTime: time,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addSession(state, participant)
      .then(() => {
        navigate('/app/fivekey', { replace: true });
      });
  };

  return (
    <Page
      className={classes.root}
      title='Add Session'
    >
      <Container>
        <form name={'addsession'} onSubmit={handleSubmit}>
          <Box spacing={10} sx={{ border: 0 }}>
            <Card>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>

                    <CardHeader
                      titleTypographyProps={{ variant: 'h5', component: 'span' }}
                      title='Add Session'
                      style={{ backgroundColor: '#F4F6F8' }}


                    />
                  </Grid>
                  <Grid item>
                    <Container>
                      <Container>
                        <Container>
                          <Grid container direction='column' spacing={4}>

                            {/* Key Select HTML Start */}

                            <Grid item>
                              <Grid container direction='row' spacing={2}>

                                <Grid item md={6}>
                                  <label htmlFor={'key'}>
                                    <Typography variant='h6'>Select A Key</Typography>
                                  </label>
                                </Grid>

                                <Grid item md={6}>
                                  <TextField
                                    id={'key'}
                                    name={'key'}
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required={true}
                                    select={true}
                                    inputProps={{ style: { fontSize: '.85rem' } }}
                                    SelectProps={{ native: true }}
                                    value={state.key}
                                    variant={'outlined'}
                                  >
                                    {key.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>

                              </Grid>
                            </Grid>

                            {/* Session Select HTML Start */}

                            <Grid item>
                              <Grid container direction='row' spacing={2}>

                                <Grid item md={6}>
                                  <label htmlFor={'session'}>
                                    <Typography variant='h6'>Select A Session</Typography>
                                  </label>
                                </Grid>

                                <Grid item md={6}>
                                  <TextField
                                    id={'session'}
                                    name={'session'}
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required={true}
                                    select={true}
                                    inputProps={{ style: { fontSize: '.85rem' } }}
                                    SelectProps={{ native: true }}
                                    value={state.session}
                                    variant={'outlined'}
                                  >
                                    {sessions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.title}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>

                              </Grid>
                            </Grid>

                            {/* Type Select HTML Start */}

                            <Grid item>
                              <Grid container direction='row' spacing={2}>

                                <Grid item md={6}>
                                  <label htmlFor={'session'}>
                                    <Typography variant='h6'>Select A Type</Typography>
                                  </label>
                                </Grid>

                                <Grid item md={6}>
                                  <TextField
                                    id={'type'}
                                    name={'type'}
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required={true}
                                    select={true}
                                    inputProps={{ style: { fontSize: '.85rem' } }}
                                    SelectProps={{ native: true }}
                                    value={state.type}
                                    variant={'outlined'}
                                  >
                                    {type.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>

                              </Grid>
                            </Grid>

                            {state.type === 'ZOOM' ? (


                              <>

                                {/* Date Picker HTML Start */}

                                <Grid item>
                                  <Grid container direction='row' spacing={2}>

                                    <Grid item md={6}>
                                      <label htmlFor={'date'}>
                                        <Typography variant='h6'>Date</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          id={'date'}
                                          name={'date'}
                                          required={true}

                                          fullWidth={true}
                                          inputVariant={'outlined'}
                                          format={'MM/dd/yyyy'}
                                          inputProps={{ style: { fontSize: '.85rem' } }}
                                          value={state.date}
                                          onChange={handleDate}
                                          KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                          }}
                                        >
                                        </KeyboardDatePicker>
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                  </Grid>
                                </Grid>

                                {/* Time Picker HTML Start*/}

                                <Grid item>
                                  <Grid container direction='row' spacing={2}>

                                    <Grid item md={6}>
                                      <label htmlFor={'time'}>
                                        <Typography variant='h6'>Start Time</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                          id={'time'}
                                          name={'time'}
                                          required={true}
                                          fullWidth={true}
                                          inputVariant={'outlined'}
                                          value={state.startTime}
                                          onChange={handleTime}
                                          inputProps={{ style: { fontSize: '.85rem' } }}
                                          KeyboardButtonProps={{
                                            'aria-label': 'change time'
                                          }}
                                        >
                                        </KeyboardTimePicker>
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                  </Grid>
                                </Grid>

                                {/* Duration Select Field HTML Start */}

                                <Grid item>
                                  <Grid container direction='row' spacing={2}>
                                    <Grid item md={6}>
                                      <label htmlFor={'duration'}>
                                        <Typography variant='h6'>Duration (minutes)</Typography>
                                      </label>
                                    </Grid>

                                    <Grid item md={6}>
                                      <TextField
                                        id={'duration'}
                                        name={'duration'}
                                        required={true}
                                        fullWidth={true}
                                        type={'number'}
                                        inputProps={{ style: { fontSize: '.85rem' } }}
                                        value={state.duration}
                                        variant={'outlined'}
                                        onChange={handleChange}
                                      >
                                      </TextField>
                                    </Grid>

                                  </Grid>
                                </Grid>
                              </>
                            ) : (<></>)}

                            {/* Submit Button HTML Start */}

                            <Grid item>
                              <Box
                                align={'center'}
                                sx={{
                                  display: 'flex',
                                  p: 4
                                }}
                              >
                                <Button
                                  id={'submit'}
                                  name={'submit'}
                                  value={'Submit'}
                                  type={'submit'}
                                  color={'secondary'}
                                  variant={'contained'}
                                  align={'center'}
                                  underline={'hover'}
                                  size={'small'}
                                >
                                  Create Session
                                </Button>
                              </Box>
                            </Grid>

                          </Grid>
                        </Container>
                      </Container>
                    </Container>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </form>
      </Container>
    </Page>
  );

};

export default Session;
