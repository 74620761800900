/* eslint-disable */
import {
  Circle, CircleMarker, LayerGroup, LayersControl, Polygon, Popup
} from 'react-leaflet';
import theme from 'src/theme';
import React, {useEffect} from "react";
import findObjectCenter from "../../../../utils/findObjectCenter";

function ViolationMarker({ violationPlaces, radiusValue, setViolationObjects }) {
  const violationMarkerGroup = []

  const dotColor = { color: theme.palette.dot.red };
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const options2 = { hour: 'numeric', minute: 'numeric' };

  useEffect(() => {
    violationMarkerGroup.push(
      <LayersControl.Overlay checked={true} name={'Violation Locations'}>
        <LayerGroup>
          {violationPlaces.map((location) => {
            if (location.userLocation.type == "Point") {
              let date = new Date(location.violationStartTime)
              //const timeDiff = date.getTimezoneOffset() * 60000;
              //const adjustedDate = new Date(date.valueOf() /*+ timeDiff*/);
              let endDate = new Date(location.violationEndTime);
              let durationHrs = Math.floor(location.violationDuration / 60)
              let durationMins = location.violationDuration % 60

              return (
                <Circle center={[location.userLocation.coordinates[1], location.userLocation.coordinates[0]]} pathOptions={dotColor} radius={radiusValue} fillOpacity={1} opacity={1} >
                  <Popup>
                    Violation ID: {location.id}<br />
                    {location.restrictedLocationName}<br />
                    {date.toLocaleDateString("en-US", options)}
                    {" - " + endDate.toLocaleDateString("en-US", options2).substring(10)}<br />
                    Duration: {durationHrs != 0 ? durationHrs + " hours, " : ""}
                    {durationMins + " minutes"}<br />
                    Violation location: {location.userLocation.coordinates[1].toFixed(5)}, {location.userLocation.coordinates[0].toFixed(5)}<br />
                  </Popup>
                </Circle>
              );
            }
          })}
        </LayerGroup>
      </LayersControl.Overlay>
    );
    /* ADD WHEN RADII ARE AVALIABLE:
    let center = findObjectCenter(zone);
    <Circle
        center={{ lat: center[1], lng: center[0] }}
        radius={radius/3.28}
        pathOptions={zoneColor}
        fillOpacity={0.1}
        opacity={0.7}
      />
     */
    //POSSIBLY TEMP CODE:
    //This code will happen for both POINT and POLYGON user locations as it stands
    violationMarkerGroup.push(
      <LayersControl.Overlay checked={true} name={'Violated Zones'}>
        <LayerGroup>
          {violationPlaces.map((location) => {
            return (
              location.restrictLocation.type == "Point" ?
                <CircleMarker
                  zIndexOffset={100}
                  center={[location.restrictLocation.coordinates[1], location.restrictLocation.coordinates[0]]}
                  pathOptions={dotColor}
                  radius={2}
                  fillOpacity={1}
                  opacity={1}
                />
                :
                location.restrictLocation.type == "Polygon" ?
                  <Polygon
                    zIndexOffset={100}
                    pathOptions={dotColor}
                    positions={location.restrictLocation.coordinates[0]}
                    fillOpacity={1}
                    opacity={1}
                  />
                  :
                  <></>
            );
          })};
        </LayerGroup>
      </LayersControl.Overlay>
    );
   setViolationObjects(violationMarkerGroup)
  }, [violationPlaces]);

  return (
    <div>
      {/*violationMarkerGroup*/}
    </div>
  );
}

export default ViolationMarker;
