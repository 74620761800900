/* eslint-disable */
import {
  CircleMarker,
} from 'react-leaflet';
import theme from 'src/theme';

function MeetingMarker({ meetingPlaces, radiusValue }) {
    const meetingMarkerGroup = []

    const dotColor = { color: theme.palette.dot.blue, weight: 10 };

    meetingPlaces.map((location) => {
      meetingMarkerGroup.push(
        <CircleMarker center={location} pathOptions={dotColor} radius={radiusValue} opacity={1} />
      );
    });

    return (
      <div>
        {meetingMarkerGroup}
      </div>
    );
}

export default MeetingMarker;