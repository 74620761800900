import {Card, CardContent} from '@material-ui/core';
import Page from '../../../../components/Page';
import ParticipantListTable from '../Table/ParticipantListTable';
import React, {useContext, useEffect, useState} from "react";
import {ParticipantContext} from "../../../../context/ParticipantContext";
import {getTasks, getUserLevel, getUserScore} from "../../../game/api";
import {getViolationData} from "../../../analytics/AnalyticsView/api";



const ParticipantListView = () => {

  const participantContext = useContext(ParticipantContext);

  const [taskRates, setTaskRates] = useState([]);
  const [userScores, setUserScores] = useState([]);
  const [userLevels, setUserLevels] = useState([]);
  const [violationCounts, setViolationCounts] = useState([]);

  const listCopy = [...participantContext.list]
  const [listHold, setListHold] = useState([]);

  // refreshes page if participant list has relevant changes, but not otherwise
  useEffect(() => {
    if (JSON.stringify(participantContext.list) != JSON.stringify(listHold)) {
      setListHold(() => {
        const newState = JSON.parse(JSON.stringify(participantContext.list));
        return newState;
      })

      if (participantContext.list.length != 0) {
        let tmpTasks = []
        let tmpScore = []
        let tmpLevels = []
        let tmpViolations = []
        participantContext.list.map((participant, i) => {
          getTasks(participant)
            .then((data) => {
              if (data) {
                let tempAssignedTasks = 0;
                let tempCompletedTasks = 0;
                data.map((task) => {
                  if (task.status === 'COMPLETED') {
                    tempCompletedTasks++;
                  } else if (task.status === 'REWARD_CLAIMED') {
                    tempCompletedTasks++;
                  }
                  tempAssignedTasks++;
                });

                tmpTasks.push({
                  index: i,
                  rate: !isNaN(((tempCompletedTasks / tempAssignedTasks).toFixed(1) * 100)) ? ((tempCompletedTasks / tempAssignedTasks).toFixed(1) * 100) + "%" : "N/A"
                });
              }
            });

          getUserScore(participant)
            .then((data) => {
              let userScore = -1;
              if (data) {
                userScore = data.score;

                tmpScore.push({
                  index: i,
                  score: userScore != -1 || userScore != undefined ? userScore : "N/A"
                });
              }
            });

          getUserLevel(participantContext)
            .then((data) => {
              let userLevel = 0;
              if (data) {
                userLevel = data.userLevel;

                tmpLevels.push({
                  index: i,
                  level: userLevel != -1 || userLevel != undefined ? userLevel : "N/A"
                })
              }
            });

          getViolationData(participant)
            .then((data) => {
              let violCount = -1;
              if (data) {
                violCount = data.userViolationCount;

                tmpViolations.push({
                  index: i,
                  count: violCount != -1 || violCount != undefined ? violCount : "N/A"
                });
              }
            });

        })

        setTaskRates(tmpTasks);
        setUserScores(tmpScore);
        setUserLevels(tmpLevels);
        setViolationCounts(tmpViolations);
      }
    }
  }, [participantContext.list]);



  return (
    <Page
      title={'Participant List'}
    >
      <Card>
        <CardContent>
          <ParticipantListTable
            data = {participantContext.list}
            sortData = {listCopy}
            taskRates = {taskRates}
            userScores = {userScores}
            userLevels = {userLevels}
            violationCounts = {violationCounts}
          />
        </CardContent>
      </Card>
    </Page>
  )
}

export default ParticipantListView
