/* eslint-disable */
import { useD3 } from '../useD3';
import * as d3 from 'd3';

function ScrolledBarChart({ data, heightIn, widthIn, brushDataRange, id, sync }) {
  const parseDate = d3.timeParse("%Y-%m-%d");

  const margin = {top: 20, right: 20, bottom: 0, left: 40} //110
  //const margin2 = {top: 230, right: 20, bottom: 30, left: 40}
  const width = +widthIn - margin.left - margin.right
  const height = +200 - margin.top - margin.bottom
  //const height2 = +300 - margin2.top - margin2.bottom

  const x = d3.scaleTime().range([0, width]).domain(d3.extent(data, function(d) {
    if (id === "stress" && d.date === data[data.length - 1].date) {
      let date = parseDate(d.date)
      date.setDate(date.getDate() + 7)
      return date;
    }
    return parseDate(d.date); }))
  //const x2 = d3.scaleTime().range([0, width]).domain(x.domain())
  const y = d3.scaleLinear().range([height, 0]).domain([0, Math.max(d3.max(data, function(d) { return d.value; }), 3)])
  //const y2 = d3.scaleLinear().range([height2, 0]).domain(y.domain())

  const xAxis = d3.axisBottom(x)
  //const xAxis2 = d3.axisBottom(x2)

  const yAxisTicks = y.ticks()
    .filter(tick => Number.isInteger(tick));

  const yAxis = d3.axisLeft(y)
    .tickValues(yAxisTicks)
    .tickFormat(d3.format('d'))

  const ref = useD3(
    (svg) => {

      d3.selectAll(".axis--x--" + id).remove();
      d3.selectAll(".axis--y--" + id).remove();
      d3.select(".area" + id).remove();
      d3.selectAll(".bar" + id).remove();

      const zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]]);

      const area = d3.area()
        .curve(d3.curveMonotoneX)
        .x(function(d) { return x(parseDate(d.date)); })
        .y0(height)
        .y1(function(d) { return y(d.value); });

      svg.append("defs").append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      const focus = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      focus.selectAll(".bar" + id)
        .data(data)
        .enter().append("rect")
        .style("fill", "steelblue")
        .style("clip-path", "url(#clip)")
        .attr("class", "bar" + id)
        .attr("x", function(d) { return x(parseDate(d.date)); })
        .attr("y", function(d) { return y(d.value); })
        .attr("width", data[data.length - 1] ? id === "stress" ?
          width / (((parseDate(data[data.length - 1].date).getTime() - parseDate(data[0].date).getTime()) / (1000*60*60*24*7)) + 1)
          : width / ((parseDate(data[data.length - 1].date).getTime() - parseDate(data[0].date).getTime()) / (1000*60*60*24)) : 10)
        .attr("height", function(d) { return height - y(d.value); });

      focus.append("g")
        .attr("class", "axis axis--x--" + id)
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      focus.append("g")
        .attr("class", "axis axis--y--" + id)
        .call(yAxis);

      svg.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("cursor", "move")
        .style("fill", "none")
        .style("pointer-event", "all")
        .call(zoom);


      if (sync)
      {
        const timeDiff = brushDataRange[0].getTimezoneOffset() * 60000;
        const adjustedDate = new Date(brushDataRange[0].valueOf() - timeDiff);
        const beginDate = parseDate(adjustedDate.toISOString().substring(0, 10));
        if (id === "stress")
        {
          beginDate.setDate(beginDate.getDate() - 6)
        }
        x.domain(brushDataRange);
        y.domain([0, Math.max(d3.max(data, d => parseDate(d.date) >= beginDate && parseDate(d.date) <= brushDataRange[1] ? d.value : 0), 3)]);

        const yAxisTicks = y.ticks()
          .filter(tick => Number.isInteger(tick));

        const yAxis = d3.axisLeft(y)
          .tickValues(yAxisTicks)
          .tickFormat(d3.format('d'))

        focus.select(".axis--x--" + id).call(xAxis);
        focus.select(".axis--y--" + id).call(yAxis);
        focus.select(".area" + id).attr("d", area);
        focus.selectAll(".bar" + id)
          .attr("x", function(d) { return x(parseDate(d.date))})
          .attr("y", function(d) { return y(d.value); })
          .attr("width", width / ((brushDataRange[1].getTime() - brushDataRange[0].getTime()) / (id === "stress" ? 1000*60*60*24*7 : 1000*60*60*24)))
          .attr("height", function(d) { return height - y(d.value); });
      }
    },[data, brushDataRange, sync]);

  return (
    <svg
      ref={ref}
      style={{
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );

}

export default ScrolledBarChart;
