/* eslint-disable */
import { Box, Button, Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import React, { useState } from 'react';
import GeoRestrictionProfileDialog from '../Dialogs/GeoRestrictionProfileDialog';
import SnackBarAlert from '../../../../components/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  addButton: {
    paddingTop: '6px'
  },
}));

const RestrictedText = (props) => {
  if (props.restricted) {
    return (
      <Typography>
        Restriction: {props.distance} ft
      </Typography>
    );
  } else {
    return (
      <Typography>
        No Restriction
      </Typography>
    );
  }
};

const GeoRestrictionProfile = ({ geoRestrictionProfile, update }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    update()
    setOpen(false);
  };

  // Determines if the alert pop-up is open or not
  const [openAlert, setOpenAlert] = useState(false);
  // Message for the alert pop-up
  const [message, setMessage] = useState('');
  // Severity for the alert pop-up
  const [severity, setSeverity] = useState(``);

  // function to open the alert pop-up
  const handleAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
  };

  // function close the alert pop-up
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        titleTypographyProps={{ variant: 'h5', component: 'span' }}
        style={{ backgroundColor: '#F4F6F8' }}
        title='GEORESTRICTION PROFILE'
        className={classes.header}
        action={
          <Box className={classes.addButton}
               Container
               align='right'
               sx={{
                 display: 'flex',
                 justifyContent: 'flex-end',
                 pt: 10
               }}>
            <Button
              color='secondary'
              variant='contained'
              underline='hover'
              halfWidth
              align='right'
              size='small'
              onClick={() => setOpen(true)}
            >
              + General Exclusion
            </Button>
            <GeoRestrictionProfileDialog geoRestrictionProfile={geoRestrictionProfile} open={open}
                                         handleCloseDialog={handleCloseDialog} handleAlert={handleAlert}/>

          </Box>
        }
      />

      <Grid
        container
      >

        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          xl={4}
        >
          <CardContent style={{ border: 'solid', borderWidth: '2px', borderColor: '#F4F6F8'}}>

              <CardHeader
                avatar={
                  <SchoolIcon />
                }
                title={
                  <Grid container justify={'space-between'} spacing={2}>
                    <Grid item>
                      <Typography>
                        School
                      </Typography>
                    </Grid>

                    <Grid item>
                      <RestrictedText restricted={geoRestrictionProfile.schoolRestricted}
                                      distance={geoRestrictionProfile.schoolTriggerDistance} />
                    </Grid>
                  </Grid>
                }
              />

            </CardContent>

        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          xl={4}
        >
            <CardContent style={{ border: 'solid', borderWidth: '2px', borderColor: '#F4F6F8'}}>
              <CardHeader
                avatar={
                  <LocalBarIcon />
                }
                title={
                  <Grid container justify={'space-between'} spacing={2}>
                    <Grid item>
                      <Typography>
                        Bar
                      </Typography>
                    </Grid>

                    <Grid item>
                      <RestrictedText restricted={geoRestrictionProfile.barRestricted}
                                      distance={geoRestrictionProfile.barTriggerDistance} />

                    </Grid>
                  </Grid>
                }
              />


            </CardContent>

        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          xl={4}
        >
          <CardContent style={{ border: 'solid', borderWidth: '2px', borderColor: '#F4F6F8'}}>
              <CardHeader
                style={{
                  width: '100%'
                }}
                avatar={
                  <NaturePeopleIcon />
                }
                title={
                  <Grid container justify={'space-between'} spacing={2}>
                    <Grid item>
                      <Typography>
                        Playgrounds
                      </Typography>
                    </Grid>

                    <Grid item>
                      <RestrictedText restricted={geoRestrictionProfile.playgroundRestricted}
                                      distance={geoRestrictionProfile.playgroundTriggerDistance} />
                    </Grid>
                  </Grid>
                }
              />

            </CardContent>
        </Grid>

      </Grid>

      <SnackBarAlert
        open={openAlert}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />

    </Card>
  );
};

export default GeoRestrictionProfile;
