/* eslint-disable */
import {
  Box, Button,
  Card,
  CardContent, CardHeader, CircularProgress,
  Container,
  Grid, LinearProgress,
  makeStyles, Snackbar, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';

import React, { useContext, useState } from 'react';
import { ParticipantContext } from '../../../context/ParticipantContext';
import { uploadFileData } from '../api';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert } from '@material-ui/lab';
import SnackBarAlert from '../../../components/SnackBarAlert';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  }
}));


const ParticipantStatusUpload = () => {
    const classes = useStyles();

    const participant = useContext(ParticipantContext);

    let fileCategories = {
      additionalCharges: {
        file: null,
        file_type: 'additional-charges'
      },
      addressQuery: {
        file: null,
        file_type: 'address'
      },
      apptQuery: {
        file: null,
        file_type: 'appointments'
      },
      automotiveQuery: {
        file: null,
        file_type: 'automotive'
      },
      caseQuery: {
        file: null,
        file_type: 'cases'
      },
      communitySupervisionQuery: {
        file: null,
        file_type: 'community-supervision-assessments'
      },
      conductionViolationQuery: {
        file: null,
        file_type: 'conduction-violations'
      },
      courtOrdersQuery: {
        file: null,
        file_type: 'court-orders'
      },
      csstAssessment: {
        file: null,
        file_type: 'csst-assessments'
      },
      demographics: {
        file: null,
        file_type: 'demographics'
      },
      drugScreensQuery: {
        file: null,
        file_type: 'drug-screens'
      },
      educationQuery: {
        file: null,
        file_type: 'education'
      },
      employmentQuery: {
        file: null,
        file_type: 'employment'
      },
      levelOfSupervision: {
        file: null,
        file_type: 'levels-of-supervision'
      },
      patAssessment: {
        file: null,
        file_type: 'pat-assessments'
      },
      programsQuery: {
        file: null,
        file_type: 'programs'
      },
      treatmentQuery: {
        file: null,
        file_type: 'treatments'
      }
    };

    const [files, setFiles] = useState([]);

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(``)
    const [severity, setSeverity] = useState('')

    const handleDrop = (added) => {
      setOpen(true);
      setMessage(`${added} file(s) added!`)
      setSeverity('success')
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const handleRejectedDrop = () => {
      setOpen(true);
      setMessage(`Incorrect file type! Only .CSV files accepted.`)
      setSeverity('error')
    };

    const handleAlert = (message, severity) => {
      setMessage(message)
      setOpen(true)
      setSeverity(severity)
    }


    const handleSubmit = async (event) => {
      event.preventDefault();

      let promises = [];

      files.map((file) => {
        let filePromise = new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = () => resolve([reader.result.split('\n')[0], file]);
          reader.readAsText(file);
        });
        promises.push(filePromise);
      });

      Promise.all(promises).then((result) => {
        result.map((line) => {
          if (line[0].includes('gender')) {
            fileCategories['demographics']['file'] = line[1];
          } else if (line[0].includes('countyName')) {
            fileCategories['addressQuery']['file'] = line[1];
          } else if (line[0].includes('appointmentProgramName')) {
            fileCategories['apptQuery']['file'] = line[1];
          } else if (line[0].includes('idType')) {
            fileCategories['automotiveQuery']['file'] = line[1];
          } else if (line[0].includes('jobTitle')) {
            fileCategories['employmentQuery']['file'] = line[1];
          } else if (line[0].includes('educationLevel')) {
            fileCategories['educationQuery']['file'] = line[1];
          } else if (line[0].includes('programEntryDate')) {
            fileCategories['caseQuery']['file'] = line[1];
          } else if (line[0].includes('componentName')) {
            fileCategories['levelOfSupervision']['file'] = line[1];
          } else if (line[0].includes('drugScreenID')) {
            fileCategories['drugScreensQuery']['file'] = line[1];
          } else if (line[0].includes('goalDate')) {
            fileCategories['treatmentQuery']['file'] = line[1];
          } else if (line[0].includes('lengthConditions')) {
            fileCategories['courtOrdersQuery']['file'] = line[1];
          } else if (line[0].includes('programName')) {
            fileCategories['programsQuery']['file'] = line[1];
          } else if (line[0].includes('criminalScore')) {
            fileCategories['communitySupervisionQuery']['file'] = line[1];
          } else if (line[0].includes('sentenceTerm')) {
            fileCategories['additionalCharges']['file'] = line[1];
          } else if (line[0].includes('priorScore')) {
            fileCategories['csstAssessment']['file'] = line[1];
          } else if (line[0].includes('sanctionType')) {
            fileCategories['conductionViolationQuery']['file'] = line[1];
          } else if (line[0].includes('jailScore')) {
            fileCategories['patAssessment']['file'] = line[1];
          }
        });

        let uploadPromises = []

        Object.keys(fileCategories).map((key) => {
          if (fileCategories[key].file != null) {
            let uploadPromise = new Promise(resolve => {
              uploadFileData(participant, fileCategories[key].file_type, fileCategories[key].file).then(r => {
                if (r) {
                  if (r.status !== 200) {
                    resolve(false)
                  } else {
                    resolve(true)
                  }
                } else {
                  resolve(false)
                }

              });
            })

            uploadPromises.push(uploadPromise)

          }
        });

        Promise.all(uploadPromises).then((success) => {
          if (success.includes(false)) {
            handleAlert(`One or more files failed to upload, try again later.`, 'error')
          } else {
            handleAlert(`All files uploaded!`, 'success')
          }
        })
      });
    };

    return (
      <>
        <Page
          className={classes.root}
          title='Participant Status'
        >
          <Container>
            <form name={'uploadfiles'} onSubmit={handleSubmit}>
              <Box spacing={10} sx={{ border: 0 }}>
                <Card>
                  <CardContent>

                    <Grid container direction='column' spacing={2}>

                      <Grid item>
                        <CardHeader
                          titleTypographyProps={{ variant: 'h5', component: 'span' }}
                          title='Upload Participant Status Files'
                          style={{ backgroundColor: '#F4F6F8' }}
                        />
                      </Grid>

                      <Grid item>
                        <Typography>
                          Please include headers on files.
                        </Typography>
                      </Grid>

                      <Grid item>
                          <DropzoneArea
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{ classes: { root: classes.previewChip } }}
                            previewText='Selected files'
                            acceptedFiles={['.csv']}
                            filesLimit={17}
                            onChange={(files) => {
                              setFiles(files);
                            }}
                            showAlerts={false}
                            onDrop={e => {
                              handleDrop(e.length);
                            }}
                            onDropRejected={e => {
                              handleRejectedDrop();
                            }}
                          />
                      </Grid>

                      <Grid item>
                        <Box
                          align={'right'}
                          sx={{
                            display: 'flex',
                            p: 4
                          }}
                        >
                          <Button
                            id={'submit'}
                            name={'submit'}
                            value={'Submit'}
                            type={'submit'}
                            color={'secondary'}
                            variant={'contained'}
                            align={'center'}
                            underline={'hover'}
                            size={'small'}
                          >
                            Upload Files
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>
              </Box>
            </form>
          </Container>


        </Page>
        <SnackBarAlert
          open={open}
          handleClose={handleClose}
          severity={severity}
          message={message}
        />
      </>
    )
      ;
  }
;

export default ParticipantStatusUpload;
